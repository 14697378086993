<!-- 个人资料页 -->
<template>
    <div class="views-UserInfo">
        <div class="views-title border">{{$t("userPage.text197")}}</div>
        <!-- 完善资料 -->
        <EditInfo />
        <!-- 基本信息 -->
        <BasicInfo  />
    </div>
</template>

<script>
import BasicInfo from './BasicInfo.vue'
import EditInfo from './EditInfo.vue'
export default {
    components: {
        BasicInfo,
        EditInfo,
    },
    data () {
        return {
            activeIndex: 0,
        }
    },
    methods: {
        // 切换导航
        tab (num) {
            if (this.activeIndex == num) return
            this.activeIndex = num
        }
    }
}
</script>

<style scoped>

</style>
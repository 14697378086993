<!-- 注册表单 -->
<template>
    <div class="register-form">
        <Dialog
        :visible.sync="showRegister" width="500px" destroy-on-close center :modal-append-to-body='false'
        :show-close="false" :close-on-click-modal="false" custom-class="register-dialog"
        >   
            <img @click="close" class="close-icon" src="../../assets/images/index/login_close.png" alt="">
            <div class="logo">
                <img loading="lazy" style="width:300px" v-lazy="require('@/assets/images/them/' + config.projectImgUrl +'/logo.png')"  alt="">
            </div>
            <div class="register-content">
                <!-- 账号 -->
                <div class="register-input input-icon1 symbol">
                    <input type="text" :placeholder='$t("componentsViews.text36")' v-model="form.username">
                </div>
                <!-- 密码 -->
                <div class="register-input input-icon2 symbol">
                    <Input type="password" class="fund-item-input" show-password :placeholder='$t("componentsViews.text37")' v-model="form.password"></Input>
                </div>
                <!-- 确认密码 -->
                <div class="register-input input-icon2 symbol">
                    <Input type="password" class="fund-item-input" show-password :placeholder='$t("componentsViews.text38")' v-model="form.confirmPassword"></Input>
                </div>
                <!-- 手机号 -->
                <div class="register-input input-icon3" :class="svalue.phone == 2 ? 'symbol' : ''" v-if="svalue.phone > 0">
                    <span class="iphone_numer" v-if="areaCode">{{ areaCode }}</span>
                    <input type="text" :class="areaCode ? 'iphone_areaCode' : ''" :placeholder="$t('componentsViews.text39') + (svalue.phone == 1 ? option1 : '')" v-model="form.phone">
                </div>
                <!-- 短信验证码 -->
                <div class="register-input register-input1 input-icon4 symbol" v-if="svalue.phone == 2 && svalue.smsCode == 1">
                    <input type="text" :placeholder='$t("componentsViews.text40")' v-model="form.smsCode">
                    <div @click="getSmsCode">{{ smsTime ? smsTime + 's' : $t("componentsViews.text41") }}</div>
                </div>
                <!-- 邀请码 -->
                <div class="register-input input-icon5" :class="svalue.inviteCode == 2 ? 'symbol' : ''" v-if="svalue.inviteCode > 0">
                    <input type="text" :placeholder="$t('componentsViews.text42') + (svalue.inviteCode == 1 ? option1 : '')" v-model="form.inviteCode">
                </div>
                <!-- 真实姓名 -->
                <div class="register-input input-icon6" :class="svalue.realName == 2 ? 'symbol' : ''" v-if="svalue.realName > 0">
                    <input type="text" :placeholder="$t('componentsViews.text43') + (svalue.realName == 1 ? option1 : '')" v-model="form.realName">
                </div>

                <!-- 性别 -->
                <div class="register-input input-icon7 radio-box" :class="svalue.sex == 2 ? 'symbol' : ''" v-if="svalue.sex > 0">
                    <Radio v-model="form.sex" :label="1">{{$t('componentsViews.text44')}}</Radio>
                    <Radio v-model="form.sex" :label="0">{{$t('componentsViews.text45')}}</Radio>
                </div>
                <!-- 生日 -->
                <div class="register-input" :class="svalue.birthday == 2 ? 'symbol' : ''" v-if="svalue.birthday > 0">
                    <DatePicker v-model="form.birthday" type="date" :placeholder="$t('componentsViews.text46')"></DatePicker>
                </div>

                <!-- 微信 -->
                <div class="register-input input-icon9" :class="svalue.wechat == 2 ? 'symbol' : ''" v-if="svalue.wechat > 0">
                    <input type="text" :placeholder="$t('componentsViews.text47') + (svalue.wechat == 1 ? option1 : '')" v-model="form.wechat">
                </div>
                <!-- QQ -->
                <div class="register-input input-icon10" :class="svalue.qq == 2 ? 'symbol' : ''" v-if="svalue.qq > 0">
                    <input type="text" :placeholder="'QQ' + (svalue.qq == 1 ? option1 : '')" v-model="form.qq">
                </div>
                <!-- 邮箱 -->
                <div class="register-input input-icon4" :class="svalue.email == 2 ? 'symbol' : ''" v-if="svalue.email > 0">
                    <input type="text" :placeholder="$t('componentsViews.text48') + (svalue.email == 1 ? option1 : '')" v-model="form.email">
                </div>
                <!-- 联系地址 -->
                <div class="register-input input-icon11" :class="svalue.address == 2 ? 'symbol' : ''" v-if="svalue.address > 0">
                    <input type="text" :placeholder="$t('componentsViews.text49') + (svalue.address == 1 ? option1 : '')" v-model="form.address">
                </div>
                <!-- 验证码 -->
                <div class="register-input">
                    <VerifyCode class="verfi" domId="registerId" showVerifyImg ref="veri" />
                </div>
            </div>
            <div class="regAgreement">
                <img
                    loading="lazy"
                    class="regCheckBox"
                    v-lazy="!isSelect ? require('@/assets/images/common/register-black-gold/reguncheck1.png') : require('@/assets/images/common/register-black-gold/regChecked.png')"
                    @click="isSelect = !isSelect"
                />
                <div class="regAgreementCont">{{$t('componentsViews.text50')}}</div>
                </div>
            <!-- 注册 -->
            <div class="register" @click="register">{{$t('componentsViews.text51')}}</div>
        </Dialog>
    </div>
</template>

<script>
import VerifyCode from '../components/VerifyCode.vue'
import { Message, Radio, Dialog,DatePicker, Input } from 'element-ui'
import { _sendSmsCode, _registerFormitem, _register } from '../api/modules/user'
import { _getDeviceInfo, _getDeviceId } from '../utils/finger'
import config from '../common/config'
import { mapActions } from 'vuex'
export default {
    props: ['showRegister'],
    components: {
        VerifyCode,
        Radio,
        DatePicker,
        Dialog,
        Input
    },
    data () {
        return {
            config,
            isSelect:true,
            option1: this.$t('componentsViews.text52'), // 选填
            smsTime: 0, // 验证码倒计时
            form: {
                username: '', // 账号
                password: '', // 密码
                confirmPassword: '', // 确认密码
                phone: '', // 手机号
                smsCode: '', // 短信验证码
                inviteCode: '', // 邀请码
                realName: '', // 真实姓名
                sex: 1, // 性别   1-男 0-女
                birthday: '', // 生日
                wechat: '', // 微信
                qq: '', // qq
                email: '', // 邮箱
                address: '', // 地址
            },
            svalue: { // 注册配置 0-不显示 1-选填 2-必填
                address: 0, // 地址
                birthday: 0, // 生日
                email: 0, // 邮箱
                inviteCode: 0, // 邀请码
                phone: 0, // 电话
                qq: 0, // qq
                realName: 0, // 真实姓名
                sex: 0, // 性别
                smsCode: 0, // 短信
                wechat: 0, // 微信
            },
            areaCode:this.$config.areaCode || '',
        }
    },
    mounted () {
        this.getRegistConfig()
    },
    methods: {
        ...mapActions([
            'userInfoAction'
        ]),
        // 获取注册配置
        getRegistConfig () {
            _registerFormitem().then(res => {
                if (!res) return
                this.svalue = res.data.svalue
                console.log('----注册配置', this.svalue)
            })
        },
        close() {
            this.$emit('closeDialog')
            for(let item in this.form) {
                this.form[item] = ""
            }
        },
        // 获取短信验证码
        getSmsCode () {
            if (this.smsTime) return
            // 请输入手机号码
            if (!this.form.phone) return Message.error(this.$t('componentsViews.text53'))
            this.smsTime = 60
            _sendSmsCode(this.form.phone, 8).then(res => {
                if (!res) {
                    this.smsTime = 0
                    return
                }
                const timeDown = setInterval(() => {
                    this.smsTime--
                    if (this.smsTime == 0) {
                        clearInterval(timeDown)
                    }
                }, 1000)
                // 验证码已发送
                Message.success(this.$t('componentsViews.text54'))
            }) 
        },
        // 表单验证
        valid () {
            // 邀请码
            if (this.form.inviteCode && !/^[a-zA-Z0-9]*$/.test(this.form.inviteCode)) {
                Message.error(this.$t('componentsViews.text55'))
                return false
            }
            // 账号
            if (this.form.username.trim() === '') {
                Message.error(this.$t('componentsViews.text56'))
                return false
            }
            // 密码
            if (!this.form.password.trim()) {
                Message.error(this.$t('componentsViews.text57'))
                return false
            }
            // 确认密码校验
            if (this.form.password != this.form.confirmPassword) {
                Message.error(this.$t('componentsViews.text58'))
                return false
            }
            // 手机号
            if (this.form.phone && !/^\d{6,20}$/.test(this.form.phone)) {
                Message.error(this.$t('componentsViews.text28'))
                return false
            }
            // 邮箱
            // eslint-disable-next-line
            if (this.form.email && !/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(this.form.email)) {
                Message.error(this.$t('componentsViews.text59'))
                return false
            }
            // QQ
            if (this.form.qq && !/^[1-9][0-9]{4,14}$/.test(this.form.qq)) {
                Message.error(this.$t('componentsViews.text60'))
                return false
            }
            // 微信
            if (this.form.wechat && !/^[a-zA-Z0-9][a-zA-Z0-9_-]{5,19}$/.test(this.form.wechat)) {
                Message.error(this.$t('componentsViews.text61'))
                return false
            }
            // 验证码
            if (this.svalue.phone == 2 && this.svalue.smsCode == 1 && this.form.smsCode.trim() == '') {
                Message.error(this.$t('componentsViews.text62'))
                return false
            }
            if (!this.isSelect) {
                Message.error(this.$t('componentsViews.text63'))
                return false
            }
            return Object.keys(this.svalue).every(key => {
                if (this.svalue[key] == 2 && !this.form[key]) {
                    Message.error(this.$t('componentsViews.text64'))
                    return false
                }
                return true
            })
        },
        // 注册
        async register () {
            if (!this.valid()) return
            const DeviceInfo = await _getDeviceInfo()
            const params = {
                clientItem: window.childCode,
                appId: window.theme,
                inviteCode: this.form.inviteCode || null,
                password: this.form.password || null,
                username: this.form.username || null,
                phone:this.form.phone || null,
                smsCode: this.form.smsCode || null,
                sex: this.form.sex || null,
                birthday: this.form.birthday || null,
                qq: this.form.qq || null,
                wechat: this.form.wechat || null,
                email: this.form.email || null,
                address: this.form.address || null,
                realName: this.form.realName || null,
                lastLoginVersion: config.loginVersion,
                validateType: this.$refs.veri.codeType,
            }
            // const fingerprintState = JSON.parse(sessionStorage.getItem('fingerprintState')) || "";
             if(!['a025','a026'].includes(config.theme)){
            // if(fingerprintState){
                params.fingerprint = await _getDeviceId()
                params.phoneModel = DeviceInfo.browserType
            }
            if (window.mergeAccount) {
                params.username = this.form.username + window.mergeAccount
            }
            if(this.areaCode) {
                params.phone = this.areaCode + this.form.phone
            }
            const codeType = this.$refs.veri.codeType
            const result = this.$refs.veri.getResult()
            switch (codeType) {
                case 0:
                    params.captchaKey = result.captchaKey
                    params.captchaCode = result.captchaCode
                    break
                case 1:
                    params.geeTestQueryVO = {
                        challenge: result.geetest_challenge,
                        seccode: result.geetest_seccode,
                        validate: result.geetest_validate,
                    }
                    break
                case 2:
                    params.shuMeiQueryVO = {
                        rid: result.rid,
                    }
                    break
            }

            _register(params).then(res => {
                if (!res) {
                    setTimeout(() => {
                        this.$refs.veri.reset() // 重置行为校验
                    }, 1000)
                    return
                }
                Message.success(this.$t('componentsViews.text65'))
                console.log('---注册成功', res)
                this.userInfoAction() // 获取个人信息
                this.close()
                setTimeout(() => {
                    this.$router.push({ name: 'home' })
                }, 500)
            })
        }
    }
}
</script>

<style lang="scss">
$imgUrl: '~@/assets/images/common/register-black-gold/';
.register-form{
    .radio-box {
        position: relative;
        padding-left: 52px;
        padding-top: 14px;
    }
    .register-dialog {
        position: relative;
        padding: 0 25px;
        .register-content {
            width: 100%;
            max-height: 300px;
            margin-bottom: 40px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
            }
            .register-input {
                position: relative;
                margin-bottom: 15px;
                height: 40px;
                display:flex;
                &.symbol:before,.verify-code:before{
                    content: "*";
                    position: absolute;
                    line-height: 40px;
                    left: 6px;
                    color: #ff4b4b;
                }
                input {
                    flex: 1;
                    width: 100%;
                    height: 100%;
                    color: #000;
                    padding-left: 40px;
                    border: 1px solid #dcdfe6;
                    background: transparent;
                    border: 1px solid;
                    border-color: #dcdfe6;
                    border-radius: 3px;
                    box-sizing: border-box;
                    &:focus {
                        outline: none;
                        border-color: #dcad02;
                    }
                }
                .iphone_numer{
                    position: absolute;
                    background:rgba(192, 196, 204 , 0.3);
                    justify-content:center;
                    align-items:center;
                    display: flex;
                    left: 40px;
                    height: 100%;
                    width: 40px;
                }
            }
            input.iphone_areaCode{
                padding-left: 90px;
            }
            @for $i from 1 to 12{
                .input-icon#{$i}{
                    background:url(#{$imgUrl}register_icon#{$i}.png)no-repeat 15px center/20px;
                }
            }
            .register-input1 {
                display: flex;
                // width: 448px;
                height: 40px;
                justify-content: space-between;
                input {
                    width: 260px;
                }
                div {
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    background-color: #b28601;
                    border-radius: 20px;
                    cursor: pointer;
                }
            }
        }
        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
        .logo {
            margin: 0 auto;
            text-align: center;
            margin-bottom: 25px;
        }
        .register {
            margin-top: 20px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            font-weight:700;
            color:#ffffff;
            font-size:16px;
            background: url(#{$imgUrl}login_botton_bg.png) no-repeat center;
            background-size: 100% 100%;
            cursor: pointer;
        }
        .regCheckBox {
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            cursor: pointer;
        }
        .regAgreement {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
        }
        .regAgreementCont {
            padding-top: 2px;
            font-size: 12px;
            line-height: 15px;
            word-break: break-word;
            color:#606266;
            margin-left: 10px;
        }
    }    
}
</style>
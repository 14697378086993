<!-- 启动页 -->
<template>
    <div class="page-start">
        <img loading="lazy" v-lazy="require('@/assets/images/them/' + config.projectImgUrl +'/logo.png')"  alt="">
        <div class="loading-text" >loading...</div>
        <div class="loading">
            <img class="loading-bg" src="../assets/images/start/loading1.png" alt="">
            <img class="loading-img" src="../assets/images/start/loading2.png" alt="">
        </div>
    </div>
</template>

<script>
import { _forDataJson } from '../core/comp/startUp'
import { mapGetters } from 'vuex'
import config from '@/core/common/config'
export default {
    data () {
        return{
            config
        }
    },
    mounted () {
        _forDataJson()
    },
    computed: {
        ...mapGetters([
            'systemReady'
        ])
    },
    watch: {
        systemReady (val) {
            if (val) {
                this.$router.push({ name: 'home' })
            }
        }
    }
}
</script>

<style lang="scss">
$imgUrl: '~@/assets/images/start/';
.page-start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: url(#{$imgUrl}/loading_bg.png) no-repeat center;
    background-size: 100% 100%;
    img{
        width: 300px;
    }
    .loading-text {
        margin-top: 60px;
        margin-bottom: 32px;
        color:#ffffff;
        font-size:30px;
    }
    .loading {
        width: 740px;
        height: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        .loading-bg {
            position: absolute;
            width:100%;
            top: 50%;
            transform: translateY(-50%);
        }
        .loading-img {
            width: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            animation:  loadingAnimate 1.5s linear;
        }
        
    }
    @keyframes loadingAnimate {
        0% {
            left: 0;
        }
        100% {
           left: 740px;
        }
    }
}

</style>
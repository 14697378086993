<template>
<!-- 热门游戏推荐 -->
   <div class="GamePopular-bg">
      <div class="GamePopular-game">
         <div v-animate-css.hover="'rubberBand'" class="GamePopular-title"></div>
         <div class="GamePopular-content">
            <div class="GamePopular-top">
               <div class="GamePopular-tj">{{$t("homePageIndex.text53")}}</div>
            </div>
            <div class="GamePopular-banner">
               <div class="GamePopular-bannerItem">
                  <div class="game-bannerList">
                    <div class="small-swiper">
                        <swiper :options="swiperOption" class="swiper-container" v-if="gameRecommen.length>0" @click-slide="goGame">
                            <swiper-slide class="swiper-item" v-for="(item,index) in gameRecommen" :key="index">
                                <img :src="item._pictureUrl?(item._pictureUrl) : item.imgUrl?(systemImgHost+item.imgUrl):''" class="swiper-img" :onError="noData">
                                <!-- <p class="title">{{item.name}}</p> -->
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import { _recommendGame } from "@/core/api/modules/game"
import { _getToken } from "@/core/comp/common"
import { mapGetters, } from 'vuex'
export default {
    name:'smallswoper',
    data(){
        return {
            swiperOption: {//swiper3
                speed: 1000,
                slidesPerView: 5,
                loop:true,
                autoplay: {
                    delay: 3000, //等4秒下一个
                    disableOnInteraction: false // 用户操作后继续自动轮播
                },
                //左右点击
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
            dataInfo:{
                pid:'', // 父id
                id:'', //子id
                type:'', // 类型
                curPage:1, //当前页
                pageSize:18, // 展示数量
                total:0,//游戏列表总数量
            },
            noData: 'this.src="' + require("@/assets/images/index/home/searchlost.png") + '"',
        }
    },
    computed: {
        ...mapGetters([
            'gameRecommen', // 推荐游戏列表
        ])
    },
    mounted () {
        setTimeout(() => { // 数据不重要 延迟一些获取
            _recommendGame()
        }, 1000)
    },
    methods:{
        // 进入游戏
        goGame(index,realIndex) {
            let item = this.gameRecommen[realIndex];
            _getToken(item,1);
        },
    },
}
</script>
<style lang="scss" scoped>
$imgUrl: '~@/assets/images/index/';
.GamePopular-bg {
   background-color:#141414;
   .GamePopular-game{
      width: 1200px;
      margin: 0 auto;
      padding: 30px 0;
      .GamePopular-title{
         height: 24px;
         width: 100%;
      }
      .GamePopular-content{
         font-size: 14px;
         color: #fff;
         width: 100%;
         padding:20px 0 ;
         .GamePopular-top{
            display: flex;
            justify-content:space-between;
            align-items: center;
            color: #fff;
            font-size: 18px;
            padding:10px 35px;
            .GamePopular-tj{
               color: #F4CD6F;
            }
         }
         .GamePopular-banner{
            padding-bottom: 90px;
            background:url(#{$imgUrl}home/GameList-bg.png)no-repeat bottom center/contain;
            .GamePopular-bannerItem{
               padding: 0px 35px;
               min-height: 222px;
               .game-bannerList{
                  .small-swiper {
                        width: 100%;
                        height: 100%;
                        border: 1px solid #F4CD6F;
                        padding: 20px;
                        position: relative;
                        .swiper-container{
                            .swiper-item{
                                border-radius: 8px;
                                overflow: hidden;
                                .swiper-img{
                                    cursor: pointer;
                                    border-radius: 8px;
                                    width: 204px;
                                    height:180px;
                                    margin: 0 auto;
                                    display: block;
                                    -webkit-transform: scale(1.01);
                                    transform: scale(1.01);
                                    transition: all .4s;
                                    &:hover{
                                        -webkit-transform: scale(1.1) rotate(.1deg);
                                        transform: scale(1.1) rotate(.1deg);
                                        -webkit-filter: brightness(.5);
                                        filter: brightness(.5);
                                    }
                                }
                            }
                        }
                        .swiper-button-next, .swiper-button-prev {
                            position: absolute;
                            top: 50%;
                            right: -35px;
                            width: 20px;
                            height: 30px;
                            margin-top: -22px;
                            background: url(#{$imgUrl}swiper-right.png)no-repeat center/contain;
                            &::after{
                                display:none;
                            }
                        }
                        .swiper-button-prev{
                            left: -35px;
                            right:inherit;
                            background: url(#{$imgUrl}swiper-left.png)no-repeat center/contain;
                        }
                    }
               }
            }
         }
      }
   }
}

</style>
<!-- 主页-菜单 -->
<template>
    <div class="wrap-menu">
        <div class="wrap-menu-content">
            <!-- LOGO -->
            <div class="wrap-menu-left">
                <a href="javascript:;" v-animate-css="'bounceInLeft'" class="logo"   @click="_jump('home')" >
                    <img  v-lazy="require('@/assets/images/them/' + config.projectImgUrl +'/logo.png')" alt="" srcset="">
                </a>
            </div>
            <!-- 菜单 -->
            <div class="wrap-menu-right">
                <!-- 首页 -->
                <span class="menu-item-list" :class="{'active':isactive === 0}" @click="toLink('home',0)">
                    <div>{{$t("homePageIndex.text3")}}</div>
                    <span>HOME</span>
                </span>
                <Popover v-for="(item, i) in gameMenus" :key="i" placement="bottom" class="menu-item-list" :class="['menu-item-' + item.routerName , {'active':isactive === i+1}]" trigger="hover" popper-class="menu-popover" transition="fade-in-linear">
                    <div class="wrap-menu-popover"  :class="i>0 ?'wrap-menu-popover-1':''">
                        <div class="wrap-menu-popover_content">
                            <Carousel style="width:1400px;height:100%;margin-left:-100px;padding:0 100px;" height="100%" indicator-position="none" arrow="always" :autoplay="false" :loop="false">
                                <CarouselItem v-for="(page, index) in item.list" :key="index" class="wrap-menu-box">
                                    <div class="wrap-menu-game" :class="item.className" v-for="(game, gameIndex) in page" :key="gameIndex">
                                        <!-- <img loading="lazy" v-lazy="game._imgUrl" alt="" style="width:100%;height:100%"> -->
                                        <div v-animate-css="'bounceInDown'" style="width:236px;text-align:center;">
                                            <img loading="lazy" v-lazy="game._imgUrl" alt="" style="width: auto;max-height:262px;">
                                        </div>
                                        <div class="popover-text-box">
                                            <div>{{ game.name }}</div>
                                            <div>{{ game.nameEn }}</div>
                                            <div  v-animate-css.hover="'tada'" @click="enterGame(game)">{{$t("homePageIndex.text4")}}</div>
                                        </div>
                                    </div>
                                </CarouselItem> 
                            </Carousel>
                        </div>
                    </div>
                    <div class="wrap-menu-item" slot="reference" @click="toLink(item.routerName,i+1)">
                        <div>{{ item.name }}</div>
                        <span>{{ item.routerName.toUpperCase() }}</span>
                    </div>
                </Popover>
                <!-- 手机投注 -->
                <span class="menu-item-list menu-item-mobile" @click="toLink('home',8)" :class="{'active':isactive === 8}">
                    <div>{{$t("homePageIndex.text5")}}</div>
                    <span>MOBILE</span>
                </span>
                <!-- 优惠活动 -->
                <span class="menu-item-list menu-item-activity" :class="{'active':isactive === 9}"  @click="toLink('promo',9)">
                    <div>{{$t("homePageIndex.text6")}}</div>
                    <span>Activity</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/core/common/config'
import { Popover, Carousel, CarouselItem } from 'element-ui'
import { _menuList } from '@/core/api/modules/game'
import { _jump ,_getImgUrl} from '@/core/utils/utils'
import { mapGetters } from 'vuex'
import { _getToken } from '@/core/comp/common'
// 路由对应的下表
const routeIndex = {
    'home': 0,
    'elec': 1,
    'live': 2,
    'gaming': 3,
    'sports': 4,
    'lottery': 5,
    'chess': 6,
    'fish':7,
    'mobile': 8,
    'promo': 9,
    'promoInfo':9,
}
export default {
    components: {
        Popover,
        Carousel,
        CarouselItem,
    },
    computed: {
        ...mapGetters([
            'gameMenu'
        ])
    },
    watch: {
        gameMenu: {
            handler: function () {
                this.updateData()
            },
            immediate: true
        },
        $route:{
            handler(item){
                if(item) this.isActiveTab()
            },
            immediate: true
        },
        $route: {
            handler: function (val) {
                console.log('---路由变化', val, routeIndex[val.name])
                if(val.query.scroll){
                    this.isactive = 8
                }else{
                    this.isactive = routeIndex[val.name]
                }
            },
            immediate: true
        }
    },
    data () {
        return {
            config,
            gameMenus: [
                {
                    //电子游艺
                    name: this.$t("homePageIndex.text7"),
                    className: 'wrap-menu-ele',
                    routerName: 'elec',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                    len: 6, // 每页多少条
                },
                {
                    // 真人视讯
                    name: this.$t("homePageIndex.text8"),
                    className: 'wrap-menu-ele',
                    routerName: 'live',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                    len: 3, // 每页多少条
                },
                {
                    // 电子竞技
                    name: this.$t("homePageIndex.text9"),
                    className: 'wrap-menu-ele',
                    routerName: 'gaming',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                    len: 3, // 每页多少条
                },
                {
                    // 体育赛事
                    name: this.$t("homePageIndex.text10"),
                    className: 'wrap-menu-ele',
                    routerName: 'sports',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                    len: 3, // 每页多少条
                },
                {
                    // 彩票游戏
                    name: this.$t("homePageIndex.text11"),
                    className: 'wrap-menu-ele',
                    routerName: 'lottery',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                    len: 3, // 每页多少条
                },
                {
                    // 棋牌游戏
                    name: this.$t("homePageIndex.text12"),
                    className: 'wrap-menu-ele',
                    routerName: 'chess',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                    len: 3, // 每页多少条
                },
                {
                    // 捕鱼游戏
                    name: this.$t("homePageIndex.text13"),
                    className: 'wrap-menu-ele',
                    routerName: 'fish',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                    len: 3, // 每页多少条
                }
            ],
            scroll:3000,
            isactive:0,
        }
    },
    created () {
        this.getMenuData()
    },
    methods: {
        _jump,
        // 进入游戏
        enterGame(item) {
            let id = item.id
            if(item.parentId == 1 ) {
                this.$router.push({ path: "/elec", query: { id } });
            } else if(item.parentId == 3) {
                this.$router.push({ path: "/chess", query: { id } });
            } else {
                 _getToken(item,2)
            }
        },
        // 更新菜单数据
        updateData () {
            const { arr_dz, arr_ty, arr_qp, arr_cp, arr_zb, arr_dj, arr_by } = this.gameMenu
            // console.log('---捕鱼', arr_by)
            this.gameMenus[0].data = arr_dz
            this.gameMenus[1].data = arr_zb
            this.gameMenus[2].data = arr_dj
            this.gameMenus[3].data = arr_ty
            this.gameMenus[4].data = arr_cp
            this.gameMenus[5].data = arr_qp
            this.gameMenus[6].data = arr_by

            this.gameMenus[0].list = pageSplit(arr_dz, this.gameMenus[0].len)
            this.gameMenus[1].list = pageSplit(arr_zb, this.gameMenus[1].len)
            this.gameMenus[2].list = pageSplit(arr_dj, this.gameMenus[2].len)
            this.gameMenus[3].list = pageSplit(arr_ty, this.gameMenus[3].len)
            this.gameMenus[4].list = pageSplit(arr_cp, this.gameMenus[4].len)
            this.gameMenus[5].list = pageSplit(arr_qp, this.gameMenus[5].len)
            this.gameMenus[6].list = pageSplit(arr_by, this.gameMenus[6].len)

            // 分页    list=源数据  len=每页多少条
            function pageSplit (list, len) {
                const arr = JSON.parse(JSON.stringify(list))
                const rs = []
                let children = []
                while (arr.length) {
                    children.push(arr.shift())
                    if (children.length == len) {
                        rs.push(children)
                        children = []
                    }
                }
                if(children.length == 0) return rs
                rs.push(children) // 扫尾
                return rs
            }
        },
        // 获取最新的菜单数据
        getMenuData () {
            _menuList()
        },
        toLink(path,num) {
            this.isactive = num
            if(path == 'home' && this.$router.currentRoute.name == 'home' && num != 8){
                document.documentElement.scrollTop = 0;
                return
            }
            // 手机下注
            if(num == 8){
                this.$router.push({ 
                    path: '/home',
                    query:{
                        scroll:this.scroll++
                    }
                });
                this.$emit('scrollToCode')
            }else{
                _jump(path)
            }
        }
    }
}
</script>


<style lang="scss">
.el-popover__reference-wrapper{
    height: 50%;
}

.el-popover.menu-popover {
  left: 0 !important;
  background-image:linear-gradient(180deg,#000000 0%,#2e2100 100%) !important; 
  background: rgba(0,0,0,0.8) !important;
  box-shadow:0px 10px 10px rgba(0, 0, 0, 0.04) !important;
  border: none !important;
  padding-left: 0 !important;
  margin-top: 20px !important;
  z-index: 11111;
    .popper__arrow {
    display: none !important;
    }
    .el-carousel__arrow i{
    font-size: 24px;
    font-weight: 700;
    color: #f8e8ac;
    }
    .wrap-menu-popover_content  .el-carousel__arrow--right {
    right: -80px;
    }
    .wrap-menu-popover_content .el-carousel__arrow--left {
    left: -80px;
    }
}
</style>
<style lang="scss" scoped>
$imgUrl:'~@/assets/images/index/';
.wrap-menu {
    width: 100%;
    height: 112px;
    color: #FA7D21;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrap-menu-content {
        width: 1200px;
        display: flex;
        height: 100%;
        justify-content: space-between;
        .wrap-menu-left {
            width: 280px;
            display: flex;
            align-items: center;
            .logo{
                width: 100%;
                height:91px;
                display: block;
                img{
                    height: 100%;
                }
            }
        }
        .wrap-menu-right {
            // flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            .menu-item-list,.wrap-menu-item{
                cursor: pointer;
                position: relative;
                width: 90px;
                text-align: center;
                height: 100%;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                z-index: 11;
                >div {
                    font-size: 14px;
                    width: 100%;
                }
                >span {
                    font-size: 12px;
                    margin-top: 5px;
                    transform: scale(0.9);
                }
            }
            // @each $i , $item in (0,home), (1,elec), (2,live), (3,gaming), (4,sports), (5,lottery), (6,chess), (7,mobile), (8,activity){
            //     .menu-item-#{$item}.active-#{$i}{
            .active{
                color: #D2BF87;
                z-index: 11;
                &::after {
                    content:'';
                    height: 112px;
                    width: 130px;
                    position: absolute;
                    z-index: 1;
                    background:url(#{$imgUrl}home/nav-active.png)no-repeat center/contain;
                }
            }
            &>span:hover{
                color: #D2BF87;
                z-index: 11;
                &::after {
                    content:'';
                    height: 112px;
                    width: 130px;
                    position: absolute;
                    z-index: 1;
                    background:url(#{$imgUrl}home/nav-active.png)no-repeat center/contain;
                }
            }
            .menu-item-mobile{
                color: #fff;
            }
            // 电子
            .menu-item-elec{
                &:before{
                    content: "";
                    position:absolute;
                    top: 0;
                    right: 0;
                    width: 34px;
                    height: 26px;
                    animation: linnerRunning 0.5s linear infinite alternate running;
                }
            }
            // 真人
            .menu-item-live{
                &:before{
                    content: "";
                    position:absolute;
                    top: 0;
                    right: 0;
                    width: 34px;
                    height: 26px;
                    animation: linnerRunning 0.5s linear infinite alternate running;
                }
            }
            .menu-item-activity{
                &:before{
                    content: "";
                    position:absolute;
                    top: 0;
                    right: 0;
                    width: 34px;
                    height: 26px;
                    z-index: -1;
                    animation: linnerRunning 0.5s linear infinite alternate running;
                }
            }
        }
    }
}
.wrap-menu-popover {
    width: 100vw;
    height: 528px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 9;
    .wrap-menu-popover_content {
        width: 1200px;
        height: 100%;
    }
    .wrap-menu-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .wrap-menu-game {
            display: flex;
            width: 33.333%;
            min-height: 240px;
            align-items: center;
            .popover-text-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-left: 15px;
                font-weight:700;
                color:#ffffff;
                font-size:16px;
                div:nth-child(1) {
                    width: 140px;
                    font-weight:500;
                    font-size:30px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                div:nth-child(2) {
                    margin-top: 10px;
                    font-size:14px;
                }
                div:nth-child(3) {
                    width:120px;
                    height:44px;
                    line-height: 44px;
                    margin-top: 10px;
                    font-size:14px;
                    text-align: center;
                    background:url(#{$imgUrl}button_enter.png) no-repeat center;
                    background-size: 100% 100%;
                    cursor: pointer;
                    &:hover {
                        background:url(#{$imgUrl}button_enter_i.png) no-repeat center;
                    }
                }
            }
        }
    }
}
.wrap-menu-popover-1 {
    height: 264px;
}
@keyframes linnerRunning {
    0%{
        top: 8px;
    }
    100%{
        top: 10px;
    }
}
</style>
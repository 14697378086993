<!-- 全民返利 -->
<template>
    <div class="page-rebate">
        <div class="views-title" :class="{'views-title-small border' : showRegraInfo}">
            <div @click="showRegraInfo = false" class="title-btn">{{$t("userPage.text163")}}</div>
            <template v-if="showRegraInfo">
                <i class="views-title-icon"></i>
                {{$t("userPage.text164")}}
            </template>
        </div>
        <!-- 头部 -->
        <div v-show="!showRegraInfo">
            <div class="receive-part">
                <div>
                    <img src="@/assets/images/my/backwater.png" alt="">
                </div>
                <!-- 累计返利 -->
                <div class="receive-text-box">
                    <div>{{$t("userPage.text165")}}：<span>{{ rebateAmount.amount + config.unit }}</span></div>
                    <div>
                        {{$t("userPage.text166")}}
                        {{ rebateAmount.minCount + config.unit }},
                        {{$t("userPage.text167")}}
                        {{ rebateAmount.meetConditions }}
                        {{$t("userPage.text168")}}
                    </div>
                </div>
                
                <div class="receive-bottom" @click="determineDialog">{{$t("userPage.text169")}}</div>
            </div>
            <div class="vipCount"  @click="_jump('rebateList')">
                {{$t("userPage.text170")}}：
                {{ rebateAmount.Vnum }}{{","+$t("userPage.text171")}}：
                {{ rebateAmount.effectiveVnum }}
                <img src="@/assets/images/my/view-left.png" alt="">
            </div>
            <!-- 邀请码 -->
            <div class="invite-box">
                <div class="invite-item">
                    <div class="invite-qrcode">
                        <div class="qrcode-box qrcode-box-bg">
                            <div id="qrcode" ref="qrcode"></div>
                        </div>
                        <div class="qrcode-title">{{$t("userPage.text172")}}</div>
                    </div>
                    <div class="invite-button" @click="downloadQRcode">{{$t("userPage.text173")}}</div>
                </div>
                <div class="invite-item">
                    <div class="invite-qrcode">
                        <div class="qrcode-box">
                            <div class="invite-text">{{ inviteCode }}</div>
                        </div>
                        <div class="qrcode-title">{{$t("userPage.text174")}}</div>
                    </div>
                    <div class="invite-button" @click="copy(inviteCode)">{{$t("userPage.text175")}}</div>
                </div>
                <div class="invite-item">
                    <div class="invite-qrcode">
                        <div class="qrcode-box">
                            <div>{{ inviteAddress }}</div>
                        </div>
                        <div class="qrcode-title">{{$t("userPage.text176")}}</div>
                    </div>
                    <div class="invite-button" @click="copy(inviteAddress)">{{$t("userPage.text177")}}</div>
                </div>
            </div>

            <!-- 规则 -->
            <div class="rebate-rules vipCount" @click="showRegraInfo = !showRegraInfo">
                <div>{{$t("userPage.text178")}}</div>
                <img src="@/assets/images/my/view-left.png" alt="">
            </div>
        </div>

        <div v-show="showRegraInfo" v-html="rebateExplains"></div>
        <!-- 领取弹窗 -->
        <Dialog :visible.sync="dialogVisible" :title='$t("userPage.text179")' width="30%">
            <div>
                <div>{{ rebateAmount.amount + config.unit}}</div>
                <div>{{$t("userPage.text180")}}：{{ rebateAmount.ultiple }}{{$t("userPage.text153")}}</div>
            </div>
            <span slot="footer">
                <Button @click="dialogVisible = false">{{$t("userPage.text181")}}</Button>
                <Button type="primary" @click="determine">{{$t("userPage.text182")}}</Button>
            </span>
        </Dialog>
    </div>
</template>

<script>
import config from '@/core/common/config'
import { mapGetters } from 'vuex'
import { Button, Dialog, Message } from 'element-ui'
import { _availableAmountAction, _referralLinkAction } from '@/core/comp/pages/promo'
import { _copy, _createQRcode, _download, _jump } from '@/core/utils/utils'
import { _allowanceExplain, _getReceive } from '@/core/api/modules/promo'

export default {
    components: {
        Button,
        Dialog,
    },
    computed: {
        ...mapGetters([
            'rebateAmount', // 返利金额相关数据
            'inviteCode', // 邀请码
            'inviteAddress', // 邀请地址
            'rebateExplains', // 返利规则
        ])
    },
    data () {
        return {
            config,
            showRegraInfo:false,
            dialogVisible: false,
        }
    },
    created () {
        _availableAmountAction()
        _referralLinkAction().then(res => {
            if (res) {
                _createQRcode({
                    id: 'qrcode',
                    content: this.inviteAddress,
                    width: 140,
                    height: 140
                })
            }
        })
        setTimeout(() => {
            _allowanceExplain()
        }, 1000)
    },
    methods: {
        _jump,
        // 复制
        copy (text) {
            _copy(text)
        },
        // 下载二维码
        downloadQRcode () {
            if (this.$refs.qrcode.children && this.$refs.qrcode.children[1]) {
                _download(this.$refs.qrcode.children[1].src, 'qrcode.png')
            }
        },
        // 打开领取弹窗
        determineDialog () {
            // const msg = `未满足领取要求，最低领取金额${this.rebateAmount.minCount}{{config.unit}},有效会员人数≥${this.rebateAmount.meetConditions}人,领取上限${this.rebateAmount.maxReceive}`
            if (this.rebateAmount.receiveFalse) return this.dialogVisible = true
            const msg = this.$t("userPage.text183") + this.rebateAmount.minCount + config.unit
                config.unit + this.$t("userPage.text184") + this.rebateAmount.meetConditions
                this.$t("userPage.text185") + this.rebateAmount.maxReceive
            Message.error(msg)
        },
        // 领取
        determine () {
            _getReceive().then(res => {
                if (res && res.code == 0) {
                    Message.success(this.$t("userPage.text186"))
                    this.dialogVisible = false
                    _availableAmountAction()
                } else {
                    Message.error(res.msg || this.$t("userPage.text187"))
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import "~@/assets/css/page-rebate.scss";
</style>
<!-- 公告和站内信 -->
<template>
    <div class="page-notice-mail">
        <div class="title">{{$t("userPage.text160")}}</div>
        <!-- 顶部Tab -->
        <Tab ref="tab" :arr="tabArr" @tab="tab" style="width:100%;justify-content: flex-start;margin-bottom: 35px;align-items: flex-start;" :activeIndex="activeIndex" />
        
        <!-- 站内信 -->
        <MailList v-if="activeIndex == 0" />
        <!-- 公告 -->
        <NoticeList v-if="activeIndex == 1" />
    </div>
</template>

<script>
import NoticeList from './NoticeList.vue'
import MailList from './MailList.vue'
import Tab from '../components/MailTab.vue'
export default {
    components: {
        NoticeList,
        MailList,
        Tab,
    },
    
    data () {
        return {
            activeIndex: 0,
            tabArr: []
        }
    },
    methods: {
        // 切换导航
        tab (num) {
            if (this.activeIndex == num) return
            this.activeIndex = num
        }
    }
}
</script>

<style scoped lang="scss">
.page-notice-mail {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .title {
        width:100%;
        font-weight:700;
        color:#ffffff;
        font-size:30px;
    }
    .line {
        width:100%;
        height:2px;
        margin-top:36px;
        background-color:#000;
    }
}
</style>
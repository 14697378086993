<!-- 顶部导航 -->
<template>
    <div class="my-tab">
        <div class="tab-item" v-for="(item, i) in arr" :key="i" @click="tab(i)" :class="index==i?'active-tab':''">{{ item }}</div>
    </div>
</template>

<script>
export default {
    props: {
        tabIndex: { // 外部注入的tab下标
            type: Number,
            default: 0,
        },
        arr: { // tab列表数组
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            index: 0
        }
    },
    created () {
        this.index = this.tabIndex
    },
    watch: {
        tabIndex (val) {
            this.index = val
        },
        '$route.query.t': {
            handler () {
                setTimeout(() => {
                    const i = this.$route.query.tab
                    if (!isNaN(i))  this.tab(this.$route.query.tab)
                })
            },
            immediate: true
        }
    },
    methods: {
        tab (index) {
            this.index = index
            this.$emit('tab', index)
        }
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/my/';
.my-tab {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tab-item {
        width: 160px;
        margin-right: 40px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        font-size: 18px;
        background:url(#{$imgUrl}tab_bg.png)no-repeat center/contain;
    }
    .active-tab {
        background:url(#{$imgUrl}tab_bg_active.png)no-repeat center/contain;
    }
}
</style>
<template>
  <div id="app" class="theme">
    <router-view/>
  </div>
</template>

<script>
import { _systemInit } from './core/comp/global'
export default {
  created () {
    _systemInit()
    // import("./assets/css/theme" + a + ".css");
  }
}
</script>

<style>
/* 全局样式 */
html, body {
  padding: 0;
  margin: 0;
  min-width: 1200px;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
div {
  box-sizing: border-box;
}

/* 主题样式 */
.theme { color: #333; }
.theme .background-color { background-color: #fff }


/* 穿透样式 */
.deep .component-name .class-name { color: antiquewhite !important; }


/* element样式修改 */
.element-class { color: black !important; }
</style>

<!-- 活动记录 -->
<template>
    <div class="page-active">
        <div class="views-title border">{{$t("userPage.text1")}}</div>
        <div class="remark">{{$t("userPage.text2")}}</div>
        <!-- 活动名称 -->
        <div class="input-box">
            <div class="text">{{$t("userPage.text3")+"："}}</div>
            <div class="input"><Input :placeholder='$t("userPage.text13")' v-model="form.name" /></div>
        </div>
        <!-- 活动时间 -->
        <div class="input-box">
            <div class="text">{{$t("userPage.text4")+"："}}</div>
            <div class="date-box">
                <DatePicker v-model="form.startTime" :placeholder='$t("userPage.text5")' />
                <span class="split-line">--</span>
                <DatePicker v-model="form.endTime" :placeholder='$t("userPage.text5")' />
            </div>
            <div class="buttom-box">
                <div class="query-buttom"  @click="query">{{$t("userPage.text6")}}</div>
                <div class="reset-buttom" @click="reset">{{$t("userPage.text7")}}</div>
            </div>
        </div>
        <div class="content-box" v-if="recordActiveList.length > 0">
            <!-- 表格 -->
            <Table :data="recordActiveList" >
                <!-- 活动名称 -->
                <TableColumn prop="actName" align="center" :label='$t("userPage.text8")'></TableColumn>
                <!-- 参与时间 -->
                <TableColumn prop="joinTime" align="center" :label='$t("userPage.text9")'></TableColumn>
                <!-- 操作 -->
                <TableColumn :label='$t("userPage.text10")' align="center"> 
                    <template slot-scope="scope">
                        <Button type="text"  @click="checkDetails(scope.row)">{{$t("userPage.text11")}}</Button>
                    </template>
                </TableColumn>
            </Table>
            <div class="pagination-boxs">
                <!-- 分页 " -->
                <Pagination
                    layout="prev, pager, next"
                    :hide-on-single-page="true"
                    :current-page.sync="form.currPage"
                    :page-size="form.pageSize"
                    :total="recordActiveTotal"
                    @size-change="getData"
                    @current-change="getData"
                    ></Pagination>
            </div>
            
        </div>
        <div v-else class="null-box">
            <img loading="lazy" v-lazy="require('../../../assets/images/my/null.png')">
            <div>{{$t("userPage.text12")}}</div>
        </div>
    </div>
</template>

<script>
import { Input, DatePicker, Button, Pagination, Table, TableColumn } from 'element-ui'
import { mapGetters } from 'vuex'
import { _getPartakeAction } from '@/core/comp/pages/records'
import { _activeInfoAction } from '@/core/comp/common'

export default {
    components: {
        Input,
        DatePicker,
        Button,
        Pagination,
        Table,
        TableColumn,
    },
    computed: {
        ...mapGetters([
            'recordActiveTotal', // 总条数
            'recordActiveList', // 表单数据
        ])
    },
    data () {
        return {
            form: {
                name: '',
                startTime: '',
                endTime: '',
                currPage: 1,
                pageSize: 5,
            },
        }
    },
    created () {
        this.getData()
    },
    methods: {
        // 查看详情
        checkDetails (item) {
            console.log('---查看详情', item)
            item._titleText = item.actName
            _activeInfoAction(item)
        },
        // 获取数据
        getData () {
            console.log('获取数据')
            _getPartakeAction(this.form)
        },
        // 查询
        query () {
            this.form.currPage = 1
            setTimeout(() => {
                this.getData()
            }, 0)
        },
        // 充值
        reset () {
            this.form = {
                name: '',
                startTime: '',
                endTime: '',
                currPage: 1,
                pageSize: 5,
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/page-active.scss";
</style>
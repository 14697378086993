<!-- banner -->
<template>
    <div style="width:100%;height:100%;">
        <Carousel style="width:100%;height:100%"
            height="100%"
            :interval="interval"
        >
            <CarouselItem @click.native="tapItem(item)" style="width:100%;height:100%" v-for="(item, i) in list" :key="i">
                <img :src="systemImgHost + item.picture"  style="width:100%;height:100%">
            </CarouselItem>
        </Carousel>

        <!-- 限时活动详情 -->
        <TimeDiscountDetail ref="td"></TimeDiscountDetail>
    </div>
</template>

<script>
import { Carousel, CarouselItem, Message } from 'element-ui'
import { _getBanner } from '../api/modules/system'
import { mapGetters } from 'vuex'
import { _activeInfoAction, _getToken } from '../comp/common'
import TimeDiscountDetail from './TimeDiscountDetail.vue'

export default {
    props: {
        interval: {
            type: Number,
            default: 5000,
        }
    },
    components: {
        Carousel,
        CarouselItem,
        TimeDiscountDetail,
    },
    computed: {
        ...mapGetters([
            'systemImgHost', // 图片根域名
            'isLogin', //  登录状态
            'userData', // 用户数据
            'userAccessToken', // 用户token
        ])
    },
    data () {
        return {
            list: []
        }
    },
    mounted () {
        _getBanner().then(res => {
            if (!res) return            
            this.list = res.data
        })
    },
    methods: {
        // 点击banner
        tapItem (item) {
            console.log('---点击', item)
            // item.type 1:外链 2:公告 3:活动 4:游戏 5:专题活动
            switch (item.type) {
                case 1:
                    if (item.url) window.open(item.url)
                    break
                case 2:
                    if (!this.isLogin) return Message.error(this.$t("errorMessage.text1"))//请先登录
                    this.$router.push({
                        name: 'notice',
                        query: {
                            id: item.urlId,
                            tab: 1,
                            t: Date.now()
                        }
                        
                    })
                    
                    break
                case 3:
                    item._titleText = item.actName
                    _activeInfoAction(item)
                    break
                case 4:
                    if (!this.isLogin) return Message.error(this.$t("errorMessage.text1"))//请先登录
                    _getToken(item.bannerGame.id, 1)
                    break
                case 5:
                    if (item.expand && item.expand.actType == 3) {
                        // 双旦活动
                        return this.jump11(item)
                    }
                    this.$refs.td.open(item.id)
                    break
            }
        },
        // 双旦活动
        jump11 (item) {
            if (!this.isLogin) return Message.error(this.$t("errorMessage.text1"))//请先登录
            const obj = {
                activityId: item.urlId,
                clientCode: window.clientCode,
                username: this.userData.username,
                language: window.locale,
                theme: window.theme,
                host: localStorage.getItem('host'),
                token: this.userAccessToken,
            }
            const str = window.btoa(JSON.stringify(obj))
            const url = window.location.origin + "/func/" + item.expand.actFolder + "/pc/index.html?s=" + str
            window.open(url,"_blank")
        }
    }
}
</script>
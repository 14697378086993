<!-- 优惠活动详情 -->
<template>
    <div class="WrapPromoInfo" :style="'background:url('+  _getImgUrl('activity_bg','.jpg') + ') no-repeat top center/contain #000'">
        <div class="page-promoinfo">
            <div class="promoinfo-goblack" style="cursor:pointer" @click="back">{{$t("userPage.text45")}}</div>
            <div class="promoinfo-title">{{ title }}</div>
            <div class="promoinfo-time">
                <span class="promoinfo-titlebg"> {{ forever == 1 ? $t("promoInfo.text2") : $t("promoInfo.text3")}}</span>
                <span>{{ time }}</span>
            </div>
            <!-- 内容 -->
            <div v-html="info"></div>
            <!-- 底部按钮 -->
            <div class="prominfo-bottom">
                <!-- <div class="promoinfo-btn"  style="cursor:pointer" @click="back">返回</div> -->
                <!-- 状态 -->
                <div class="promoinfo-text">
                    <!-- 咨询客服领取 -->
                    <template v-if="infoData.type == 2">
                        <div class="promoinfo-btn" @click="_toCustomerService">{{$t("promoInfo.text6")}}</div>
                    </template>
                    <!-- 站内活动 -->
                    <template v-if="infoData.type && infoData.type != 2">
                        <!-- 活动生效，立即参与 -->
                        <div @click="partIn" 
                            v-if="!infoData.auditStatus && typeof infoData.auditStatus == 'object' && infoData.isEffect"
                            class="promoinfo-btn">
                            {{$t("promoInfo.text7")}}
                        </div>
                        <!-- 进行中 -->
                        <template v-if="typeof infoData.auditStatus == 'number'">
                            <div>{{$t("promoInfo.text8")}}：{{ promoStatus(infoData) }}</div>
                            <div>
                                <!-- 查看活动记录 -->
                                <div  class="promoinfo-btn" v-if="actRecordsList.length > 0" @click="openActRecords">{{$t("promoInfo.text9")}}</div>
                            </div>
                        </template>
                        <!-- 未参与，活动截止 -->
                        <template v-if="!infoData.auditStatus && typeof infoData.auditStatus == 'object' && !infoData.isEffect">
                            <div>{{$t("promoInfo.text10")}}</div>
                            <div>
                                <!-- 活动局数记录 -->
                                <div v-for="(item, i) in activityCompletionArr" :key="i">
                                    <span>{{ item[0] }}：</span>
                                    <span>¥</span><span>{{ _setNumFixed(item[1], 2) }}</span>
                                </div>
                                <!-- 查看活动记录 -->
                                <div  class="promoinfo-btn" v-if="actRecordsList.length > 0" @click="openActRecords">{{$t("promoInfo.text11")}}</div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
            <!-- 活动记录-弹窗 -->
            <Dialog :title='$t("promoInfo.text12")' :visible.sync="dialogVisible" width="666" center>
                <Table :data="actRecordsList" border>
                    <TableColumn prop="censusDate" :label='$t("promoInfo.text13")'></TableColumn>
                    <TableColumn prop="activityCompletionStr" :label='$t("promoInfo.text14")'></TableColumn>
                    <TableColumn prop="auditStatus" :label='$t("promoInfo.text15")'></TableColumn>
                </Table>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { _setNumFixed,_getImgUrl } from '@/core/utils/utils'
import { _toCustomerService } from '@/core/comp/common'
import { _partIn, _promoStatus } from '@/core/comp/pages/promo'
import { Dialog, Table, TableColumn } from 'element-ui'
export default {
    components: {
        Dialog,
        Table,
        TableColumn,
    },
    data () {
        return {
            id: '', // 活动id
            title: '', // 活动标题
            time: '', // 活动时间
            info: '', // 活动详情
            infoData: {}, // 活动数据
            activityCompletionArr: [], // 活动局数记录
            actRecordsList: [], // 活动记录列表
            forever: '', // 时效 1-长期活动 0-限时活动 
            dialogVisible: false, // 活动记录弹窗开关
        }
    },
    mounted () {
        this.init()
    },
    computed:{
        lang(){
            return window.locale || "zh_CN";
        }
    },
    methods: {
        _getImgUrl,
        _toCustomerService,
        _setNumFixed,
        // 初始化数据
        init () {
            const query = this.$route.query
            this.id = query.pid
            this.title = query.title
            this.time = query.time
            this.forever = query.forever
            this.info = localStorage.getItem('promoInfo')
            try {
                this.infoData = JSON.parse(localStorage.getItem('promoInfoData'))
            } catch {
                console.erroe('编译活动数据失败')
                this.infoData = {}
            }
            // 活动记录
            this.actRecordsList = []
            if (this.infoData.list && this.infoData.list.length) {
                // 活动记录数据处理
                this.actRecordsList = this.infoData.list.map(item => {
                    // 日期
                    if (item.censusDate) {
                        const date = new Date(item.censusDate)
                        item.censusDate = `${date.getMonth() + 1}月${date.getDate()}日`
                    } else {
                        item.censusDate = '--/--'
                    }
                    // 完成条件
                    if (!item.activityCompletionStr) item.activityCompletionStr = '--/--'
                    // 状态
                    item.auditStatus = this.promoStatus(item)
                    return item
                })
            }
            // 活动局数
            const activityCompletion = this.infoData.activityCompletion
            this.activityCompletionArr = []
            if (activityCompletion && JSON.stringify(activityCompletion) != '{}') {
                for (const k in activityCompletion) {
                    this.activityCompletionArr.push({
                        0: k,
                        1: activityCompletion[k]
                    })
                }
            }
        },
        // 返回
        back () {
            this.$router.back(-1)
        },
        // 获取活动状态
        promoStatus (activityData) {
            return _promoStatus(activityData)
        },
        // 立即参与
        partIn () {
            _partIn().then(res => {
                if (res) this.init()
            })
        },
        // 查看活动记录
        openActRecords () {
            this.dialogVisible = true
        }
    }
}
</script>
<style lang="scss" scoped>
$imgUrl: '~@/assets/images/index/';
    .WrapPromoInfo{
        width: 100%;
        padding: 50px 0;
        height: auto;
        min-height: 536px;
        // background:url(#{$imgUrl}activity_bg.jpg) no-repeat top center/contain #000;
        .page-promoinfo{
            width: 1200px;
            margin: 0 auto;
            padding: 30px 16px;
            border-radius: 20px;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            .promoinfo-goblack{
                color: #000;
                width: max-content;
                padding:8px 25px;
                position: absolute;
                top: 0;
                left:0;
                border-bottom-right-radius: 15px;
                // background-color: #fee0ba;
                background: linear-gradient(to top,#c9b254,#fde499);
            }
            .promoinfo-title{
                text-align: center;
                color: #000;
                font-weight: bold;
                font-size: 30px;
            }
            .promoinfo-time{
                text-align: center;
                font-size: 14px;
                margin: 15px 0;
                color: #8e8e8e;
                display: flex;
                justify-content: center;
                align-items: center;
                .promoinfo-titlebg{
                    padding: 0 7px;
                    color: #fff;
                    font-size: 14px;
                    margin-right: 10px;
                    display: inline-block;
                    background-color: #fee0ba;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }
            }
        }
        .promoinfo-flex{
            display:flex;
            align-items: center;
        }
        .prominfo-bottom{
            margin-top: 20px;
            .promoinfo-btn{
                display:flex;
                align-items: center;
                justify-content: center;
                color: #000;
                width: max-content;
                padding:7px 30px;
                border-radius: 10px;
                background: linear-gradient(to top,#c9b254,#fde499);
            }
            .promoinfo-text{
                display:flex;
                margin-top: 10px;
                align-items: center;
            }
        }
    }
</style>
<!-- 主页面-顶部 -->
<template>
    <div class="wrap-header">
        <div class="wrap-header-content">
            <div class="wrap-header-left">
                <div class="header-time">
                    <div class="header-time-content">
                        <span class="header-time-text">{{zone}}</span>
                        <span>{{$t("homePageIndex.text1")}}：</span>
                        <span class="header-time-text">{{date}}</span>
                        <span >{{time}}</span>
                    </div>
                </div>
                <div>
                    <span>{{$t("homePageIndex.text2")}}</span>
                </div>
            </div>
            <div class="wrap-header-right">
                <ul>
                    <li v-for="(item,index) in systemQuickEntry" :key="index"  :class="item.className" @click="goLink(item.jumpContent)">
                        <span :style="{color: item.shineColor}">{{ item.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _jump ,_formatDate} from '@/core/utils/utils'
import { _listQuickEntry } from '@/core/api/modules/system'
export default {
    components: {
    },
    data () {
        return{
            zone: 'UTC+8',
            date: '',
            time: '',
            interval: 0,
            scroll:3000,
            
        }
    },
    computed: {
        ...mapGetters([
            'isLogin',
            'systemQuickEntry',
        ])
    },
    methods: {
        _jump,
        goLink(link){
            if(!link) return;
            if(link == 'mobile'){
                this.$router.push({ 
                    name: 'home',
                    query:{
                        scroll:this.scroll++
                    }
                });
                this.$emit('scrollToCode')
            }else if(link == 'help'){
                this.$router.push({ 
                    name: 'help',
                    query:{
                        id:7
                    }
                });
            }else{
                window.open(link)
            }
        },
        // 时间
        getTime () {
            this.interval = setInterval(() => {
                var bjTime = this.getBjTime();
                this.date = _formatDate(bjTime, 'yyyy-MM-dd')
                this.time = _formatDate(bjTime, 'HH:mm:ss')
            }, 500)
        },
        getBjTime() {
            //获得当前运行环境时间
            var d = new Date(), currentDate = new Date(), tmpHours = currentDate.getHours();
            //算得时区
            var time_zone = -d.getTimezoneOffset() / 60;
            //少于0的是西区 西区应该用时区绝对值加京八区 重新设置时间（西区时间比东区时间早 所以加时区间隔）
            if (time_zone < 0) {
                time_zone = Math.abs(time_zone) + 8; currentDate.setHours(tmpHours + time_zone);
            } else {
                //大于0的是东区  东区时间直接跟京八区相减
                time_zone -= 8; currentDate.setHours(tmpHours - time_zone);
            }
            return currentDate;
        },

    },
    mounted () {
        this.getTime()
        setTimeout(() => {
            // 获取快捷入口
            _listQuickEntry()
        }, 1000)
    },
    beforeDestroy () {
        if (this.interval) clearInterval(this.interval)
    }
}
</script>

<style lang="scss" scoped >
    .wrap-header {
        width: 100%;
        height: 40px;
        color: #fff;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .wrap-header-content {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wrap-header-left{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .header-time,.header-time-text{
                margin-right: 10px;
            }
        }
        .wrap-header-right{
            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                height:30px;
                li{
                    width: auto;
                    padding:0 10px;
                    height: 12px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    &:last-child{
                        border-right: none;
                    }
                }
                li.li_color1{
                    color: #B65FCC;
                }
                li.li_color2{
                    color: #E10B0C;
                }
                li.li_color3{
                    color: #FEEA00;
                }
            }
        }
    }
    .wrap-header-logined {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
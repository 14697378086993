<template>
  <div class="jackPotNew">
    <Dialog width="1070px" :visible.sync="adVisible" :show-close="false">
      <div class="jkContent">
        <ul class="jkTabs">
          <li v-for="(item,index) in systemJackpotNew" :key="index" :class="{ 'active': index === currentIndex }" @click="changeTab(item,index)">
            <i></i>
            <span>{{ item.title }}</span>
          </li>
        </ul>
        <div class="jkHtml">
          <img @click="bannerTransgo(currentList)" :src="systemImgHost + currentList.picture" />
        </div>
      </div>
      <div class="jkClose" @click="closePop"></div>
    </Dialog>
  </div>
</template>
<script>
import { Dialog, Message } from 'element-ui'
import { _getGuideAd } from '@/core/api/modules/system'
import { mapGetters } from 'vuex'
import { _activeInfoAction, _getToken } from '@/core/comp/common'
export default {
  data() {
    return {
      adVisible: false,
      isToday: "",
      currentIndex: 0, //当前tab
      currentList: {} //当前列表
    };
  },
  components: {
    Dialog
  },
  computed: {
      ...mapGetters([
          'systemImgHost', // 图片根域名
          'isLogin', //  登录状态
          'userData', // 用户数据
          'userAccessToken', // 用户token
          'systemJackpotNew',//公告列表
      ])
  },
  mounted() {
    this.getGuideAd();
  },
  methods: {
    getGuideAd(){
      _getGuideAd().then(res => {
        // first.popUpRule  0-不弹  1-每日弹一次  2-次次弹
        if(res && res.length > 0){
          const first = res[0]
          this.currentList = first || {};
          if(first.popUpRule != 0) this.adVisible = true
        }else{
          this.adVisible = false
        }
      })
    },
    changeTab(item,index) {
      if (index === this.currentIndex) return;
      this.currentIndex = index;
      this.currentList = item;
    },
    closePop() {
      this.adVisible = false;
    },
    // banner图跳转
    bannerTransgo (item) {
        console.log('---点击', item)
        // item.type 1:外链 2:公告 3:活动 4:游戏 5:专题活动
        switch (item.type) {
            case 1:
                if (item.url) window.open(item.url)
                break
            case 2:
                if (!this.isLogin) return Message.error(this.$t("errorMessage.text1"))//请先登录
                this.$router.push({
                    name: 'notice',
                    query: {
                        id: item.urlId,
                        tab: 1,
                        t: Date.now()
                    }
                })
                break
            case 3:
                item._titleText = item.actName
                _activeInfoAction(item)
                break
            case 4:
                if (!this.isLogin) return Message.error(this.$t("errorMessage.text1"))//请先登录
                _getToken(item.bannerGame.id, 1)
                break
            case 5:
                if (item.expand && item.expand.actType == 3) {
                    // 双旦活动
                    return this.jump11(item)
                }
                this.$refs.td.open(item.id)
                break
        }
    },
    // 双旦活动
    jump11 (item) {
        if (!this.isLogin) return Message.error(this.$t("errorMessage.text1"))//请先登录
        const obj = {
            activityId: item.urlId,
            clientCode: window.clientCode,
            username: this.userData.username,
            language: window.locale,
            theme: window.theme,
            host: localStorage.getItem('host'),
            token: this.userAccessToken,
        }
        const str = window.btoa(JSON.stringify(obj))
        const url = window.location.origin + "/func/" + item.expand.actFolder + "/pc/index.html?s=" + str
        window.open(url,"_blank")
    }
  },
};
</script>
<style lang="scss">
$imgUrl: '~@/assets/images/index/home/';
.jackPotNew {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f6daa7;
    border-radius: 5px;
  }
  div {
    box-sizing: border-box;
  }
  .el-dialog__wrapper{
    .el-dialog {
      background-color: transparent !important;
      .el-dialog__header {
        padding: 0 !important;
      }
      .el-dialog__body {
        padding: 0;
        .jkContent {
          display: flex;
          justify-content: space-between;
          width: 1070px;
          height: 497px;
          padding: 35px 22px 62px 35px;
          background: url(#{$imgUrl}jk_bg.png);
          .jkTabs {
            width: 338px;
            overflow: auto;
            li {
              cursor: pointer;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              width: 328px;
              height: 55px;
              margin-bottom: 15px;
              padding: 0 25px;
              background: url(#{$imgUrl}jk_tab.png) no-repeat; 
              i {
                display: inline-block;
                width: 50px;
                height: 28px;
                margin-right: 6px;
                background: url(#{$imgUrl}jk_icon.png); 
              }
              span {
                font-size: 22px;
                color: #bebebe;
                max-width: 221px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              &.active {
                background: url(#{$imgUrl}jk_tab_active.png) no-repeat; 
                i {
                  background: url(#{$imgUrl}jk_icon_active.png); 
                }
                span {
                  color: #000;
                }
              }
              &:hover {
                opacity: .9;
              }
            }
          }
          .jkHtml {
            width: 658px;
            height: 396px;
            overflow: auto;
            border: solid 1px #4a4333;
            // background-color: #fff;
            border-radius: 7px;
            // padding: 10px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 7px;
              cursor: pointer;
            }
          }
        }
        .jkClose {
          cursor: pointer;
          width: 80px;
          height: 80px;
          margin: 15px auto 0;
          background: url(#{$imgUrl}jk_close.png) no-repeat;
          &:hover {
            opacity: .9;
          }
        }
      }
    }
  }
}
</style>
// 个人资料
import store from '../../store/index'
import { Message, MessageBox } from 'element-ui'
import i18n from '@/il8n/index'
import { _updateInfo, _withdrawPass, _updatePassword, _addBankCard, _getBankList } from '../../api/modules/user'
import { _phoneIsValid, _weChatIsValid, _qqIsValid, _emailIsValid, _fundPassword } from '../../utils/utils'

// 修改个人资料
export const _modifyUserAction = form => {
    if (form.phone && !_phoneIsValid(form.phone)) return Message.error(i18n.t("errorMessage.text69"))//手机格式不正确
    if (form.qq && !_qqIsValid(form.qq)) return Message.error(i18n.t("errorMessage.text38"))//QQ号码不存在
    if (form.wechat && !_weChatIsValid(form.wechat)) return Message.error(i18n.t("errorMessage.text39"))//微信号码不存在
    if (form.email && !_emailIsValid(form.email)) return Message.error(i18n.t("errorMessage.text40"))//邮箱地址不存在
    const data = {
        userId: store.getters.userData.userId,
        birthday: form.birthday || null,
        email: form.email || null,
        nickname: form.nickname || null,
        phone: form.phone || null,
        qq: form.qq || null,
        realName: form.realName || null,
        wechat: form.wechat || null,
    }
    _updateInfo(data).then(res => {
        store.dispatch('userInfoAction')
        if (res) Message.success(i18n.t("errorMessage.text41"))//更新成功！
    })
}

// 修改资金密码
export const _modifyFundPass = (form, callback) => {
    if (!_fundPassword(form.newPass)) return Message.error(i18n.t("errorMessage.text42"))//密码必须为6位数字
    if (form.newPass != form.confirmPass) return Message.error(i18n.t("errorMessage.text43"))//两次输入的密码不一致
    const withdrawPassWordState = store.getters.userData.withdrawPassWordState
    let params = {}
    if (withdrawPassWordState) { // 设置过资金密码
        if (!_fundPassword(form.oldPass)) return Message.error(i18n.t("errorMessage.text44"))//请输入正确的旧密码
        params = {
            newWithdrawpassword: form.newPass,
            withdrawpassword: form.oldPass,
        }
    }
    else { // 未设置过资金密码
        params = {
            withdrawpassword: form.newPass
        }
    }
    _withdrawPass(params).then(res => {
        if (res && res.code == 0) { // 修改成功
            Message.success(i18n.t("errorMessage.text10"))//操作成功
            store.dispatch('userDataAction', { withdrawPassWordState: true })
            if (callback) callback()
        } else {
            Message.error(res.msg || i18n.t("errorMessage.text9"))//操作失败
        }
    })
}

// 修改登录密码
export const _modifyPassword = (form, callback) => {
    if (form.newPass != form.confirmPass) return Message.error(i18n.t("errorMessage.text43"))//两次输入的密码不一致
    const params = {
        newpassword1: form.newPass,
        password: form.oldPass
    }
    _updatePassword(params).then(res => {
        if (res && res.code == 0) { // 修改成功
            Message.success(i18n.t("errorMessage.text10"))//操作成功
            if (callback) callback()
        } else {
            Message.error(res.msg || i18n.t("errorMessage.text9"))//操作失败
        }
    })
}

// 添加银行卡前置验证
export const _checkAddStatus = () => {
    const user = store.getters.userData
    return new Promise(resolve => {
        // 真实姓名校验
        if (!user.realName) {
            // 请填写真实姓名再添加银行卡
            MessageBox.alert(
                i18n.t('errorMessage.text45'),
                i18n.t('errorMessage.text46'),
                {
                    type: 'warning'
                }
            ).then(() => {
                resolve('realName')
            }).catch(() => {
                resolve(false)
            })
            return
        }
        // 资金密码校验
        if (!user.withdrawPassWordState) {
            // 请先设置取款密码再添加银行卡
            MessageBox.alert(
                i18n.t('errorMessage.text47'),
                i18n.t('errorMessage.text46'),
                {
                    type: 'warning'
                }
            ).then(() => {
                resolve('withdrawPassWordState')
            }).catch(() => {
                resolve(false)
            })
            return
        }
        // 手机绑定校验
        if (store.getters.userBankBindPhone && !user.phone) {
            // 请绑定手机号再添加银行卡
            MessageBox.alert(
                i18n.t('errorMessage.text48'),
                i18n.t('errorMessage.text46'),
                {
                    type: 'warning'
                }
            ).then(() => {
                resolve('phone')
            }).catch(() => {
                resolve(false)
            })
            return
        }
        // 通过校验，可以去绑卡
        resolve('pass')
    })
}

// 添加银行卡
// form-表单  isUSDT-是否是数字货币
export const _addCard = (form, isUSDT) => {
    if (!form.name) return Message.error(i18n.t("errorMessage.text49"))//请选择收款方式
    if (!form.branch) return Message.error(isUSDT ? i18n.t("errorMessage.text50") : i18n.t("errorMessage.text51"))//ERC20或TRC20、支行名称
    if (!form.number) return Message.error(isUSDT ? i18n.t("errorMessage.text52") : i18n.t("errorMessage.text53"))//
    if (form.number != form.number2) return Message.error(isUSDT ? i18n.t("errorMessage.text54") : i18n.t("errorMessage.text55"))//钱包地址、银行卡号不一致
    if (store.getters.userBankBindPhone) { // 需要短信验证
        if (!form.code) return Message.error(i18n.t("errorMessage.text33"))//验证码
    }
    const user = store.getters.userData
    const params = {
        ...form,
        ...{
            memberId: user.userId,
            account: user.realName,
            type: isUSDT ? 1 : form.name == 'USDT' ? 2 : 0, // 银行卡type为0 ，数字货币type为1，origo钱包的时候 type为2,
            phone: user.phone,
            smsCode: form.code
        }
    }
    return new Promise(resolve => {
        _addBankCard(params).then(res => {
            if (res && res.code == 0) {
                _getBankList()
                Message.success(i18n.t("errorMessage.text57"))//添加成功
                resolve(res)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text58"))//添加失败
                resolve(false)
            }
        })
    })
}
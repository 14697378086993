// 代理相关接口
import { _post, _get } from '../request'
import store from '../../store/index'
import { Message } from 'element-ui'
import i18n from '@/il8n/index'


// 获取代理规则
export const _agentCommissionPlan = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/agentCommissionPlan/getAgentCommissionPlan', '', true).then(res => {
            if (res && res.code == 0) {
                store.dispatch('agentInfoAction', res.data || {})
                resolve(res)
            } else {
                resolve(false)
                // 获取代理规则失败
                Message.error(res.msg || i18n.t("errorMessage.text2"))
            }
        })
    })
}

// 代理申请
export const _appSave = params => _post('/agent/api/v1/agent/appSave', params, true)
<!-- 常见问题 -->
<template>
    <div class="commonProblem">
        <div class="titleBOx">
               <h2 class="title">
                {{$t('problem.text11')}}
            </h2>
        </div>
        <div class="content"> 
            <h3 class="tip1"> {{$t('problem.text12')}}</h3>
            <h5 class="tip4"> {{$t('problem.text13')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text14')}}</p>
            <h5 class="tip4"> {{$t('problem.text15')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text16')}}</p>
            <h5 class="tip4"> {{$t('problem.text19')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text20')}}</p>
            <h5 class="tip4"> {{$t('problem.text21')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text22')}}</p>

            <h3 class="tip1"> {{$t('problem.text23')}}</h3>
            <h5 class="tip4"> {{$t('problem.text24')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text25')}}</p>

            <h5 class="tip4"> {{$t('problem.text26')}}</h5>
            <p class="tip2"> {{$t('problem.text27')}}</p>
            <p class="tip2 tip6"> {{$t('problem.text28')}}</p>
            <p class="tip2"> {{$t('problem.text29')}}</p>
            <p class="tip2"> {{$t('problem.text30')}}</p>
            <p class="tip2"> {{$t('problem.text31')}}</p>
            <p class="tip2 tip6"> {{$t('problem.text32')}}</p>
            <p class="tip2"> {{$t('problem.text33')}}；</p>
            <p class="tip2 tip5"> {{$t('problem.text34')}}</p>

            <h5 class="tip4"> {{$t('problem.text35')}}</h5>
             <p class="tip2 tip6"> {{$t('problem.text36')}}</p>
            <p class="tip2 tip7"> {{$t('problem.text37')}}</p>
             <p class="tip2 tip6"> {{$t('problem.text38')}}</p>
            <p class="tip2 tip7"> {{$t('problem.text39')}}</p>
             <p class="tip2 tip6"> {{$t('problem.text40')}}</p>
            <p class="tip2 tip5"> {{$t('problem.text41')}}</p>

             <h5 class="tip4"> {{$t('problem.text42')}}</h5>
             <p class="tip2 tip6"> {{$t('problem.text43')}}</p>
            <p class="tip2 tip7"> {{$t('problem.text44')}}</p>
             <p class="tip2 tip6"> {{$t('problem.text45')}}</p>
            <p class="tip2 tip5"> {{$t('problem.text46')}}</p>

            <h5 class="tip4"> {{$t('problem.text47')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text48')}}</p>

            <h3 class="tip1"> {{$t('problem.text49')}}</h3>
            <h5 class="tip4"> {{$t('problem.text50')}}</h5>
            <p class="tip2"> {{$t('problem.text51')}}</p>
            <img loading="lazy" :src="_getImgUrl('text/help8')" alt="">
            <h5 class="tip4"> {{$t('problem.text52')}}</h5>
            <p class="tip2"> {{$t('problem.text53')}}</p>
            <p class="tip2"> {{$t('problem.text54')}}</p>
            <p class="tip2 tip5"> {{$t('problem.text55')}}</p>

            <h5 class="tip4"> {{$t('problem.text56')}}</h5>
            <p class="tip2"> {{$t('problem.text57')}} </p>
            <a class="tip2 tip6" target='_blank' href="http://www.macromedia.com/support/documentation/cn/flashplayer/help/settings_manager07.html">http://www.macromedia.com/support/documentation/cn/flashplayer/help/settings_manager07.html</a>
            <p class="tip2 tip5"> {{$t('problem.text58')}}</p>

            <h5 class="tip4"> {{$t('problem.text59')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text60')}}</p>
6
            <h5 class="tip4"> {{$t('problem.text61')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text62')}}</p>

            <h5 class="tip4"> {{$t('problem.text63')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text64')}}</p>
            <h5 class="tip4"> {{$t('problem.text65')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text66')}}</p>

            <h5 class="tip4"> {{$t('problem.text67')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text68')}}</p>
            <h5 class="tip4"> {{$t('problem.text69')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text70')}}</p>

            <h3 class="tip1"> {{$t('problem.text71')}}</h3>
             <h5 class="tip4"> {{$t('problem.text72')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text73')}}</p>
             <h5 class="tip4"> {{$t('problem.text74')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text75')}}</p>
             <h5 class="tip4"> {{$t('problem.text76')}}</h5>
            <p class="tip2 tip5"> {{$t('problem.text77')}}</p>

        </div>
    </div>
</template>
<script>
import {_getImgUrl} from '@/core/utils/utils'
export default {
    data(){
        return {
        }
    },
    computed: {
    },
    methods:{
        _getImgUrl
    },
    mounted(){

    }
}
</script>
<style scoped lang='scss'>
    .commonProblem{
        .titleBOx{
            .title{
                font-weight:700;
                color:#fdf86a;
                font-size:16px;
            }
        }
        .content{
            .tip1{
                padding: 15px 0px 20px;
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                color: #fff;
            }
            .tip2{
                line-height: 28px;
                font-size: 14px;
                color: #ccc;
                .tip3{
                    color: #ec2828;
                }
            }
            .tip5{
                margin-bottom: 20px;
            }
            .tip7{
                margin-bottom: 10px;
            }
            .tip4{
                color: #fff;
                font-size: 15px;
                padding: 0px 0px 10px;
            }
            .tip6{
                color: rgb(0, 68, 153);
            }
            img{
                width: 100%;
                display: inline-block;
                margin-bottom: 30px;
            }
        }
    }
</style>
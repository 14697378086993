<!-- 主页-底部 -->
<template>
    <div class="wrap-bottom">
        <div class="wrap-bottom-content">
            <div class="wrap-bottom-img"></div>
            <div class="wrap-bottom-text">
                <!-- <span>联络我们</span> -->
                <div class="content-bottom1">
                    <span class="bottom1-text" @click="toHlep('help',0)">{{$t("homePageIndex.text26")}}</span>
                    <span>/</span>
                    <span class="bottom1-text" @click="toHlep('help',1)">{{$t("homePageIndex.text27")}}</span>
                    <span>/</span>
                    <span class="bottom1-text" @click="toHlep('help',2)">{{$t("homePageIndex.text28")}}</span>
                    <span>/</span>
                    <span class="bottom1-text" @click="toHlep('help',3)">{{$t("homePageIndex.text29")}}</span>
                    <span>/</span>
                    <span class="bottom1-text" @click="toHlep('help',4)">{{$t("homePageIndex.text30")}}</span>
                    <span>/</span>
                    <span class="bottom1-text" @click="toHlep('help',5)">{{$t("homePageIndex.text31")}}</span>
                </div>
                <p>Copyright © KZ Reserved @v2.0.1 - 009</p>
            </div>
        </div>
    </div>
</template>

<script>
import { _toCustomerService } from '@/core/comp/common'
export default {
    data () {
        return {
        }
    },
    mounted() {
    },
    methods: {
        toHlep(pathName,id){
            this.$router.push({
                name: pathName,
                query: {
                    id: id
                }
            })
        },
        _toCustomerService,
    }
}
</script>
<style scoped lang="scss">
$imgUrl: '~@/assets/images/index/';
.wrap-bottom {
    width: 100%;
    color:#C1C1C1;
    background-color: #141414;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:12px;
    .wrap-bottom-content {
        width: 1200px;
        .wrap-bottom-img{
            width:100%;
            height: 40px;
            margin: 50px auto 30px;
            background: url(#{$imgUrl}wrap-bottom-bg.png)no-repeat center/contain;
        }
        .wrap-bottom-text{
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 30px;
        }
        .content-bottom1 {
            display: flex;
            justify-content: space-between;
            width:505px;
            margin: 20px auto;
            margin-bottom: 15px;
            .bottom1-text {
                font-size: 12px;
                cursor: pointer;
            }
        }
    }
}
</style>
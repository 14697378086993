// 财务相关接口
import store from '../../store'
import {
    _post,
    _get
} from '../request'

// 获取汇率
export const _getDigitRate = params => _post('/pay/api/v1/deposit/getDigitRate', params, false)

// 获取手续费
export const _getCurrentAudit = params => _post('/pay/api/v1/audit/getCurrentAudit', params, true)

// 验证是否是首次提款
export const _firstWithdraw = () => _get('/pay/api/v1/withdrawRecords/firstWithdraw', '', true)

// 验证是否是首次充值
export const _firstRechargeRecords = () => _get('/pay/api/v1/accountMoneyChange/findFirstRechargeRecords', '', true)


// //获取注册设置
export const _getRegisterSet = () => _get('/longm/api/v1/settings/get/', '', true)


// 验证手机号
export const _submitPhone = params => _post('/member/api/v1/members/bindPhone', params, true)

// 验证手机号
export const _updatePhone = params => _post('/member/api/v1/members/updatePhoneVaildBySmsCode', params, true)

// 提现
export const _withdraw = params => _post('/pay/api/v1/withdraw/entranceWithdraw', params, true)

//一键归集
export const _oneKeyBalance = params => _post('/game/api/v1/game/oneKeyBalanceCollection', params, true)
//获取游戏厂商列表
export const _getGameMoneyList = params => _post('/game/api/v1/game/getAllBalances', params, true)

//获取银行卡列表
export const _bankList = () => _get('/pay/api/v1/memberBanks/list', '', true)
// 获取充值配置
export const _depositSetting = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/settings/get/' + 'withdraw_open', '', false).then(res => {
            if (res && res.data && res.data.svalue && res.data.svalue.recharge_sms) {
                store.dispatch('depositSmsCheckAction', true)
                resolve(res)
                return
            }
            resolve(false)
        })
    })
}

// 获取存款方式
export const _getMoneyMode = params => _post('/pay/api/v1/deposit/listPayWays', params, true)

// 存款
export const _getPaymentNextStep = params => _post('/pay/api/v1/deposit/entranceDeposit', params, true)


//获取付款方式
export const _getlistPayWays = params => _post('/pay/api/v1/deposit/listPayWays', params, true)
// 充值入口
export const _getPCNotSendLatestSuccessRecord = params => _post('/pay/api/v1/onlinePayRecords/onlinePay/getPCNotSendLatestSuccessRecord', params, true)
// 充值入口
export const _updateNotSendLatestSuccessRecord = params => _post('/pay/api/v1/onlinePayRecords/onlinePay/updateNotSendLatestSuccessRecord', params, true)

export const _getByOrderNo = params => _post('/pay/api/v1/onlinePayRecords/getByOrderNo', params, true)
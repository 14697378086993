// 财务相关逻辑
import { _getDigitRate, _getCurrentAudit, _firstWithdraw, _firstRechargeRecords, _submitPhone, _updatePhone, _withdraw, _getMoneyMode } from '../../api/modules/finance'
import { Message } from 'element-ui'
import store from '../../store/index'
import i18n from '@/il8n/index'

// 获取汇率
export const _getDigitRateAction = form => {
    return new Promise(resolve => {
        _getDigitRate({
            digitCcy: form.name,
            digitLink: form.branch,
            type: form.type,
        }).then(res => {
            if (res && res.code == 0) {
                const re = /([0-9]+\.[0-9]{6})[0-9]*/
                const exchange = ((1 / res.data.digitBuyRate) + '').replace(re, '$1')
                resolve({
                    digitBuyRate: res.data.digitBuyRate,
                    currencyName: res.data.digitCcy,
                    exchange: exchange,
                })
            } else {
                // 获取汇率失败
                Message.error(res.msg || i18n.t("errorMessage.text27"))
                resolve(false)
            }
        })
    })
}

// 获取手续费
export const _getCurrentAuditAction = () => {
    return new Promise(resolve => {
        const user = store.getters.userData
        _getCurrentAudit({ memberId: user.user_id }).then(res => {
            if (res && res.code == 0) {
                resolve({
                    withdrawType: res.data.withdrawType, //取款限额方式
                    withdrawFixed: res.data.withdrawFixed ?  res.data.withdrawFixed.split(',') : res.data.withdrawFixed, // 取款整额数组
                    withdrawPayRate: res.data.withdrawPayRate, // 百分比
                    handlingfee: res.data.handlingfee, // 手续费
                    administrativeCosts: res.data.administrativeCosts, //行政费用
                    discountDeduction: res.data.discountDeduction, // 优惠扣除
                    singleMin: res.data.singleMin,
                    singleMax: res.data.singleMax,
                })
            } else {
                // 获取手续费失败
                Message.error(res.msg || i18n.t("errorMessage.text60"))
                resolve(false)
            }
        })
    })
}

// 验证是否是首次提款
export const _firstWithdrawAction = () => {
    return new Promise(resolve => {
        _firstWithdraw().then(res => {
            if (res && res.code == 0 && res.data) {
                resolve(res.data)
            } else {
                resolve(false)
            }
        })
    })
}

// 验证是否是首次提款
export const _firstRechargeRecordsAction = () => {
    return new Promise(resolve => {
        _firstRechargeRecords().then(res => {
            if (res && res.code == 0 && res.data) {
                resolve(res.data)
            } else {
                resolve(false)
            }
        })
    })
}   

// 提款
// form-表单  bank-当前提款方式  origin-手续费相关源数据  handlingfee-手续费  actualMoney-实际到账金额
export const _withdrawAction = ({ form, bank, origin, handlingfee, actualMoney }) => {
    console.log('----', form, bank, origin, handlingfee, actualMoney)
    // 表单验证
    if (!form.bankCard) return Message.error(i18n.t("errorMessage.text61"))//请选择取款方式
    if (!form.pass) return Message.error(i18n.t("errorMessage.text62"))//请输入交易密码
    if (form.money < origin.singleMin) return Message.error(i18n.t("errorMessage.text63") + origin.singleMin)//您的取款额度高于单次取款限额
    if (form.money > origin.singleMax) return Message.error(i18n.t("errorMessage.text64") + origin.singleMax)//您的取款额度高于单次取款限额
    if (actualMoney <= 0) return Message.error(i18n.t("errorMessage.text65"))//您的实际获得额度过低，无法提现
    const user = store.getters.userData
    return new Promise(resolve => {
        _withdraw({
            amount: form.money,
            password: form.pass,
    
            bankNo: bank.number,
            bankAccount: bank.account,
            bankBranch: bank.branch,
            bankName: bank.name,
            type: bank.type,
            bankId: bank.id,
    
            handlingfee: handlingfee,
            administrativeCosts: origin.administrativeCosts,
            discountDeduction: origin.discountDeduction,
            digitRate: origin.digitRate,
    
            operator: user.username,
    
        }).then(res => {
            if (res && res.code == 0) {
                resolve(res)
            } else {
                // 提款失败
                Message.error(res.msg || i18n.t("errorMessage.text66"))
                resolve(false)
            }
        })
    })
}

// 提交验证手机号
export const _submitPhoneAction = params => {
    return new Promise(resolve => {
        _submitPhone(params).then(res => {
            if (res && res.code == 0) {
                // 提交成功
                Message.success(i18n.t("errorMessage.text67"))
                store.dispatch('userInfoAction')
                resolve(res)
            } else {
                // 验证失败
                Message.error(res.msg || i18n.t("errorMessage.text24"))
                resolve(false)
            }
        })
    })
}

// 彩金绑定手机号
export const _updatePhoneSmsCodeAction = params => {
    return new Promise(resolve => {
        _updatePhone(params).then(res => {
            if (res && res.code == 0) {
                // 提交成功
                Message.success(i18n.t("errorMessage.text67"))
                store.dispatch('userInfoAction')
                resolve(res)
            } else {
                // 验证失败
                Message.error(res.msg || i18n.t("errorMessage.text24"))
                resolve(false)
            }
        })
    })
}

// 获取存款方式
export const _getMoneyModeAction = () => {
    return new Promise(resolve => {
        _getMoneyMode({
            terminal: 1,
            version: 1,
        }).then(res => {
            if (res && res.code == 0) {
                const listPayWays = res.data
                store.dispatch('depositDigitAction', listPayWays.digit || [])
                store.dispatch('depositOfflineAction', listPayWays.offline || [])
                store.dispatch('depositOnlineAction', listPayWays.online || [])
                store.dispatch('depositVipsAction', listPayWays.vips || [])
                resolve(res.data)
            } else {
                resolve(false)
                // 存款方式获取失败
                Message.error(res.msg || i18n.t("errorMessage.text66"))
            }
        })
    })
}
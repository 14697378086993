// 全局逻辑
import config from '../common/config'
import store from '../store/index'
import { _maintain } from '../api/modules/system'
import Router from '../../router/index'


// 系统启动
export const _systemInit = () => {

    // 获取url.js中的配置
    config.host = localStorage.getItem('host')
    config.imgUrl = localStorage.getItem('imgUrl')
    config.clientCode = window.clientCode
    config.childCode = window.childCode
    config.theme = window.theme
    config.interval = window.interval
    config.locale = window.locale
    config.projectImgUrl = window.projectImgUrl
    config.unit = window.unit;
    config.symbol = window.symbol;
    if(['a025','a026'].includes(window.theme)){
        config.ascriptionType  = window.ascriptionType 
    }
    // 如果未获取到服务端配置则跳转启动页
    if (!store.getters.systemReady && Router.currentRoute.name != 'startUp') {
        Router.push({ name: 'startUp' })
    }

    setTimeout(() => {
        // 获取最新用户数据
        store.dispatch('userInfoAction')

        // 获取维护信息
        _maintain()
    }, 0)
    // setTimeout(() => {
    //     // 获取快捷入口
    //     _listQuickEntry()
    // }, 2000)
}


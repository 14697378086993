// 记录相关状态、数据

// 邀请记录
const RebateList = {
    state: {
        rebateDataList: [],
        rebateDataTotal: 0,
        totalBetValid: '0.00', // 总投注
        totalAllowance: '0.00', // 累计获得总返利
    },
    mutations: {
        REBATE_LIST (state, { list = [], total = 0, totalBetValid = '0.00', totalAllowance = '0.00' }) {
            state.rebateDataList = list
            state.rebateDataTotal = total
            state.totalBetValid = totalBetValid
            state.totalAllowance = totalAllowance
        }
    },
    actions: {
        rebateListAction ({ commit }, data) {
            commit('REBATE_LIST', data)
        }
    },
    getters: {
        rebateDataList: state => JSON.parse(JSON.stringify(state.rebateDataList)),
        rebateDataTotal: state => state.rebateDataTotal,
        totalBetValid: state => state.totalBetValid,
        totalAllowance: state => state.totalAllowance,
    }
}

// 交易记录
const TransData = {
    state: {
        transDataList: [],
        transDataTotal: 0
    },
    mutations: {
        TRANS_DATA(state, { list = [], total = 0 }) {
            state.transDataList = list
            state.transDataTotal = total
        }
    },
    actions: {
        transDataAction({ commit }, data) {
            commit('TRANS_DATA', data)
        }
    },
    getters: {
        transDataList: state => JSON.parse(JSON.stringify(state.transDataList)),
        transDataTotal: state => state.transDataTotal
    }
}

// 游戏记录
const RecordGameList = {
    state: {
        recordGameList: [],
        recordGameTotal: 0,
        recordGameCount: {
            totalBet: '0.00', // 总投注
            effective: '0.00', // 总有效投注
            distributed: '0.00', // 总派金
            profit: '0.00', // 总盈亏金额
        },
    },
    mutations: {
        RECORD_GAME_LIST(state, { list = [], total = 0 }) {
            state.recordGameList = list
            state.recordGameTotal = total
        },
        RECORD_GAME_COUNT(state, data) {
            state.recordGameCount = data
        }
    },
    actions: {
        recordGameListAction({ commit }, data) {
            commit('RECORD_GAME_LIST', data)
        },
        recordGameCountAction({ commit }, data) {
            commit('RECORD_GAME_COUNT', data)
        },
    },
    getters: {
        recordGameList: state => JSON.parse(JSON.stringify(state.recordGameList)),
        recordGameTotal: state => state.recordGameTotal,
        recordGameCount: state => state.recordGameCount,
    }
}

// 活动记录
const RecordActiveList = {
    state: {
        recordActiveList: [],
        recordActiveTotal: 0,
    },
    mutations: {
        RECORD_ACTIVE_LIST(state, { list = [], total = 0 }) {
            state.RecordGameList = list
            state.RecordGameTotal = total
        }
    },
    actions: {
        recordActiveListAction({ commit }, data) {
            commit('RECORD_ACTIVE_LIST', data)
        },
    },
    getters: {
        recordActiveList: state => JSON.parse(JSON.stringify(state.recordActiveList)),
        recordActiveTotal: state => state.recordActiveTotal,
    }
}


// 所有游戏平台
const RecordAllPlatform = {
    state: {
        recordAllPlatform: []
    },
    mutations: {
        RECORD_ALL_PLATFORM(state, data) {
            state.recordAllPlatform = data
        }
    },
    actions: {
        recordAllPlatformAction({ commit }, data) {
            commit('RECORD_ALL_PLATFORM', data)
            commit('SAVE')
        }
    },
    getters: {
        recordAllPlatform: state => state.recordAllPlatform
    }
}


export default {
    state: {
        ...TransData.state,
        ...RecordAllPlatform.state,
        ...RecordGameList.state,
        ...RecordActiveList.state,
        ...RebateList.state,
    },
    mutations: {
        ...TransData.mutations,
        ...RecordAllPlatform.mutations,
        ...RecordGameList.mutations,
        ...RecordActiveList.mutations,
        ...RebateList.mutations,
    },
    actions: {
        ...TransData.actions,
        ...RecordAllPlatform.actions,
        ...RecordGameList.actions,
        ...RecordActiveList.actions,
        ...RebateList.actions,
    },
    getters: {
        ...TransData.getters,
        ...RecordAllPlatform.getters,
        ...RecordGameList.getters,
        ...RecordActiveList.getters,
        ...RebateList.getters,
    }
}
// 启动页逻辑
import { _getSystemConfig } from '../api/modules/system'
import store from '../store/index'

// 获取服务端配置
export const _forDataJson = () => {
    store.dispatch('systemReadyAction', false)
    _getSystemConfig().then(res => {
        if (!res || !res.data) return
        console.log('-----系统配置', res)
        const keyMap =  {
            1: 'systemCodeUrl', // web服务器地址
            2: 'systemImgHost', // 图片服务器地址
            3: 'systemDowUrl', // 下载服务器地址
            4: 'systemJackpotUrl', // 彩金服务器地址
            5: 'systemCustomerServiceUrl', // 客服服务器地址
            6: 'systemIosDownloadUrl', // ios下载地址
            7: 'systemAndroidDownloadUrl', // 安卓下载地址
            8: 'systemPcDownloadUrl', // pc下载地址
            9: 'systemPcMainUrl', // 主站域名
        }
        const serverConfig = {}
        res.data.forEach(e => {
            serverConfig[keyMap[e.type]] = e.domain
        })
        store.dispatch('systemReadyAction', serverConfig)
    })
}
<!-- 提款 -->
<template>
    <div class="views-Withdraw">
        <div class="views-title">{{$t("userPage.text99")}}</div>
        <!-- <Tab @tab="tab" :tabIndex="1" :arr="['会员存款', '会员提款']" /> -->
        <!-- 主账户 -->
        <div class="basic-balance">
            <span>{{$t("userPage.text100")}}</span>
            <div class="balance">{{ balance }} {{config.unit}}</div>
            <i class="refresh-btn refresh-btn-lg" :class="{'rotate': rotate}" @click="getPlatformBalance(true)"></i>
            <div class="balance-btn cursorPointer" @click="oneKeyBalance">{{$t("userPage.text102")}}</div>
        </div>
        <!-- 游戏平台 -->
        <div class="basic-platform">
            <div class="basic-platform-item" 
                v-for="(item, i) in platformBalances" 
                :key="i" 
                v-show="i <= total"
                :class="{ 'moneyBg': !item.operation }">
                <span>{{ item.vendorName }}</span>
                <div class="balance" v-if="item.operation">{{config.symbol}}{{ item.totalMoney }}</div>
                <div v-if="!item.operation">{{$t("userPage.text103")}}</div>
            </div>
            <div class="basic-platform-more" v-if="platformBalances.length > 18" @click="onMore">
                <img loading="lazy"
                    v-lazy="require('@/assets/images/my/daw.png')"
                    class="moreImg"
                    :class=" condition ? 'moreImgup' : 'moreImgdaw'"
                />
            </div>
        </div>
        <div class="basic-platform-drawing" v-if="drawing">
            <Descriptions :column="1">
                <!-- 收款方式 -->
                <DescriptionsItem :label='$t("userPage.text104")'>
                    <Select v-model="form.bankCard" class="basic-platform-select" @change="changeBank">
                        <template slot="prefix" v-if="currBank.imgUrl">
                            <img loading="lazy" class="prefixSlot" v-lazy="systemImgHost + currBank.imgUrl" />
                        </template>
                        <Option v-for="(item, i) in userBankList" :key="i" :label="item | banktitle" :value="item.id">
                            <img loading="lazy" v-lazy="systemImgHost + item.imgUrl" />
                            <span>{{ item | banktitle }}</span>
                        </Option>
                        <Option @click.native="goAdd" :value="-1">
                            <div class="money-select-option-add-bank">
                                <div>
                                    <i class="el-icon-circle-plus-outline add-bank-img themeTextColor"></i>
                                </div>
                                <div class="add-text">
                                    <span>{{$t("userPage.text105")}}</span>
                                </div>
                                <div>
                                    <i class="el-icon-arrow-right icon-right-img"></i>
                                </div>
                            </div>
                        </Option>
                    </Select>
                </DescriptionsItem>
            </Descriptions>
            <div class="drawing-btn-item">
                <Button type="primary" class="drawing-btn" @click="onNext(true)">{{$t("userPage.text106")}}</Button>
            </div>
        </div>
        <div class="basic-platform-drawing" v-if="!drawing">
            <!-- 表单 -->
            <Descriptions :column="1">
                <!-- 收款方式 -->
                <DescriptionsItem :label='$t("userPage.text107")'>
                    <div class="drawing-bankname">
                        <div class="drawing-img"><img loading="lazy" class="prefixSlot" v-lazy="systemImgHost + currBank.imgUrl" /></div>
                        <div class="bankName">{{ currBank.name }}</div>
                        <div class="bankNumber">{{ currBank._hideMiddleNumber }}</div>
                    </div>
                </DescriptionsItem>
                <!-- 提款金额 -->
                <DescriptionsItem :label='$t("userPage.text108")' >
                    <Input v-model="form.money" class="basic-platform-balance" :placeholder="$t('userPage.text109') +  balance + config.unit " :controls="false" >
                        <template slot="suffix">
                            <div @click="form.money = balance">{{$t("userPage.text110")}}</div>
                        </template>
                    </Input>
                    <div v-if="showOrigin.withdrawType == 1" class="withdraw-item-box">
                        <div class="withdraw-item" 
                            :class="checkMoneyIndex === index && withdrawNum ? 'withdraw-item-act' : '' " 
                            v-for="(item,index) in showOrigin.withdrawFixed" :key="'withdraw' + index" 
                            @click="checkMoney(item,index)">
                            {{ item }}
                        </div>
                    </div>
                    <div class="basic-platform-tips">
                        <div v-if="currBank.type != 0">
                            <div>1 {{config.unit}}≈{{ exchange | subNum }}{{ currBank.name }}</div>
                            <div>{{$t("userPage.text111")}}：{{ actualMoney }} {{config.unit}}≈ {{ accMul(actualMoney, exchange) }}USDT
                                <i class="refresh-btn refresh-btn-lg" :class="{'rotate1': rotate1}" @click="getDigitRate"></i>
                            </div>
                        </div>
                        <div v-else>
                            {{$t("userPage.text112")}}：{{ actualMoney }} {{config.unit}}
                            <i class="refresh-btn refresh-btn-lg" :class="{'rotate1': rotate1}" @click="getCost"></i>
                        </div>
                        <div>{{$t("userPage.text113")}}：{{config.symbol}} {{ handlingfee }}</div>
                        <div>{{$t("userPage.text114")}}：{{config.symbol}} {{ showOrigin.administrativeCosts.toFixed(2) }}</div>
                        <div>{{$t("userPage.text115")}}：{{config.symbol}} {{ showOrigin.discountDeduction.toFixed(2) }}</div>
                    </div>
                </DescriptionsItem>
                <!-- 交易密码 -->
                <DescriptionsItem :label='$t("userPage.text116")'>
                    <Input type="password" class="fund-item-input" show-password :placeholder='$t("userPage.text117")' v-model="form.pass"></Input>
                </DescriptionsItem>
            </Descriptions>
            <div class="drawing-btn-item">
                <Button type="primary" class="drawing-btn" @click="onNext(false)">{{$t("userPage.text118")}}</Button>
                <Button type="primary" class="drawing-btn" @click="submit">{{$t("userPage.text119")}}</Button>
            </div>
        </div>

        <!-- 短信验证弹窗 -->
        <VerificationCode ref="veriCode" @pass="withdraw" />
    </div>
</template>

<script>
import { _getPlatformBalance, _oneKeyBalance } from '@/core/api/modules/user'
import { mapGetters } from 'vuex'
import  config  from '@/core/common/config'
import { _getDigitRateAction, _getCurrentAuditAction, _withdrawAction, _firstWithdrawAction } from '@/core/comp/pages/finance'
import { Message, Descriptions, DescriptionsItem, Button, Select, Option, Input, } from 'element-ui'
import VerificationCode from './VerificationCode.vue'
export default {
    components: {
        Descriptions,
        DescriptionsItem,
        Button,
        Select,
        Option,
        Input,
        VerificationCode,
    },
    filters:{
        // 银行卡展示
        banktitle(val) {
            if (val) {
                return val.name + "(" + val.number.substr(-4) + ")";
            } else {
                return;
            }
        },
        subNum(val) {
            var re = /([0-9]+\.[0-9]{6})[0-9]*/;
            let aNew = (val + "").replace(re, "$1");
            return aNew;
        },
        
    },
    computed: {
        ...mapGetters([
            'userData',
            'userPlatformBalance',
            'userBankList',
            'systemImgHost',
        ]),
        handlingfee () { // 手续费
            const number = /^[0-9]*$/
            if (number.test(this.form.money)) {
                return (this.showOrigin.handlingfee + this.form.money * Number(this.showOrigin.withdrawPayRate)).toFixed(2)
            } else {
                return '0.00'
            }
        },
        actualMoney () { // 实际到账金额
            const number = /^[0-9]*$/
            if (number.test(this.form.money)) {
                const actualMoney = this.form.money - this.handlingfee - this.showOrigin.administrativeCosts - this.showOrigin.discountDeduction
                return actualMoney > 0 ? actualMoney.toFixed(2) : '0.00'
            } else {
                return '0.00'
            }
        },
        balance () { // 主账户数据
            return this.userData._totalBalance || '0.00'
        },
        platformBalances () { // 游戏平台数据
            // return this.userPlatformBalance.filter(item => item.type != 1)
            return this.userPlatformBalance
        },
        currBank () { // 当前提款渠道
            return this.userBankList.find(e => e.id === this.form.bankCard) || {}
        },
    },
    data () {
        return {
            config,
            form: {
                bankCard: '', // 收款方式
                money: '', // 提款金额
                pass: '', // 交易密码
            },
            showOrigin: { // 手续费相关源数据
                withdrawPayRate: 0, // 百分比
                handlingfee: 0, // 手续费
                administrativeCosts: 0, //行政费用
                discountDeduction: 0, // 优惠扣除
                withdrawType:0, //取款方式 0:区间任意金额，1:固定金额
                withdrawFixed:null, //固定金额
                singleMax:0,//固定最大金额
                singleMin:0,//固定最小金额
            },
            checkMoneyIndex: null,
            withdrawNum:false, 
            condition:false,
            total: 19,//游戏平台
            drawing:true,//取款下一步

            ActualMoney: 0, //实际到账金额
            exchange: "", //汇率
            digitBuyRate: "", //转换前的汇率
            currencyName: "", //货币名称
            rotate:false, //余额刷新
            rotate1:false, //
        }
    },
    created () {
        this.getCost()
        this.oneKeyBalance()//一键归集
        // this.platformBalances()
        // setTimeout(() => {
        //     this.$refs.veriCode.open()
        // }, 1000)
    },
    methods: {
        //取款下一步
        onNext(val){
            if(!this.form.bankCard && val){
                return Message.error(this.$t("userPage.text120"))
            }else if(!val){
                this.form = {
                    bankCard: '', // 收款方式
                    money: '', // 提款金额
                    pass: '', // 交易密码
                }
            }
            this.drawing = !this.drawing;
        },
        // 更多游戏平台
        onMore() {
            this.condition = !this.condition;
            this.total = this.total == 19 ? this.userPlatformBalance.length : 19;
        },
        checkMoney(num,index) {
            this.form.money = num
            this.checkMoneyIndex = index
            this.withdrawNum = true
        },

        // 获取各平台余额
        getPlatformBalance (isRefresh) {
            this.rotate = true
            _getPlatformBalance().then(res => {
                if (res){
                    if (isRefresh) Message.success(this.$t("userPage.text121"))
                }
            })
            this.rotate = false
        },
        // 一键归集
        oneKeyBalance () {
            _oneKeyBalance().then(res => {
                if (res) return this.getPlatformBalance()
            })
        },
        // 添加数字货币
        goAdd () {
            console.log('添加')
            this.$router.push({
                name: 'bankCard',
                // query: {
                //     tab: 0,
                //     t: Date.now()
                // }
            })
        },
        // 选择了支付方式
        changeBank () {
            setTimeout(() => {
                console.log('---当前支付方式', this.currBank)
                if (this.currBank.type == 2) { // USDT
                    console.log('todo:获取汇率')
                    this.getDigitRate() // 获取汇率
                }
            }, 0)
        },
        
        //计算数字精度
        accMul(arg1, arg2) {
            var m = 0,
                s1 = arg1.toString(),
                s2 = arg2.toString();
            try {
                m += s1.split(".")[1].length;
            } catch (e) {}
            try {
                m += s2.split(".")[1].length;
            } catch (e) {}
            return (
                (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
                Math.pow(10, m)
            );
        },
        //小數點截取6位
        subNum6(val) {
            var re = /([0-9]+\.[0-9]{6})[0-9]*/;
            let aNew = (val + "").replace(re, "$1");
            return aNew;
        },
        // 获取汇率
        getDigitRate () {
            _getDigitRateAction(this.currBank).then(res => {
                if (res) {
                    console.log('----汇率', res)
                    this.digitBuyRate = res.digitBuyRate;
                    this.exchange = this.subNum6(1 / res.digitBuyRate);
                    this.currencyName = res.digitCcy;
                }
            })
        },
        // 获取手续费
        getCost () {
            _getCurrentAuditAction().then(res => {
                if (res) {
                    this.showOrigin = res
                    console.log('---手续费', this.showOrigin)
                }
            })
        },
        // 提款
        submit () {
            if (!this.form.money) return Message.error(this.$t("userPage.text122"))
            if (!this.form.pass)  return Message.error(this.$t("userPage.text123"))
           _firstWithdrawAction().then(res => {
                if (!res) return this.withdraw()
                // 首次提款需要先验证手机
                this.$refs.veriCode.open()
           })
        },
        // 提款
        withdraw () {
            const result = _withdrawAction({
                form: this.form, //表单
                bank: this.currBank, // 当前提款方式
                origin: this.showOrigin, // 手续费相关源数据
                handlingfee: this.handlingfee, // 手续费
                actualMoney: this.actualMoney, // 实际到账金额
            })
            if (result && result.then) result.then(res => {
                if (res) { // 取款成功
                    this.onNext(false)//返回上一步
                    Message.success(this.$t("userPage.text124"))
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/my/';
.views-Withdraw{
    .basic-balance {
        margin: 20px 0;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        color: #6f6f6f;
        font-size: 20px;
        .balance{
            color: #fdf86a;
            margin: 0 10px;
            font-weight: 700;
        }
        .balance-btn{
            color: #fff;
            font-size: 18px;
            border: 1px solid #707070;
            font-weight: 700;
            padding:7px 15px;
            border-radius: 6px;
            box-shadow: 0 2px 1px 0px #ffffff;
            background:linear-gradient(to bottom,#3d3d3d,#252525,#101010,#292929,#414141);
        }
    }
    .basic-platform {
        display:grid;
        grid-template-columns: repeat(6,1fr);
        grid-gap: 20px 10px;
        margin:10px auto;
        .basic-platform-item {
            width: 120px;
            padding: 15px 0;
            text-align: center;
            border-radius: 5px;
            border: 1px solid #3e3e3e;
            word-wrap: break-word;
            background:#000;
            &.moneyBg{
                background: linear-gradient(163deg, #fffdc4, #e3bd29, #916801);
                color: #fff;
                .balance{
                    color: #fff;
                }
            }
            .balance{
                color: #fdf86a;
                font-size: 16px;
                font-weight: 700;
            }
        }
        .basic-platform-more{
            width: 120px;
            padding: 15px 0;
            border-radius: 5px;
            border: 1px solid #3e3e3e;
            background:#000;
            display:flex;
            align-items: center;
            text-align: center;
            img{
                margin: auto;
            }
            .moreImgdaw{
                animation: Running 0.5s linear infinite alternate running;
            }
            .moreImgup{
                transform:rotateX(180deg);
            }
        }
    }
    .basic-platform-drawing{
        .el-descriptions{
            margin:20px auto;
            .drawing-bankname{
                background: linear-gradient(163deg, #fffdc4, #e3bd29, #916801);
                box-shadow: 0 2px 0px 0 #f5e68e;
                border-radius: 6px;
                width: 400px;
                color: #fff;
                font-size: 20px;
                padding: 30px 20px;
                display:grid;
                justify-content: flex-start;
                grid-auto-flow: column dense;
                .drawing-img{
                    width: 100px;
                    height: 100px;
                    grid-column: 1 / 3;
                    grid-row: 1 / 3;
                    margin-right: 20px;
                    display:flex;
                    align-items: center;
                    img{
                        width: 100%;
                        height:auto;
                        border-radius: 10px;
                    }
                }
                .bankName{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                }
                .bankNumber{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                }
            }
        }
        .drawing-btn-item{
            margin-left: 100px;
            .drawing-btn{
                margin-right: 20px;
                border:none;
                width: 130px;
                height: 40px;
                font-size: 16px;
                box-shadow: 0 1px 0px 0 #f5e68e;
                background:url(#{$imgUrl}tab_bg_active.png)no-repeat center/100%;
            }
        }
    }
}
@keyframes Running{
    0%{
        transform:translateY(4px);
    }
    100%{
        transform:translateY(0px);
    }
}
</style>
<style lang="scss">
// 取款
.views-Withdraw{
    .el-descriptions{
        .el-descriptions__body{
            background:transparent;
            td{
                padding-bottom: 20px;
                border: none;
                background:transparent;
                .el-descriptions-item__label{
                    font-size: 16px;
                    width: 200px;
                    font-weight: 700;
                    display: flex;
                    justify-content:flex-end;
                    line-height: 40px;
                }
                .el-descriptions-item__content{
                    width: calc(100% - 100px);
                    .fund-item-input{
                        width: 400px;
                    }
                    input::-webkit-input-placeholder{
                        color: #888888;
                    }
                    .el-input-number{
                        width: 400px;
                    }
                    input{
                        width: 400px;
                        color: #fff;
                        background-color: rgb(0 0 0 / 20%);
                        border-radius: 4px;
                        padding-left: 15px;
                        text-align: left;
                        border: 1px solid #b18501;
                    }
                    .el-input__prefix{
                        display:flex;
                        align-items:center;
                        img{
                            width: 25px;
                            height: 25px;
                            margin: auto 0;
                        }
                    }
                    .basic-platform-select{
                        input{
                            padding-left: 35px;
                        }
                    }
                    .basic-platform-balance{
                        width:400px;
                        .el-input__suffix{
                            height: 100%;
                            margin-right: -4px;
                            cursor: pointer;
                            color: #f5e589;
                            .el-input__suffix-inner{
                                height:100%;
                                display:flex;
                                align-items:center;
                                padding: 0 10px;
                            }
                        }
                    }
                    .basic-platform-tips{
                        margin: 20px auto;
                        color: #efb73b;
                        line-height:30px;
                    }
                    
                    .withdraw-item-box {
                        display:flex;
                        justify-content:flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        .withdraw-item {
                            display: flex;
                            min-width: 100px;
                            padding: 7px 20px;
                            border:1px solid #b18501;
                            justify-content:center;
                            align-items: center;
                            text-align: center;
                            border-radius: 5px;
                            color: #b18501;
                            cursor: pointer;
                            margin-right:20px;
                            margin-bottom:10px;
                            font-size: 16px;
                            &:hover {
                                color: #fff;
                                background: linear-gradient(163deg, #fffdc4, #e3bd29, #916801);
                            }
                        }
                        .withdraw-item-act {
                            color: #fff;
                            background: linear-gradient(163deg, #fffdc4, #e3bd29, #916801);
                        }
                    }
                }
            }
        }
    }
}
</style>
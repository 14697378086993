<!-- ip限制页面 -->
<template>
    <div class="iplimit">
        <img class="iplimit-img" :src="require('@/assets/images/iplimit/iplimit01.png')" alt="">
        <div class="iplimit-box1">
            <img class="iplimit-img2" :src="require('@/assets/images/iplimit/iplimit02.png')" alt="">
            <span class="iplimit-text">{{$t("componentsViews.text18")}}</span>
        </div>
        <div class="iplimit-box2">
            <span>{{$t("componentsViews.text19")}}</span>
        </div>
        <div class="iplimit-box3">
            {{$t("componentsViews.text20")}}: {{ip}}
        </div>
        <img @click="goCustomer" class="iplimit-img3" :src="require('@/assets/images/iplimit/iplimit03.png')" alt="">
    </div>
</template>

<script>
export default {
    data () {
        return {
            ip: '0.0.0.0',
            customer: ''
        }
    },
    created () {
        if (this.$route.query) {
            this.ip = this.$route.query.ip
            this.customer = decodeURIComponent(this.$route.query.customer)
        }
    },
    methods: {
        goCustomer () {
            if (this.customer) {
                window.open(this.customer, '_blank')
            }
        }
    }
}
</script>


<style scoped lang="scss">
.iplimit {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
}
.iplimit-img {
    width: 560px;
}
.iplimit-box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -100px;
}
.iplimit-img2 {
    width: 60px;
    margin-right: 8px;
}
.iplimit-text {
    color: #ED8980;
    font-size: 44px;
    font-weight: 600;
}
.iplimit-box2 {
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 2px 2px 10px #eee;
    position: relative;
    top: -70px;
    color: #333;
}
.iplimit-box3 {
    font-size: 24px;
    color: #ED8980;
    position: relative;
    top: -20px;
    /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif */
}
.iplimit-img3 {
    cursor: pointer;
}
</style>
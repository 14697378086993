<!-- 专题活动详情 -->
<template>
    <div>
        <Dialog :visible.sync="dialogVisible">
            <div>
                <!-- 限时优惠 -->
                <div>{{$t('componentsViews.text66')}}</div>
                <div>
                    <div>{{ info.name || '--' }}</div>
                    <div>{{ info._time || '--' }}</div>
                    <div>
                        <Scrollbar>
                            <div v-html="info.intro"></div>
                        </Scrollbar>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { Dialog, Scrollbar } from 'element-ui'
import { _getThematicActivitiesByApp } from '../api/modules/promo'
import { _getTime } from '../utils/utils'

export default {
    components: {
        Dialog, Scrollbar
    },
    data () {
        return {
            dialogVisible: false,
            info: {}, // 详情数据
        }
    },
    methods: {
        // 打开详情
        open (id) {
            this.dialogVisible = true
            _getThematicActivitiesByApp(id).then(res => {
                if (res && res.code == 0) {
                    this.info = res.data
                    if (this.info.forever) {
                        this.info._time = this.$t('componentsViews.text67')//永久
                    } else {
                        this.info._time = `${_getTime(this.info.startTime)} - ${_getTime(this.info.endTime)}`
                    }
                    
                }
            })
        }
    }
}
</script>

<!-- 公告 -->
<template>
    <div class="home-notice">
        <div class="home-notice-list">
            <div class="notice-left">
                <i class="icon-notice"></i>
                <span>{{$t("homePageIndex.text68")}}：</span>
            </div>
            <Notice />
        </div>
    </div>
</template>

<script>
import Notice from '@/core/components/Notice.vue'
export default {
    components: {
        Notice,
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped >
$imgUrl:'~@/assets/images/index/';
.home-notice {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 12px;
    z-index: 11;
    color: rgba(204, 204, 204, 1);
    background-color: #000;
    cursor: pointer;
    // position: sticky;
    // top: 204px;
    .home-notice-list{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: row;
        .notice-left{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                width: 28px;
                height: 28px;
                margin-right: 5px;
                display:inline-block;
                background:url(#{$imgUrl}icon-notice.png) no-repeat center/contain;
            }
            span{
                color: #D6BD7B;
                word-break: keep-all;
            }
        }
    }
}
</style>
<style lang="scss">
.home-notice-component{
    width: calc(100% - 105px) !important;
    .title{
        display:none !important;
    }
    .home-notice{
        width: 100% !important;
    }
}
</style>
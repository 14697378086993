// 财务相关状态、数据


// 充值时是否需要短信验证过
const DepositSmsCheck = {
    state: {
        depositSmsCheck: false
    },
    mutations: {
        DEPOSIT_SMS_CHECK (state, data) {
            state.depositSmsCheck = data
        }
    },
    actions: {
        depositSmsCheckAction ({ commit }, data) {
            commit('DEPOSIT_SMS_CHECK', data)
        }
    },
    getters: {
        depositSmsCheck: state => state.depositSmsCheck
    }
}

// 充值渠道
const DepositChannels = {
    state: {
        depositDigit: [], // 数字货币
        depositOffline: [], // 线下充值
        depositOnline: [], // 线上充值
        depositVips: [], // vip通道
    },
    mutations: {
        DEPOSIT_DIGIT (state, data) { state.depositDigit = data },
        DEPOSIT_OFFLINE (state, data) { state.depositOffline = data },
        DEPOSIT_ONLINE (state, data) { state.depositOnline = data },
        DEPOSIT_VIPS (state, data) { state.depositVips = data },
    },
    actions: {
        depositDigitAction ({ commit }, data) { commit('DEPOSIT_DIGIT', data) },
        depositOfflineAction ({ commit }, data) { commit('DEPOSIT_OFFLINE', data) },
        depositOnlineAction ({ commit }, data) { commit('DEPOSIT_ONLINE', data) },
        depositVipsAction ({ commit }, data) { commit('DEPOSIT_VIPS', data) },
    },
    getters: {
        depositDigit: state => state.depositDigit,
        depositOffline: state => state.depositOffline,
        depositOnline: state => state.depositOnline,
        depositVips: state => state.depositVips,
    }
}

export default {
    state: {
        ...DepositSmsCheck.state,
        ...DepositChannels.state,
    },
    mutations: {
        ...DepositSmsCheck.mutations,
        ...DepositChannels.mutations,
    },
    actions: {
        ...DepositSmsCheck.actions,
        ...DepositChannels.actions,
    },
    getters: {
        ...DepositSmsCheck.getters,
        ...DepositChannels.getters,
    }
}
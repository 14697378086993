// 优惠活动相关逻辑
import store from '../../store/index'
import { Message } from 'element-ui'
import i18n from '@/il8n/index'
import { _joinAct, _activityInfo, _availableAmount, _validMemberCount, _referralLink } from '../../api/modules/promo'

// 获取活动状态文案
export const _promoStatus = activityData => {
    if (activityData.auditStatus == 0 && activityData.status == 0) return i18n.t("textMessage.text6")//进行中
    if (activityData.auditStatus == 0 && activityData.status == 10) return i18n.t("textMessage.text7")//未完成
    if (activityData.auditStatus == 1 && activityData.status == 0) return i18n.t("textMessage.text8")//等待审核
    if (activityData.auditStatus == 1 && (activityData.status == 5 || activityData.status == 6)) return i18n.t("textMessage.text9")//已完成
    if (activityData.auditStatus == 1 && activityData.status == 8) return i18n.t("textMessage.text10")//审核未通过
    return '--'
}

// 立即参与
export const _partIn = activityId => {
        return new Promise(resolve => {
            // 请先登录
            if (!store.getters.isLogin) return Message.error(i18n.t("errorMessage.text1"))
            const params = {
                memberId: store.getters.userData.user_id,
                activityId: activityId
            }
            _joinAct(params).then(res => {
                // 参与失败
                if (!res || res.code) return Message.error(res.msg || i18n.t("textMessage.text11"))
                // 参与成功
                Message.success(i18n.t("textMessage.text12"))
                _activityInfo().then(rs => {
                    resolve(rs) // 已经更新了活动内容，重新加载页面数据即可刷新
                })
            })
        })

    }
    //中奖排行榜
    // export const _getJackpotList = () =>  _post('/game/api/v1/game/getJackpotList')


// 获取会员可领取返利金额
export const _availableAmountAction = async() => {
    let vipResult = false
    const res = await _validMemberCount()
    if (res && res.code == 0) {
        vipResult = res.data
    } else {
        // 获取有效会员失败
        Message.error(res.msg || i18n.t("textMessage.text13"))
    }
    return new Promise(resolve => {
        if (!vipResult) return resolve(false)
        _availableAmount().then(res => {
            if (res && res.code == 0) {
                const rs = res.data
                const receiveFalse = !!(
                    Number(rs.allowance) >= Number(res.minCount) &&
                    Number(vipResult.validMemberCount) >= Number(rs.meetConditions) &&
                    Number(rs.maxReceive) >= Number(rs.allowance)
                )
                const datas = {
                    meetConditions: rs.meetConditions || 0,
                    maxReceive: Number(rs.maxReceive).toFixed(2),
                    minCount: Number(rs.minCount).toFixed(2),
                    amount: Number(rs.allowance).toFixed(2),
                    ultiple: rs.auditFactor,
                    receiveFalse: receiveFalse,
                    effectiveVnum: vipResult.validMemberCount,
                    Vnum: vipResult.allMemberCount
                }
                store.dispatch('rebateAmountAction', datas)
                resolve(res)
            } else {
                // 获取返利失败
                Message.error(res.msg || i18n.t("textMessage.text14"))
                resolve(false)
            }
        })
    })
}

// 获取推广码
export const _referralLinkAction = () => {
    const inviteCode = store.getters.inviteCode
    return new Promise(resolve => {
        if (inviteCode) return resolve({
            data: {
                code: inviteCode
            }
        })
        _referralLink().then(res => {
            if (res && res.code == 0) {
                const datas = {
                    code: res.data.code,
                    address: `${window.location.origin}?code=${res.data.code}`
                }
                store.dispatch('inviteCodeAction', datas)
                resolve(res)
            } else {
                resolve(false)
                // 推广码获取失败
                Message.error(res.msg || i18n.t("textMessage.text15"))
            }
        })
    })
}
// 游戏相关逻辑
import { _vendorGame, _getGameByIds, _searchGame, _recommendGameByID } from '../../api/modules/game'
import store from '../../store/index'
import { Message } from 'element-ui'
import i18n from '@/il8n/index'


// 电子游戏||棋牌游戏 页获取三级游戏列表（这里和 /game/api 里面的 _vendorGameAction 方法逻辑类似，两种写法皆可）
// type 类型   elec-电子游戏  chess-棋牌游戏
export const _thirdList = ({ item, pages }, type = 'elec') => {
    console.log(item)
    const reqMap = {
        1: _vendorGame,
        2: _getGameByIds,
        3: _getGameByIds,
    }
    const params = {
        currentPage: pages.currPage,
        pageSize: pages.pageSize,
        gameKindId: item.parentId,
        vendorId: item.ids,
        ids: item.ids,
    }
    return new Promise(resolve => {
        reqMap[item.type](params).then(res => {
            if (res && res.code == 0) {
                // 列表数据
                const val = {}
                val[item.id] = res.data.list.map(item => {
                    item._pictureUrl = store.getters.systemImgHost + item.pictureUrl
                    return item
                })
                switch (type) {
                    case 'elec':
                        store.dispatch('gameElecListsAction', val)
                        break
                    case 'chess':
                        store.dispatch('gameChessListsAction', val)
                        break
                }
                // 分页数据
                const page = {}
                page[item.id] = { currPage: pages.currPage, pageSize: pages.pageSize, total: res.data.total }
                switch (type) {
                    case 'elec':
                        store.dispatch('gameElecPagesAction', page)
                        break
                    case 'chess':
                        store.dispatch('gameChessPagesAction', page)
                        break
                }
                resolve(res)
            } else {
                // 获取列表失败
                Message.error(res.msg || i18n.t("errorMessage.text68"))
                resolve(false)
            }
        })
    })
}

// 电子游戏页 搜索游戏
export const _searchGameAction = searchText => {
    return new Promise(resolve => {
        _searchGame({
            currentPage: 1,
            pageSize: 999,
            name: searchText
        }).then(res => {
            if (res && res.code == 0) {
                const list = res.data.list.map(item => {
                    item._pictureUrl = store.getters.systemImgHost + item.pictureUrl
                    return item
                })
                resolve(list)
            } else {
                // 搜索失败
                Message.error(res.msg || i18n.t("errorMessage.text29"))
                resolve(false)
            }
        })
    })
}

// 电子游戏||棋牌游戏 页 获取热门游戏
/*
    gameKindId
    1  Electron  电子游艺
    2  Sports  体育赛事
    3  Chess  棋牌游戏
    4  Lottery  彩票游戏
    5  LiveCasino  真人视讯
    6  Esports  电子竞技
    7  Fishing  捕鱼
*/
export const _recommendGameByIDAction = (item, gameKindId = 1) => {
    return new Promise(resolve => {
        _recommendGameByID(gameKindId, item.ids).then(res => {
            if (res && res.code == 0) {
                // 列表数据
                const val = {}
                val[item.id] = res.data.map(item => {
                    item._pictureUrl = store.getters.systemImgHost + item.pictureUrl
                    return item
                })
                store.dispatch('gameElecRecommenAction', val)
                resolve(res.data)
            } else {
                // 获取热门游戏列表失败
                Message.error(res.msg || i18n.t("errorMessage.text29"))
                resolve(false)
            }
        })
    })
}
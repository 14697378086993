<!-- 关于我们 -->
<template>
    <div class="AboutTenbo">
        <div class="titleBOx">
               <h2 class="title">
               {{$t('helpamjs.text0')}}
            </h2>
        </div>
        <div class="content"> 
            <p class="tip2">{{$t('helpamjs.text1')}}</p>
            <p class="tip2">{{$t('helpamjs.text2')}}</p>
            <h3 class="tip1">{{$t('helpamjs.text3')}}</h3>
            <h3 class="tip1">{{$t('helpamjs.text4')}}</h3>
            <p class="tip2">{{$t('helpamjs.text5')}}</p>
            <p class="tip2">{{$t('helpamjs.text6')}}</p>
            <p class="tip2">{{$t('helpamjs.text7')}}</p>
            <p class="tip2">{{$t('helpamjs.text8')}}</p>
            <p class="tip2">{{$t('helpamjs.text9')}}</p>
            <p class="tip2">{{$t('helpamjs.text10')}}</p>
            <p class="tip2">{{$t('helpamjs.text11')}}</p>
            <h3 class="tip1">{{$t('helpamjs.text12')}}</h3>
            <p class="tip2">{{$t('helpamjs.text13')}}</p>
            <h3 class="tip1">{{$t('helpamjs.text14')}}</h3>
            <p class="tip2">{{$t('helpamjs.text15')}}</p>
            <p class="tip2">{{$t('helpamjs.text16')}}</p>
            <h3 class="tip1">{{$t('helpamjs.text17')}}</h3>
            <p class="tip2">{{$t('helpamjs.text18')}}</p>
            <h3 class="tip1">{{$t('helpamjs.text19')}}</h3>
            <p class="tip2">{{$t('helpamjs.text20')}}</p>
            <h3 class="tip1">{{$t('helpamjs.text21')}}</h3>
            <p class="tip2">{{$t('helpamjs.text22')}}</p>
            <p class="tip2">{{$t('helpamjs.text23')}}</p>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    computed: {
    },
    methods:{
    },
    mounted(){

    }
}
</script>
<style scoped lang='scss'>
    .AboutTenbo{
        .titleBOx{
            .title{
                font-weight:700;
                color:#fdf86a;
                font-size:16px;
            }
        }
        .content{  
            .tip1{
                color: #fff;
                padding: 0px 0px 20px;
                line-height: 30px;
                font-size: 18px;
                font-weight: 500;
            }
            .tip2{
                line-height: 32px;
                font-size: 14px;
                color: #ccc;
                padding-bottom: 20px;
                .tip3{
                    color: #fdf86a;
                }
            }
        }
    }
</style>
// 代理相关逻辑
import { _appSave } from '../../api/modules/agent'
import { Message } from 'element-ui'
// import store from '../../store/index'
// import { _getTime, _phoneIsValid, _weChatIsValid, _qqIsValid, _emailIsValid, _accountValid, _nameValid } from '../../utils/utils'
import config from '../../common/config'
import i18n from '@/il8n/index'

// 代理申请 表单提交
export const _appSaveAction = (data) => {
    return new Promise(resolve => {
        // 表单验证
        // let errMsg = ''
        // if (!_accountValid(name)) errMsg = '账号：请输入4-15位字母或数字'
        // if (password == '') errMsg = '请输入密码'
        // if (confirmPass != password) errMsg = '两次密码不一致'
        // if (!_nameValid(nickname)) errMsg = '姓名：姓名不能超过8位'
        // if (!_phoneIsValid(phone)) errMsg = '请输入正确的手机号'
        // if (!birthday) errMsg = '请选择生日'
        // if (qq && !_qqIsValid(qq)) errMsg = '请输入正确的QQ'
        // if (wechat && !_weChatIsValid(wechat)) errMsg = '请输入正确的微信'
        // if (email && !_emailIsValid(email)) errMsg = '请输入正确的邮箱地址'
        // if (errMsg) {
        //     resolve(false)
        //     return Message.error(errMsg)
        // }
        // 账号处理
        if (window.mergeAccount) data.name = data.name + window.mergeAccount
        const params = {
            ...data,
            clientItem: config.childCode,
            appId: config.theme,
        }
        _appSave(params).then(res => {
            if (res && res.code == 0) {
                resolve(res)
            } else {
                resolve(false)
                // 操作失败
                Message.error(res.msg || i18n.t("errorMessage.text9"))
            }
        })
    })
}
// 优惠活动


// 优惠活动分类
const PromoMenu = {
    state: {
        promoMenu: []
    },
    mutations: {
        PROMO_MENU (state, data) {
            state.promoMenu = data
        }
    },
    actions: {
        promoMenuAction ({ commit }, data) {
            commit('PROMO_MENU', data)
        }
    },
    getters: {
        promoMenu: state => state.promoMenu
    }
}

// 彩金列表
const jackpotList = {
    state: {
        jackpotList: [], // 彩金列表
        jackpotTotals: 0, // 彩金总条数
        jackpotStatus: false, // 是否有彩金未领取
    },
    mutations: {
        JACKPOT_LIST (state, data) {
            state.jackpotList = data
        },
        JACKPOT_TOTALS (state, data) {
            state.jackpotTotals = data
        },
        JACKPOT_STATUS (state, data) {
            state.jackpotStatus = data
        },
    },
    actions: {
        jackpotListAction ({ commit }, data) {
            commit('JACKPOT_LIST', data)
        },
        jackpotTotalsAction ({ commit }, data) {
            commit('JACKPOT_TOTALS', data)
        },
        jackpotStatusAction  ({ commit }, data) {
            commit('JACKPOT_STATUS', data)
        },
    },
    getters: {
        jackpotList: state => JSON.parse(JSON.stringify(state.jackpotList)),
        jackpotTotals: state => state.jackpotTotals,
        jackpotStatus: state => state.jackpotStatus,
    }
}


// 全民返利
const Rebate = {
    state: {
        rebateAmount: { // 可领取金额 相关数据
            meetConditions: '--/--', // 符合领取要求会员人数
            maxReceive: '--/--', // 领取上限金额
            minCount: '--/--', // 领取最低金额标准
            amount: '--/--', // 累计返利
            ultiple: '--/--', // 流水要求倍数
            receiveFalse: '--/--', // 是否满足领取要求

            effectiveVnum: '--/--', // 有效会员
            Vnum: '--/--', // 会员总数
        },
        rebateExplains: '', // 返利规则
    },
    mutations: {
        REBATE_AMOUNT (state, data) {
            state.rebateAmount = data
        },
        REBATE_EXPLAINS (state, data) {
            state.rebateExplains = data
        },
    },
    actions: {
        rebateAmountAction ({ commit }, data) {
            commit('REBATE_AMOUNT', data)
        },
        rebateExplainsAction ({ commit }, data) {
            commit('REBATE_EXPLAINS', data)
        }
    },
    getters: {
        rebateAmount: state => JSON.parse(JSON.stringify(state.rebateAmount)),
        rebateExplains: state => state.rebateExplains
    }
}

// 推广码
const InviteCode = {
    state: {
        inviteCode: '', //推广码
        inviteAddress: '', // 推广地址
    },
    mutations: {
        INVITE_CODE (state, { code, address }) {
            state.inviteCode = code
            state.inviteAddress = address
        }
    },
    actions: {
        inviteCodeAction ({ commit }, data) {
            commit('INVITE_CODE', data)
        }
    },
    getters: {
        inviteCode: state => state.inviteCode,
        inviteAddress: state => state.inviteAddress,
    }
}

export default {
    state: {
        ...PromoMenu.state,
        ...jackpotList.state,
        ...Rebate.state,
        ...InviteCode.state,
    },
    mutations: {
        ...PromoMenu.mutations,
        ...jackpotList.mutations,
        ...Rebate.mutations,
        ...InviteCode.mutations,
    },
    actions: {
        ...PromoMenu.actions,
        ...jackpotList.actions,
        ...Rebate.actions,
        ...InviteCode.actions,
    },
    getters: {
        ...PromoMenu.getters,
        ...jackpotList.getters,
        ...Rebate.getters,
        ...InviteCode.getters,
    }
}
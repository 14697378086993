// 登录注册逻辑
import { Message } from 'element-ui'
import i18n from '@/il8n/index'
import MyAes from '../../utils/myAes'
import {
    _validateLogin,
    _login,
    _validateRealName,
    _getMemberByName,
    _resetMemberPwdByClient,
} from '../../api/modules/user'
import Config from '../../common/config'
import store from '../../store/index'
import { _getDeviceId, _getDeviceInfo } from '../../utils/finger'
import { _loginOut } from '../common'
import { _getPlatformBalance } from '@/core/api/modules/user'
// 验证账号
const _validateLoginAccount = (form, codeDom) => {
    return new Promise(resolve => {
        _validateLogin(form).then(res => {
            if (res && res.code == 0) {
                if (res.data.validateStatus) {
                    resolve(false) // 通过验证
                } else {
                    const rs = res.data.validateWay
                    resolve(rs) // 未通过验证
                }
            } else {
                resolve(true)
                codeDom.reset() // 重置验证码
            }
        })
    })
}

// 登录逻辑
export const _loginMethod = async(form, codeDom, callback, errCallback) => {
    if (!form.account || !form.password) return Message.error(i18n.t("errorMessage.text31"))//请输入账号和密码
    const result = codeDom.getResult()
    if (!result) return Message.error(i18n.t("errorMessage.text32"))//请先通过验证
    if (codeDom.codeType == 0 && !result.captchaCode) return Message.error(i18n.t("errorMessage.text33"))//请输入验证码
        // 密码处理
    const aseKey = 'pigxpigxpigxpigx'
    const encodePassword = MyAes.encrypt(form.password, aseKey)
        // 账号处理
    let encodeAccount = form.account
    if (window.mergeAccount) {
        encodeAccount = form.account + window.mergeAccount
    }
    // 账号验证
    const veri = await _validateLoginAccount({
        name: encodeAccount,
        password: encodePassword
    }, codeDom)
    if (veri) {
        if (errCallback) errCallback(veri) // 登录验证失败
        return
    }
    // 登录请求
    _loginAction(form, codeDom, callback)
}

// 直接登录
export const _loginAction = async(form, codeDom, callback) => {
    const DeviceInfo = await _getDeviceInfo()

    // 密码处理
    const aseKey = 'pigxpigxpigxpigx'
    const encodePassword = MyAes.encrypt(form.password, aseKey)
        // 账号处理
    let encodeAccount = form.account
    if (window.mergeAccount) {
        encodeAccount = form.account + window.mergeAccount
    }
    let params = {
        name: encodeAccount,
        password: encodePassword,
        validateType: codeDom.codeType,
        lastLoginVersion: Config.loginVersion,
        
    }
    if(!['a025','a026'].includes(Config.theme)){
        params.fingerprint = await _getDeviceId()
        params.phoneModel = DeviceInfo.browserType
    }
    params = mergeCodeDom(params, codeDom)
    _login(params).then(res => {
        if (res && res.code == 0) {
            // if (res.data.firstLogin) { // 看字段是第一次登录，但不知道做了什么处理
            //     console.log('----第一次登录', res.data)
            // } else {
            console.log('----登录成功', res.data)
            store.dispatch('userAccessTokenAction', res.data.token_type + ' ' + res.data.access_token)
            store.dispatch('userDataAction', res.data)
            _getPlatformBalance().then(() => {

            })
            setTimeout(() => {
                store.dispatch('userInfoAction') // 获取个人信息
            }, 300)
            if (callback) callback()
                //}
        } else {
            _loginOut()
            Message.error(res.msg)
            codeDom.reset()
        }
    })
}

// 验证真实姓名
export const _validateRealNameAction = ({ name, realName }) => {
    // 账号处理
    if (window.mergeAccount) {
        name = name + window.mergeAccount
    }
    return new Promise(resolve => {
        _validateRealName({
            name,
            realName
        }).then(res => {
            if (res && res.code == 0) {
                if (res.data.validateStatus) { // 验证通过
                    resolve(true)
                } else {
                    Message.error(i18n.t("errorMessage.text34") + res.data.surplusCount + i18n.t("errorMessage.text35"))
                    resolve(false)
                }
            } else {
                // 验证失败
                Message.error(res.msg || i18n.t("errorMessage.text24"))
                resolve(false)
            }
        })
    })
}

// 找回密码-第一步-验证账号
export const _getMemberByNameAction = (name, codeDom) => {
    let params = { name }
    params = mergeCodeDom(params, codeDom)
    return new Promise(resolve => {
        _getMemberByName(params).then(res => {
            if (res && res.code == 0) {
                resolve(res)
            } else {
                // 校验失败
                Message.error(res.msg || i18n.t("errorMessage.text22"))
                resolve(false)
            }
        })
    })
}

// 找回密码-第三步-提交新密码
export const _resetMemberPwdByClientAction = ({ memberId, password, code, name }) => {
    return new Promise(resolve => {
        _resetMemberPwdByClient({
            memberId,
            password,
            code,
            name
        }).then(res => {
            if (res && res.code == 0) {
                // 操作成功
                Message.success(i18n.t("errorMessage.text10"))
                resolve(true)
            } else {
                // 操作失败
                Message.error(res.msg || i18n.t("errorMessage.text9"))
                resolve(false)
            }
        })
    })
}


// 合并验证码组件参数
function mergeCodeDom(form, codeDom) {
    const params = form
    const result = codeDom.getResult()
    params.validateType = codeDom.codeType
    switch (codeDom.codeType) {
        case 0:
            params.captchaKey = result.captchaKey
            params.captchaCode = result.captchaCode
            break
        case 1:
            params.geeTestQueryVO = {
                challenge: result.geetest_challenge,
                seccode: result.geetest_seccode,
                validate: result.geetest_validate
            }
            break
        case 2:
            params.shuMeiQueryVO = {
                rid: result.rid
            }
            break
    }
    return params
}
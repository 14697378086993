<!-- 交易记录 -->
<template>
    <div class="page-transrecord">
        <div class="views-title border">{{$t("userPage.text250")}}</div>
        <div class="remark">{{$t("userPage.text251")}}</div>
        <!-- 交易类型 -->
        <div class="form-box">
            <div class="form-title">{{$t("userPage.text253")+"："}}</div>
            <div :class="{ 'form-item-active': form.type == item.id }" class="form-item" v-for="item in transType" :key="'type' + item.id" @click="changeType(item.id)">{{ item.name }}</div>
        </div>

        <!-- 交易时间 -->
        <div class="form-box">
            <div class="form-title">{{$t("userPage.text253")+"："}}</div>
            <div :class="{ 'form-item-active': form.dateType === item.id }" class="form-item" v-for="item in transDate" :key="'time' + item.id" @click="selectDate(item.id)">{{ item.name }}</div>
        </div>

        <!-- 选择时间 -->
        <div class="form-box">
            <div class="form-title">{{$t("userPage.text254")+"："}}</div>
            <div class="date-box">
                <DatePicker :placeholder='$t("userPage.text5")' v-model="form.start" />
                <span class="split-line">--</span>
                <DatePicker :placeholder='$t("userPage.text5")' v-model="form.end" />
            </div>
            <div class="buttom-box">
                <div class="query-buttom"  @click="query">{{$t("userPage.text6")}}</div>
                <div class="reset-buttom" @click="reset">{{$t("userPage.text7")}}</div>
            </div>
        </div>
        <div class="content-box" v-if="transDataList.length > 0">
            <!-- 表格 -->
            <Table :data="transDataList">
                <!-- 订单号 -->
                <TableColumn prop="orderNo" align="center" :label='$t("userPage.text132")' width="180px">
                    <template slot-scope="scope">
                       <div style="display:flex;">
                            <span style="cursor: pointer;color:#fdf86a;width:50px;" @click="_copy(scope.row._betNo)">{{$t("userPage.text255")}}</span>
                            <!-- <Tooltip :content="scope.row._betNo" placement="top-start"> -->
                                <span class="betNo" >{{ scope.row._betNo }}</span>
                            <!-- </Tooltip> -->
                       </div>
                    </template>
                </TableColumn>
                <!-- 交易时间 -->
                <TableColumn prop="_createdAt" align="center" :label='$t("userPage.text253")'></TableColumn>
                <!-- 支付方式 -->
                <TableColumn prop="_payChannel" align="center" :label="payChannelMap[form.type].name"></TableColumn>
                <!-- 金额 -->
                <TableColumn prop="amount" align="center" :label='$t("userPage.text256")'></TableColumn>
                <!-- 状态 -->
                <TableColumn v-if="this.form.type != 4" prop="_status" align="center" :label='$t("userPage.text257")'></TableColumn>
                <TableColumn v-if="this.form.type == 4" align="center" :label='$t("userPage.text10")' >
                    <template slot-scope="scope">
                        <span style="cursor: pointer;color:#fdf86a;" @click="toDetail(scope.row._betNo)">{{$t("userPage.text258")}}</span>
                    </template>
                </TableColumn>
            </Table>
            <!-- 分页 -->
            <div class="pagination-box">
                <Pagination
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :current-page.sync="form.currPage"
                :page-size="form.pageSize"
                :total="transDataTotal"
                @size-change="getData"
                @current-change="getData"
                ></Pagination>
            </div>
            
        </div>
        <div v-else class="null-box">
            <img loading="lazy" v-lazy="require('@/assets/images/my/null.png')">
            <div>一条记录也没有呢...</div>
        </div>

    </div>
</template>

<script>
import { DatePicker, Pagination, Table, TableColumn, Tooltip,Message } from 'element-ui'
import { _getTransDatas } from '../../../core/comp/pages/records'
import { mapGetters } from 'vuex'
import { _copy,_getDifferDate } from '@/core/utils/utils'
export default {
    components: {
        DatePicker,
        Pagination,
        Table,
        TableColumn,
        Tooltip,
    },
    computed: {
        ...mapGetters([
            'transDataList', // 当前页数据
            'transDataTotal', // 总条数
        ])
    },
    data () {
        return {
            // 交易类型
            transType:[
                {id:1, name:this.$t("userPage.text259")},//充值记录
                {id:2, name:this.$t("userPage.text260")},//银行转账
                {id:3, name:this.$t("userPage.text261")},//提款记录
                {id:4, name:this.$t("userPage.text262")},//返水记录
                {id:5, name:this.$t("userPage.text263")},//其它
            ],

            // 交易时间
            transDate: [
                {id:0, name:this.$t("userPage.text264")},//今日
                {id:1, name:this.$t("userPage.text265")},//昨日
                {id:7, name:this.$t("userPage.text266")},//近7日
                {id:30,name:this.$t("userPage.text267")}//近30日
            ],
            // 交易方式map
            payChannelMap: [
                {id:1, name:this.$t("userPage.text268")},//支付方式
                {id:2, name:this.$t("userPage.text268")},//支付方式
                {id:3, name:this.$t("userPage.text269")},//提款方式
                {id:4, name:this.$t("userPage.text270")},//返水类型
                {id:5, name:this.$t("userPage.text271")},//类型
            ],
            form: {
                type: 1,
                dateType: '',
                start: '',
                end: '',
                currPage: 1,
                pageSize: 7,
            }
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        _copy,
        toDetail(betNo) {
            console.log(betNo)
            this.$router.push({
                path: '/rebateInfo',
                query: {
                    betNo: betNo,
                }
            })
        },
        // 切换表格类型
        changeType (key) {
            this.reset()
            setTimeout(() => {
                this.form.type = key
                this.getData()
            }, 0)
        },
        // 查询
        query () {
            if(this.form.start && this.form.end){
                let day = _getDifferDate(this.form.start,this.form.end);
                if(day > 30) return Message.warning(this.$t("userPage.text272")) 
            }
            this.form.currPage = 1
            this.getData()
        },
        // 获取数据
        getData () {
            setTimeout(() => {
                _getTransDatas(this.form)
            }, 0)
        },
        // 重置表单
        reset () {
            this.form = {
                type: 1,
                dateType: '',
                start: '',
                end: '',
                currPage: 1,
                pageSize: 7,
            }
        },
        // 选择日期
        selectDate (val) {
            this.form.dateType = val

            // 设置起止时间
            const now = new Date()
            const toDay = new Date(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 00:00:00`)
            console.log(toDay)
            const end = toDay.getTime() - 1000
            const start = end - (24 * 60 * 60 * 1000 * val) + 1000
            this.form.start = new Date(start)
            this.form.end = new Date(end)
            if (Number(val) === 0) { // 今日
                this.form.start = toDay
                this.form.end = new Date(toDay.getTime() - 1000 + 24 * 60 * 60 * 1000)
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "~@/assets/css/page-trans-record.scss";
</style>
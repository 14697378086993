// 游戏相关状态、数据

// 所有游戏、游戏菜单
const gameMenu = {
    state: {
        gameMenu: {
            arr_dz_all: [], // 包含了捕鱼分类的所有电子游戏
            arr_dz: [], // 电子游戏
            arr_ty: [], // 体育赛事
            arr_qp: [], // 棋牌游戏
            arr_cp: [], // 彩票游戏
            arr_zb: [], // 直播游戏
            arr_dj: [], // 电竞赛事
            arr_by: [], // 捕鱼游戏
        },
    },
    mutations: {
        GAME_MENU(state, data) {
            const gameMenu = JSON.parse(JSON.stringify(state.gameMenu))
            state.gameMenu = {
                arr_dz_all: data.arr_dz_all || gameMenu.arr_dz_all,
                arr_dz: data.arr_dz || gameMenu.arr_dz,
                arr_ty: data.arr_ty || gameMenu.arr_ty,
                arr_qp: data.arr_qp || gameMenu.arr_qp,
                arr_cp: data.arr_cp || gameMenu.arr_cp,
                arr_zb: data.arr_zb || gameMenu.arr_zb,
                arr_dj: data.arr_dj || gameMenu.arr_dj,
                arr_by: data.arr_by || gameMenu.arr_by,
            }
        },
    },
    actions: {
        gameMenuAction({ commit }, data) {
            commit('GAME_MENU', data)
            commit('SAVE')
        }
    },
    getters: {
        gameMenu: state => JSON.parse(JSON.stringify(state.gameMenu)),
    }
}

// 电子游戏页 三级游戏列表
const gameElecLists = {
    state: {
        // gameElecLists: {
        //     '12345': [ {}, {} ]
        // },
        gameElecLists: {}, // key 为二级游戏id  val 为三级游戏列表数组
        // gameElecPages: {
        //     '12345': [ { currPage: 1, pageSize: 10, total: 100 } ]  // currPage-当前页  pageSize-每页多少条  total-总条数
        // },
        gameElecPages: {}, // key 为二级游戏id  val 为当前分类的页面状态
        // gameElecRecommen: {
        //     '12345': [ {}, {} ]
        // },
        gameElecRecommen: {}, // key 为二级游戏id  val 为推荐游戏列表数组
    },
    mutations: {
        GAME_ELEC_LISTS (state, data) {
            const gameMenu = {
                ...JSON.parse(JSON.stringify(state.gameElecLists)),
                ...data,
            }
            state.gameElecLists = gameMenu
        },
        GAME_ELEC_PAGES (state, data) {
            const gameMenu = {
                ...JSON.parse(JSON.stringify(state.gameElecPages)),
                ...data,
            }
            state.gameElecPages = gameMenu
        },
        GAME_ELEC_RECOMMEN (state, data) {
            const gameMenu = {
                ...JSON.parse(JSON.stringify(state.gameElecRecommen)),
                ...data,
            }
            state.gameElecRecommen = gameMenu
        }
    },
    actions: {
        gameElecListsAction ({ commit }, data) {
            commit('GAME_ELEC_LISTS', data)
        },
        gameElecPagesAction ({ commit }, data) {
            commit('GAME_ELEC_PAGES', data)
        },
        gameElecRecommenAction ({ commit }, data) {
            commit('GAME_ELEC_RECOMMEN', data)
        }
    },
    getters: {
        gameElecLists: state => JSON.parse(JSON.stringify(state.gameElecLists)),
        gameElecRecommen: state => JSON.parse(JSON.stringify(state.gameElecRecommen)),
        gameElecPages: state => JSON.parse(JSON.stringify(state.gameElecPages)),
    }
}

// 棋牌游戏页 三级游戏列表
const gameChessLists = {
    state: {
        // gameChessLists: {
        //     '12345': [ {}, {} ]
        // },
        gameChessLists: {}, // key 为二级游戏id  val 为三级游戏列表数组
        // gameChessPages: {
        //     '12345': [ { currPage: 1, pageSize: 10, total: 100 } ]  // currPage-当前页  pageSize-每页多少条  total-总条数
        // },
        gameChessPages: {}, // key 为二级游戏id  val 为当前分类的页面状态
        // gameChessRecommen: {
        //     '12345': [ {}, {} ]
        // },
        gameChessRecommen: {}, // key 为二级游戏id  val 为推荐游戏列表数组
    },
    mutations: {
        GAME_CHESS_LISTS (state, data) {
            const gameMenu = {
                ...JSON.parse(JSON.stringify(state.gameChessLists)),
                ...data,
            }
            state.gameChessLists = gameMenu
        },
        GAME_CHESS_PAGES (state, data) {
            const gameMenu = {
                ...JSON.parse(JSON.stringify(state.gameChessPages)),
                ...data,
            }
            state.gameChessPages = gameMenu
        },
        GAME_CHESS_RECOMMEN (state, data) {
            const gameMenu = {
                ...JSON.parse(JSON.stringify(state.gameChessRecommen)),
                ...data,
            }
            state.gameChessRecommen = gameMenu
        }
    },
    actions: {
        gameChessListsAction ({ commit }, data) {
            commit('GAME_CHESS_LISTS', data)
        },
        gameChessPagesAction ({ commit }, data) {
            commit('GAME_CHESS_PAGES', data)
        },
        gameChessRecommenAction ({ commit }, data) {
            commit('GAME_CHESS_RECOMMEN', data)
        }
    },
    getters: {
        gameChessLists: state => JSON.parse(JSON.stringify(state.gameChessLists)),
        gameChessRecommen: state => JSON.parse(JSON.stringify(state.gameChessRecommen)),
        gameChessPages: state => JSON.parse(JSON.stringify(state.gameChessPages)),
    }
}

// 首页电子游戏组件 三级菜单游戏列表
const gameElecChildren = {
    state: {
        // gameElecChildren: {
        //     '12345': [ {}, {} ]
        // },
        gameElecChildren: {}, // key 为二级游戏id  val 为三级游戏列表数组
    },
    mutations: {
        GAME_ELEC_CHILDREN (state, data) {
            const gameMenu = {
                ...JSON.parse(JSON.stringify(state.gameElecChildren)),
                ...data,
            }
            state.gameElecChildren = gameMenu
        }
    },
    actions: {
        gameElecChildrenAction ({ commit }, data) {
            commit('GAME_ELEC_CHILDREN', data)
        }
    },
    getters: {
        gameElecChildren: state => JSON.parse(JSON.stringify(state.gameElecChildren)),
    }
}

// 首页推荐游戏
const gameRecommen = {
    state: {
        gameRecommen: []
    },
    mutations: {
        GAME_RECOMMEN (state, data) {
            state.gameRecommen = data
        }
    },
    actions: {
        gameRecommenAction ({ commit }, data) {
            commit('GAME_RECOMMEN', data)
        }
    },
    getters: {
        gameRecommen: state => state.gameRecommen
    }
}

// 首页超级彩金列表
const gameJackpotList = {
    state: {
        gameJackpotList: []
    },
    mutations: {
        GAME_JACKPOT_LIST (state, data) {
            state.gameJackpotList = data
        }
    },
    actions: {
        gameJackpotListAction ({ commit }, data) {
            commit('GAME_JACKPOT_LIST', data)
        }
    },
    getters: {
        gameJackpotList: state => state.gameJackpotList
    }
}

export default {
    state: {
        ...gameMenu.state,
        ...gameElecChildren.state,
        ...gameRecommen.state,
        ...gameElecLists.state,
        ...gameChessLists.state,
        ...gameJackpotList.state,
    },
    mutations: {
        ...gameMenu.mutations,
        ...gameElecChildren.mutations,
        ...gameRecommen.mutations,
        ...gameElecLists.mutations,
        ...gameChessLists.mutations,
        ...gameJackpotList.mutations,
    },
    actions: {
        ...gameMenu.actions,
        ...gameElecChildren.actions,
        ...gameRecommen.actions,
        ...gameElecLists.actions,
        ...gameChessLists.actions,
        ...gameJackpotList.actions,
    },
    getters: {
        ...gameMenu.getters,
        ...gameElecChildren.getters,
        ...gameRecommen.getters,
        ...gameElecLists.getters,
        ...gameChessLists.getters,
        ...gameJackpotList.getters,
    }
}
// 常用工具函数
import Router from '../../router/index'
import ClipboardJS from 'clipboard'
import { Message } from 'element-ui'
import QRCode from '@keeex/qrcodejs-kx'
import store from '../store/index'
import { _activeInfoAction, _getToken } from '../comp/common'
import { _getThematicActivitiesByApp } from '../api/modules/promo'
import i18n from '@/il8n/index'
// 跳转
export const _jump = (pathName, id) => {
    if (Router.currentRoute.name == pathName && Router.currentRoute.query.id == id) return
    if (id || id === 0) {
        Router.push({
            name: pathName,
            query: {
                id: id
            }
        })
    } else {
        Router.push({
            name: pathName,
        })
    }
}
// 语言
export const _getLocale = () => {
    var lang;
    if (!localStorage.getItem("locale")) {
        lang = window.locale || "zh_CN";
        i18n.locale = lang;
        localStorage.setItem('locale', lang);
    } else {
        lang = localStorage.getItem("locale")
    }
    return lang;
}

// 多语言图片
export const _getImgUrl = (name,type) => {
    let lang
    let urlType = type ? type : '.png';
    lang = window.locale || "zh_CN";
    return require("@/assets/images/lang/" + lang + "/" + name + urlType)
}
/* ---- 正则校验 ---- */
// 资金密码
export const _fundPassword = pass => /^[0-9]{6}$/.test(pass)
// 手机
export const _phoneIsValid = phone => /^1[3456789]\d{9}$/.test(phone)
// 微信
export const _weChatIsValid = wechat => /^[a-zA-Z0-9][a-zA-Z0-9_-]{5,19}$/.test(wechat)
// qq
export const _qqIsValid = qq => /^[1-9][0-9]{4,14}$/.test(qq)
// 邮箱
// eslint-disable-next-line
export const _emailIsValid = email => /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(email)
// 账户校验
export const _accountValid = account => /^[a-z0-9]{4,15}$/.test(account)
// 姓名校验
export const _nameValid = name => /^.{0,8}$/.test(name)
/* ---- 正则校验结束 ---- */

// 隐藏字符中间的内容
export const _hideMiddle = str => {
    if (!str) return ''
    if (str.length <= 12) {
        let num1 = Math.ceil(str.length / 3)
        let num2 = Math.floor(str.length / 3)
        return str.substr(0, num2) + '*'.repeat(num1) + str.substr(num1 + num2)
    }
    let num1 = 4
    let num2 = str.length - 4
    return str.substr(0, num1) + '*'.repeat(10) + str.substr(num2)
}

// 转换时间显示
export const _getTime = val => {
    if (!val) return ''
    const date = new Date(val)
    const Y = date.getFullYear() + '-'
    const M = (date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + '-'
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    return Y + M + D
}
//获取两个日期相差天数
export const getDifferScale = () => {
    var format;
    format = parseFloat(24 * 60 * 60 * 1000);
    return format;
}
//获取两个日期的相差日期数
export const _getDifferDate = (firstDate, secondDate) => {
    //1)将两个日期字符串转化为日期对象
    var startDate = new Date(firstDate);
    var endDate = new Date(secondDate);
    //2)计算两个日期相差的毫秒数
    var msecNum = endDate.getTime() - startDate.getTime();
    //3)计算两个日期相差的天数
    var dayNum = Math.floor(msecNum / getDifferScale());
    return dayNum;
}
// 小数位数截取
export const _setNumFixed = (num, i) => {
    if (num == '--/--') num = '0.00'
    const newNum = num * 1
    const tempVal = parseFloat(newNum).toFixed(i + 1)
    return tempVal.substring(0, tempVal.length - 1)
}

//格式化时间
export const _formatDate = (timeStamp, str = 'yyyy-MM-dd HH:mm:ss') => {
    try {
        timeStamp = timeStamp * 1
    } catch {
        console.log('time')
    }
    const t = new Date(timeStamp)
    const obj = {
        yyyy: t.getFullYear(),
        yy: ('' + t.getFullYear()).slice(-2),
        M: t.getMonth() + 1,
        MM: ('0' + (t.getMonth() + 1)).slice(-2),
        d: t.getDate(),
        dd: ('0' + t.getDate()).slice(-2),
        H: t.getHours(),
        HH: ('0' + t.getHours()).slice(-2),
        h: t.getHours() % 12,
        hh: ('0' + t.getHours() % 12).slice(-2),
        m: t.getMinutes(),
        mm: ('0' + t.getMinutes()).slice(-2),
        s: t.getSeconds(),
        ss: ('0' + t.getSeconds()).slice(-2),
        w: ['日', '一', '二', '三', '四', '五', '六'][t.getDay()]
    }
    const reg = /([a-z])+/ig
    return str.replace(reg, a => obj[a])
}
// 复制
export const _copy = (text, callback) => {
    const copyDom = document.createElement('button')
    copyDom.innerText = text
    copyDom.className = 'my_copy_dom'
    copyDom.setAttribute('data-clipboard-text', text)
    copyDom.style.cssText = `
        position: absolute;
        top: -100px;
        right: 100%;
    `
    document.body.appendChild(copyDom)
    setTimeout(() => {
        const clipboard = new ClipboardJS('.my_copy_dom')
        clipboard.on('success', () => {
            document.body.removeChild(copyDom)
            if (callback) return callback()
            Message.success(i18n.t("textMessage.text54"))

        })
        clipboard.on('error', () => {
            document.body.removeChild(copyDom)
            Message.error(i18n.t("textMessage.text55"))
        })
        copyDom.click()
    }, 100)
}

// 生成二维码
// id-dom元素id  content-二维码内容  width-二维码宽  height-二维码高
export const _createQRcode = ({ id, content, width = 100, height = 100 }) => {
    new QRCode(id, {
        'width': width, // 设置宽度，单位像素
        'height': height, // 设置高度，单位像素
        'text': content, // 设置二维码内容或跳转地址
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L
    })
}

// 下载
export const _download = (uri, name) => {
    const link = document.createElement('a')
    link.download = name || 'download'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

// 国内版获取app下载地址
export const _downloadApp = () => {
    let url = location.origin + '/downloadUrl?code=' + window.childCode
    let iosUrl = store.getters.systemIosDownloadUrl
    let androidUrl = store.getters.systemAndroidDownloadUrl
    if (iosUrl) url += `&ios=${iosUrl}`
    if (androidUrl) url += `&android=${androidUrl}`
    return url
}

// 国际版app下载地址
export const _downloadInternational = () => {
    let url = ''
    let inviteCode = JSON.parse(sessionStorage.getItem("inviteCode")) || '';
    url = `${store.getters.systemDowUrl}/${window.clientCode}${window.theme}${window.ascriptionType}`;
    if (inviteCode) url += `/${inviteCode}`
    return url
}


// 头部快捷导航 跳转逻辑
// 这里的跳转页面需要交由前台页面处理
// 返回根据返回值跳转页面
// recharge-充值中心  cooperation-合作伙伴  notice-公告详情
export const _headerJump = item => {
    if (item.editStatus === 0) {
        switch (item.fixedCode) {
            case '充值中心':
                if (!store.getters.isLogin) {
                    Message.error(i18n.t("errorMessage.text1"))
                    return false
                }
                return Router.push("/deposit");
            case '合作伙伴':
                return Router.push("/help?id=5");
            case 'APP下载':
                return Router.push("/mobileBet");
        }
    } else {
        switch (item.jumpType) {
            case 0: // 路径
                if (item.openType === 0) {
                    window.open(item.jumpContent, "_self");
                } else {
                    window.open(item.jumpContent, "_blank");
                }
                break
            case 1: // 公告(打开的是公告详情)
                return Router.push("/notice")
            case 2: // 活动(打开的是优惠活动详情)id
                _activeInfoAction(item)
                break
            case 3: // 专题活动
                _getThematicActivitiesByApp(item.jumpContent).then(res => {
                    if (!(res && res.code == 0 && res.data)) return false
                    // if (res.data.type == 2) { // 签到
                    //     if (!store.getters.isLogin) {
                    //         Message.error('请先登录')
                    //         return false
                    //     }
                    //     // todo 跳转签到页
                    // } else { // 专题活动
                    //     // todo 跳转专题活动详情页（不是活动详情页，另外单独写）
                    // }
                })
                break
            case 4: // 进入游戏
                if (!store.getters.isLogin) {
                    Message.error(i18n.t("errorMessage.text1"))
                    return false
                }
                item.id = item.jumpContent
                _getToken(item, 1)
                break
        }
    }
}

// recharge-充值中心  cooperation-合作伙伴  notice-公告详情
export const _routerBack = () => {
    return Router.back(-1)
}
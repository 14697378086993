<!-- 忘记密码-找回密码 -->
<template>
    <div class="Forget-from">
        <!-- 第一步 -->
        <Dialog :visible.sync="ForgetDialog"
            :title='$t("componentsViews.text2")'
            width="550px" destroy-on-close center :modal-append-to-body='false'
            :show-close="false" :close-on-click-modal="false" custom-class="Forget-dialog">
            
            <img @click="close" class="close-icon" src="../../assets/images/index/login_close.png" alt="">
            <div class="Forget-top">
                <div class="Forget-num">
                    <div class="num">{{ step }}</div>
                </div>
                <div class="Forget-step">{{ stepText }}</div>
            </div>
            <div class="Forget-content">
                <!-- 第一步 -->
                <template v-if="step == 1">
                    <!-- 账号 -->
                    <div class="Forget-input input-icon1">
                        <Input v-model.trim="form.account" :placeholder='$t("componentsViews.text3")' />
                    </div>
                    <!-- 验证码 -->
                    <div class="Forget-input">
                        <VerifyCode ref="code" />
                    </div>
                    <div class="Forget-btn" @click="checkAccount">{{$t("componentsViews.text4")}}</div>
                </template>
                
                <!-- 第二步 -->
                <template v-if="step == 2">
                    <!-- 账号 -->
                    <div class="Forget-input input-icon1">
                        <input v-model.trim="form.account" disabled />
                    </div>
                    <!-- 验证码 -->
                    <div class="Forget-input Forget-input1 input-icon3">
                        <Input v-model.trim="form.code" :placeholder='$t("componentsViews.text5")' />
                        <div class="input-btn" :disabled="form.time" @click="getCode">{{ form.time ? `${form.time}s` : $t("componentsViews.text6") }}</div>
                    </div>
                    <div class="Forget-btn" @click="checkSms">{{$t("componentsViews.text7")}}</div>
                </template>
                
                <!-- 第三步 -->
                <template v-if="step == 3">
                   <!-- 新密码 -->
                    <div class="Forget-input input-icon2">
                        <Input show-password :placeholder='$t("componentsViews.text8")' v-model="form.pass" />
                    </div>
                    <!-- 确认密码 -->
                    <div class="Forget-input input-icon2">
                        <Input show-password :placeholder='$t("componentsViews.text9")' v-model="form.confirmPass" />
                    </div>
                    <div class="Forget-btn" @click="submitPassword">{{$t("componentsViews.text4")}}</div>
                </template>
                <div class="Forget-tips">{{$t("componentsViews.text11")}}<span @click="_toCustomerService()">{{$t("componentsViews.text12")}}</span></div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import VerifyCode from './VerifyCode.vue'
import { Input, Message, Dialog } from 'element-ui'
import { _getMemberByNameAction, _resetMemberPwdByClientAction } from '@/core/comp/pages/login'
import { _mobilebyacc, _checkSmsCode } from '@/core/api/modules/user'
import { _toCustomerService } from '@/core/comp/common'

export default {
    props: ['ForgetDialog'],
    components: {
        VerifyCode,
        Input,
        Dialog
    },
    data () {
        return {
            step: 1,
            // 第一步:验证账号信息
            stepText:this.$t("componentsViews.text13"),
            form: {
                account: '', // 账号
                code: '', // 短信验证码
                time: 0, // 倒计时
                pass: '', // 新密码
                confirmPass: '', // 确认密码
                // 通过接口获取的id和账号
                memberId: '',
                myAccount: '',
            }
        }
    },
    methods: {
        _toCustomerService,
        close() {
            this.$emit('closeForget')
            for(let item in this.form) {
                this.form[item] = ""
            }
        },
        // 第一步-验证账号
        checkAccount () {
            _getMemberByNameAction(
                this.form.account,
                this.$refs.code
            ).then(res => {
                if (res) {
                    this.form.memberId = res.data.memberId
                    this.form.myAccount  = res.data.name
                    this.step = 2
                    // 第二步:短信验证
                    this.stepText = this.$t("componentsViews.text14")
                }else{
                    setTimeout(() => {
                        this.$refs.code.reset() // 重置行为校验
                    }, 1000)
                    return
                }
            })
        },
        // 获取短信验证码
        getCode () {
            if (!this.form.account || this.form.time) return
            console.log('---获取短信验证码')
            _mobilebyacc(this.form.account, 2).then(res => {
                if (res) {
                    this.form.time = 60
                    const interval = setInterval(() => {
                        this.form.time--
                        if (this.foem.time <= 0) {
                            clearInterval(interval)
                        }
                    }, 1000)
                }
            })
        },
        // 第二步-验证短信验证码
        checkSms () {
            if (!this.form.code) return
            _checkSmsCode({
                name: this.form.account,
                smsCode: this.form.code,
            }).then(res => {
                if (res) {
                    this.step = 3
                    // 第三步:修改新密码
                    this.stepText = this.$t("componentsViews.text15")
                }
            })
        },
        // 第三步-提交新密码
        submitPassword () {
            if (!this.form.pass) return
            // 两次输入的密码不一致
            if (this.form.pass !== this.form.confirmPass) return Message.error(this.$t("componentsViews.text16"))
            _resetMemberPwdByClientAction({
                memberId: this.form.memberId,
                password: this.form.pass,
                code: this.form.code,
                name: this.form.myAccount
            }).then(res => {
                if (res) {
                    this.step = 1
                    // 第一步:验证账号信息
                    this.stepText = this.$t("componentsViews.text13")
                    // setTimeout(() => {
                    //     this.$router.push({ name: 'home' })
                    // }, 500)
                    this.close()
                }
            })
        }
    }
}
</script>
<style lang="scss">
$imgUrl: '~@/assets/images/index/';
$imgUrlLogin: '~@/assets/images/common/login-black-gold/';
.Forget-from{
    .Forget-dialog{
        padding: 0 15px;
        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
        .Forget-top{
            margin: 20px 0 50px;
            .Forget-num{
                height:16px;
                position: relative;
                &:before{
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width:100%;
                    height:2px;
                    z-index: 1;
                    background:#D6BD7B;
                }
                .num{
                    position: absolute;
                    top: 50%;
                    left: 35px;
                    transform: translateY(-50%);
                    width:16px;
                    height:16px;
                    text-align: center;
                    color: #fff;
                    z-index: 1;
                    background-color: #D6BD7B;
                    border-radius: 50%;
                }
            }
            .Forget-step{
                margin-top: 5px;
                line-height: 22px;
                font-weight: 500;
                color: #a09d90;
            }
        }
        .Forget-content{
            .Forget-input {
                position: relative;
                margin-bottom: 15px;
                height: 40px;
                display:flex;
                input {
                    flex: 1;
                    height: 100%;
                    color: #000;
                    padding-left: 40px;
                    border: 1px solid #dcdfe6;
                    background: transparent;
                    border: 1px solid;
                    border-color: #dcdfe6;
                    border-radius: 3px;
                    box-sizing: border-box;
                    &:focus {
                        outline: none;
                        border-color: #dcad02;
                    }
                }
            }
            @for $i from 1 to 4{
                .input-icon#{$i}{
                    background:url(#{$imgUrlLogin}login_icon#{$i}.png)no-repeat 10px center/20px;
                }
            }
            .Forget-input1 {
                display: flex;
                height: 40px;
                justify-content: space-between;
                .el-input {
                    flex: 1;
                }
                .input-btn {
                    width: auto;
                    padding: 0 8px;
                    margin-left: 10px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    background-color: #b28601;
                    border-radius: 20px;
                    cursor: pointer;
                }
            }
            .Forget-tips{
                margin-top: 20px;
                text-align: center;
                span{
                    color: #D6BD7B;
                }
            }
            .Forget-btn {
                margin-top: 40px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                font-weight:700;
                color:#ffffff;
                font-size:16px;
                background: url(#{$imgUrl}/login_botton_bg.png) no-repeat center;
                background-size: 100% 100%;
                cursor: pointer;
            }
        }
    }

}
</style>
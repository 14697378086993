<template>
<div class="GameInner-bg">
   <div class="GameInner-game">
      <div class="GameInner-content">
         <div class="GameInner-banner">
            <div class="GameInner-bannerList" v-for="(item,index) in gameInnerList" :key="index"  @click="tolink(item)">
               <img loading="lazy" v-lazy="item.imgUrl" />
               <span class="gameText">{{ item.name }}</span>
            </div>
         </div>
      </div>
   </div>
</div>
</template>

<script>
export default {
   data () {
      return{
         gameInnerList:[
            // 真人视讯
            {
               imgUrl:require('@/assets/images/index/home/game-innner1.png'),
               path:'/live',
               name:this.$t("homePageIndex.text8"),
               query:{
                  pid:5,
                  id:51,
                  type:2,
               }
            },
            // 电子竞技
            {
               imgUrl:require('@/assets/images/index/home/game-innner2.png'),
               path:'/gaming',
               name:this.$t("homePageIndex.text9"),
               query:{
                  pid:6,
                  id:15928,
                  type:2,
               }
            },
            // 棋牌游戏
            {
               imgUrl:require('@/assets/images/index/home/game-innner3.png'),
               path:'/chess',
               name:this.$t("homePageIndex.text12"),
               query:{
                  pid:3,
                  id:20,
                  type:1,
               }
            },
            // 体育赛事
            {
               imgUrl:require('@/assets/images/index/home/game-innner4.png'),
               path:'/sports',
               name:this.$t("homePageIndex.text10"),
               query:{
                  pid:2,
                  id:34,
                  type:1,
               }
            },
         ]
      }
   },
    computed: {
    },
    methods: {
       tolink(val){
         this.$router.push({
            path:val.path,
            // query:val.query
         })
       },
    },
}
</script>

<style lang="scss" scoped>
$imgUrl: '~@/assets/images/index/';
.GameInner-bg {
   background-color:#141414;
   .GameInner-game{
      width: 1200px;
      margin: 0 auto;
      padding: 30px 0;
      .GameInner-content{
         font-size: 14px;
         color: #fff;
         width: 100%;
         padding:20px 0 ;
         .GameInner-banner{
            display: grid;
            grid-gap: 16px 13px;
            grid-template-columns: repeat(4,1fr);
            .GameInner-bannerList{
               flex: 1;
               position:relative;
               transition: transform 0.5s ease 0s;
               &:hover{
                  transform: translateY(-7%);
               }
               img{
                  width: 285px;
                  height: 268px;
               }
               span.gameText{
                  position: absolute;
                  bottom:42px;
                  left:18px;
                  color: #cba36e;
                  font-size: 18px;
                  font-weight: bold;
               }

            }
            .GameInner-bannerList:nth-child(even){
                span.gameText{
                   color: #fff;
                }
            }
         }
      }
   }
}

</style>

<!-- 基本信息 -->
<template>
    <div class="views-BasicInfo">
        <div class="views-title-small">{{$t("userPage.text276")}}</div>
        <Descriptions title="" :column="1">
            <DescriptionsItem :label="$t('userPage.text276')">
                <div class="BasicInfo-text">**************</div>
                <div class="BasicInfo-btn" @click="_jump('password')">{{$t("userPage.text280")}}</div>
            </DescriptionsItem>
            <DescriptionsItem :label='$t("userPage.text277")'>
                <div class="BasicInfo-text">{{ userData.withdrawPassWordState ? '**************' : ''}}</div>
                <div class="BasicInfo-btn" @click="_jump('fundPass')">{{ userData.withdrawPassWordState ? $t("userPage.text280") : $t("userPage.text281") }}</div>
            </DescriptionsItem>
            <DescriptionsItem :label='$t("userPage.text278")'>
                <div class="BasicInfo-text"></div>
                <div class="BasicInfo-btn" @click="_jump('devices')">{{$t("userPage.text279")}}</div>
            </DescriptionsItem>
        </Descriptions>


        <!-- 绑定信息 -->
        <!-- <div class="basic-bind">
            <div>
                <span>你好，{{ userData.name }}</span>
                <span>VIP: {{ userData.viplv }}</span>
            </div>
            <div>
                <Progress :percentage="percentage" :format="format"></Progress>
                <span>手机绑定 {{ userData.phone ? '√' : '×' }}</span>
                <span>邮箱绑定 {{ userData.email ? '√' : '×' }}</span>
                <span>资金密码 {{ userData.withdrawPassWordState ? '√' : '×' }}</span>
                <span>绑定银行卡 {{ userBankList.length ? '√' : '×' }}</span>
            </div>
        </div> -->

        <!-- 基本信息 -->
        <!-- <div class="basic-info">
            <Descriptions title="个人资料" :column="2">
                <DescriptionsItem label="昵称">{{ userData.nickname || '--' }}</DescriptionsItem>
                <DescriptionsItem label="性别">{{ userData.sex==1?'男':'女' }}</DescriptionsItem>
                <DescriptionsItem label="联系电话">{{ userData._hideMiddlePhone || '--' }}</DescriptionsItem>
                <DescriptionsItem label="邮箱地址">{{ userData.__hideMiddleEmail || '--' }}</DescriptionsItem>
                <DescriptionsItem label="联系QQ">{{ userData._hideMiddleQQ || '--' }}</DescriptionsItem>
            </Descriptions>
        </div> -->
        
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { _jump } from '@/core/utils/utils'
import {  Descriptions, DescriptionsItem } from 'element-ui'
export default {
    components: {
        Descriptions,
        DescriptionsItem,
    },
    data () {
        return {
            
        }
    },
    computed: {
        percentage () { // 绑定进度
            let num = 0
            this.userData.phone && (num += 25)
            this.userData.email && (num += 25)
            this.userData.withdrawPassWordState && (num += 25)
            this.userBankList.length && (num += 25)
            return num
        },
        balance () { // 主账户数据
            return this.userData._totalBalance || '0.00'
        },
        ...mapGetters([
            'userData',
            'userPlatformBalance',
            'userBankList',
        ])
    },
    created () {
    },
    methods: {
        _jump,
        // // 绑定进度文案
        // format (percentage) {
        //     if (percentage < 60) return '低级'
        //     if (percentage >= 80) return '高级'
        //     return '中级'
        // },
    }
}
</script>

<style lang="scss">
.views-BasicInfo{
    .el-descriptions{
        color: #6f6f6f;
        font-size: 16px;
        .el-descriptions__body{
            background:transparent;
            .el-descriptions-row{
                .el-descriptions-item__container,.el-descriptions-item__content{
                    display: flex;
                    justify-content:flex-start;
                    align-items: center;
                    height: 50px;
                    .BasicInfo-text{
                        width: 170px;
                        text-align: center;
                    }
                    .BasicInfo-btn{
                        width: 120px;
                        height: 35px;
                        line-height: 35px;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: center;
                        border-radius: 6px;
                        margin-left: 10px;
                        cursor: pointer;
                        background:linear-gradient(to bottom,#fff 0% ,#fff 40%,#d0d0d0 75%,#fff 100%);
                    }
                }
                .el-descriptions-item__label{
                    width: 200px;
                    display: flex;
                    justify-content:flex-start;
                    line-height: 50px;
                    font-weight: 700;
                }
            }
        }
    }
}

</style>
// 记录相关逻辑
import { _pcOnlinePayRecordsList, _bankTransfer, _withdrawalsRecords, _rebates, _elseList, _gameRecords, _getPartake } from '../../api/modules/records'
import { Message } from 'element-ui'
import store from '../../store/index'
import i18n from '@/il8n/index'
import { _getTime,_formatDate } from '../../utils/utils'

// 获取交易记录数据
export const _getTransDatas = ({ type, start, end, currPage, pageSize }) => {
    start = start ? _getTime(start) + ' 00:00:00' : ''
    end = end ? _getTime(end) + ' 59:59:59' : ''
    const user = store.getters.userData
    const form = [{
        createdAtBegin: start || '',
        createdAtEnd: end || '',
        memberId: user.user_id || ''
    }, {
        createdAtBegin: start || '',
        createdAtEnd: end || '',
        memberName: user.username
    }, {
        createdAtBegin: start || '',
        createdAtEnd: end || '',
        id: user.user_id || ''
    }, {
        startTime: start || '',
        endTime: end || '',
        memberId: user.user_id || ''
    }, {
        createdAtBegin: start || '',
        createdAtEnd: end || '',
    }, ]
    let params = {
        ...form[type - 1],
        currentPage: currPage,
        pageSize: pageSize,
    }
    const reqMap = {
        1: _pcOnlinePayRecordsList,
        2: _bankTransfer,
        3: _withdrawalsRecords,
        4: _rebates,
        5: _elseList
    }
    return new Promise(resolve => {
        reqMap[type](params).then(res => {
            if (!res || res.code != 0) {
                resolve(false)
                // 操作失败
                return Message.error(res.msg || i18n.t("errorMessage.text9"))
            }
            if (res.data && (res.data.totalRecords > 0 || res.data.total > 0)) {
                let list = []
                const total = res.data.totalRecords || res.data.total
                let mapData = {}
                console.log(type, res.data.content)
                    // 数据处理
                switch (Number(type)) {
                    case 1: // 充值记录
                        list = res.data.content.map(item => {
                            item._betNo = item.orderNo
                            item._createdAt = _formatDate(item.createdAt)
                            if (item.type == 41) {
                                mapData = {
                                    0: i18n.t("textMessage.text16"),//未处理
                                    2: i18n.t("textMessage.text17"),//充值成功
                                    3: i18n.t("textMessage.text18"),//充值失败
                                    4: i18n.t("textMessage.text19"),//关闭

                                    1: i18n.t("textMessage.text20"),//处理中
                                    5: i18n.t("textMessage.text20"),
                                    6: i18n.t("textMessage.text20"),
                                    7: i18n.t("textMessage.text20")
                                }

                            } else {
                                mapData = {
                                    0: i18n.t("textMessage.text21"),//待支付
                                    1: i18n.t("textMessage.text22"),//支付中
                                    2: i18n.t("textMessage.text23"),//支付成功
                                    3: i18n.t("textMessage.text24"),//支付失败
                                    4: i18n.t("textMessage.text25")//已关闭
                                }
                            }
                            item._status = mapData[item.status]

                            mapData = {
                                3: i18n.t("textMessage.text26"),//线下银行卡支付
                                4: i18n.t("textMessage.text27"),//线下二维码支付
                                5: i18n.t("textMessage.text28"),//线上支付
                                11: i18n.t("textMessage.text29"),//手动上分
                                41: i18n.t("textMessage.text30"),//数值货币支付
                            }
                            item._payChannel = mapData[item.type]
                            return item
                        })
                        break
                    case 2: // 银行转账
                        list = res.data.content.map(item => {
                            item._betNo = item.orderNo
                            item._createdAt = _formatDate(item.createdAt)
                            mapData = {
                                0: i18n.t("textMessage.text21"),//待支付
                                1: i18n.t("textMessage.text22"),//支付中
                                2: i18n.t("textMessage.text23"),//支付成功
                                3: i18n.t("textMessage.text24"),//支付失败
                            }
                            item._status = mapData[item.status]

                            mapData = {
                                'alipay': i18n.t("textMessage.text31"),//支付宝扫码
                                'wechat': i18n.t("textMessage.text32"),//微信扫码
                                'wechath5': i18n.t("textMessage.text33"),//微信H5
                                'zfbh5': i18n.t("textMessage.text34"),//支付宝H5
                                'unionclound': i18n.t("textMessage.text35"),//银联扫码
                                'union': i18n.t("textMessage.text36"),//银联转账
                                'other': i18n.t("textMessage.text37"),//其它
                            }
                            item._payChannel = mapData[item.type]
                            return item
                        })
                        break
                    case 3: // 提款记录
                        list = res.data.content.map(item => {
                            item._betNo = item.orderNo
                            item._createdAt = _formatDate(item.createdAt)
                            if (item.type == 43) {
                                mapData = {
                                    0: i18n.t("errorMessage.text21"),//未处理
                                    2: i18n.t("errorMessage.text39"),//提款成功
                                    3: i18n.t("errorMessage.text40"),//提款失败
                                    4: i18n.t("errorMessage.text25"),//已关闭
                                    1: i18n.t("errorMessage.text20"),//处理中
                                    5: i18n.t("errorMessage.text20"),
                                    6: i18n.t("errorMessage.text20"),
                                    7: i18n.t("errorMessage.text20"),
                                }
                            } else {
                                mapData = {
                                    1: i18n.t("errorMessage.text42"),//'出款中',
                                    0: i18n.t("errorMessage.text41"),//'待出款',
                                    2: i18n.t("errorMessage.text43"),//'出款成功',
                                    3: i18n.t("errorMessage.text44"),//'出款失败',
                                    4: i18n.t("errorMessage.text25"),//'关闭'
                                }
                            }
                            item._status = mapData[item.status]

                            mapData = {
                                8: i18n.t("errorMessage.text45"),//'银行卡提款',
                                10: i18n.t("errorMessage.text46"),//'手动下分',
                                43: i18n.t("errorMessage.text47"),//'数值货币提款申请',
                                47: i18n.t("errorMessage.text48"),//'origo钱包提款'
                            }
                            item._payChannel = mapData[item.type]
                            return item
                        })
                        break
                    case 4: // 返水记录
                        list = res.data.list.map(item => {
                            item._betNo = item.betNo
                            item._createdAt = _formatDate(item.createdAt)
                            item.amount = Number(item.rebateAmount).toFixed(2)
                            item._payChannel = item.typeName
                            return item
                        })
                        break
                    case 5: // 其它
                        list = res.data.content.map(item => {
                            item._betNo = item.orderNo
                            item._createdAt = _formatDate(item.createdAt)
                            if (item.type == 42) {
                                mapData = {
                                    0: i18n.t("textMessage.text16"),//'未处理',
                                    2: i18n.t("textMessage.text49"),//'成功',
                                    3: i18n.t("textMessage.text50"),//'失败',
                                    4: i18n.t("textMessage.text51"),//'关闭',

                                    1: i18n.t("textMessage.text20"),//'处理中',
                                    5: i18n.t("textMessage.text20"),//'处理中',
                                    6: i18n.t("textMessage.text20"),//'处理中',
                                    7: i18n.t("textMessage.text20"),//'处理中'
                                }
                            } else {
                                mapData = {
                                    0: i18n.t("textMessage.text35"),//'待领取',
                                    1: i18n.t("textMessage.text4"),//'已过期',
                                    2: i18n.t("textMessage.text49"),//'成功',
                                    3: i18n.t("textMessage.text50"),//'失败'
                                }
                            }
                            item._status = mapData[item.status]

                            mapData = {
                                ...i18n.t('textMessage.textMapDate'),
                                // 6: '线下入款优惠',
                                // 7: '线上入款优惠',
                                // 11: '手动上分',
                                // 12: '手动存款优惠上分',
                                // 13: '手动返水优惠上分',
                                // 14: '手动活动优惠上分',
                                // 15: '自动活动优惠上分',
                                // 20: '手动下分-存款',
                                // 21: '手动下分-优惠活动',
                                // 22: '手动下分-人工存款反水',
                                // 23: '手动下分-存款优惠',
                                // 25: '手动上分-优惠活动',
                                // 26: '手动上分-人工存款返水',
                                // 27: '手动上分-存款优惠',
                                // 28: '手动上分-其他',
                                // 31: '手动上分彩金',
                                // 32: '活动上分彩金',
                                // 33: '手动上分彩金-人工存款返水',
                                // 34: '手动上分彩金-存款优惠',
                                // 35: '手动上分彩金-优惠活动',
                                // 36: '手动上分-其他彩金',
                                // 42: '数值货币优惠上分',
                                // 44: '救济金上分',
                                // 45: '救济金彩金',
                                // 46: '每日签到上分',
                                // 50: '全民返利',
                                // 51: '其他',
                                // 52: '利息宝存入',
                                // 53: '利息宝本金提取',
                                // 62: '信息认证',
                                // 63: '周勤奖励',
                                // 64: '幸运注单',
                                // 65: '周年礼金',
                                // 66: '棋牌奖励',
                                // 67: '棋牌救援',
                                // 68: '千倍百倍',
                                // 69: '亿元回馈',
                                // 99: '利息宝利息提取'
                            }
                            item._payChannel = mapData[item.type]
                            return item
                        })
                        break
                }
                store.dispatch('transDataAction', { list: list, total: total })
            } else {
                store.dispatch('transDataAction', { list: [], total: 0 })
            }
        })
    })
}

// 获取游戏记录数据
export const _gameRecordsAction = ({ platform, start, end, startTime, endTime, currPage, pageSize }) => {
    startTime = startTime ? _getTime(startTime) + ' 00:00:00' : ''
    endTime = endTime ? _getTime(endTime) + ' 59:59:59' : ''
    const user = store.getters.userData
    const params = {
        memberId: user.user_id || '',
        betAmountMin: start,
        betAmountMax: end,
        startTimeBet: startTime,
        endTimeBet: endTime,
        pageSize: pageSize,
        pageNum: currPage,
        vendorCodeList: platform ? [platform] : [],
        region: platform,
    }
    return new Promise(resolve => {
        _gameRecords(params).then(res => {
            if (res && res.code == 0) {
                // 统计
                const count = {}
                count.totalBet = Math.abs(res.data.totalBetAmount).toFixed(2)
                count.effective = Number(res.data.totalBetAmountValid).toFixed(2)
                count.distributed = Number(res.data.totalPayoff).toFixed(2)
                count.profit = (count.distributed - count.totalBet).toFixed(2)
                store.dispatch('recordGameCountAction', count)

                // 数据处理
                if (res.data.total <= 0) {
                    store.dispatch('recordGameListAction', { list: [], total: 0 })
                } else {
                    const total = res.data.total
                    const list = res.data.list.map(item => {
                        item._createdAt = _formatDate(item.betTime)
                        item._payoutTime = item.status == 0 ? '--' : item.payoutTime ?_formatDate(item.payoutTime) : '--'
                        item._betAmount = item.betAmount < 0 ? item.betAmount *(-1) : item.betAmount
                        item._betAmountValid = Number(item.betAmountValid).toFixed(2)
                        item._payoff = Number(item.payoff).toFixed(2)
                        item._winLoss = Number(item.winLoss).toFixed(2)
                        if (item.status == 0) {
                            item._payoff = '--'
                            item._winLoss = '--'
                            item._betAmountValid = '--'
                        }
                        return item
                    })
                    console.log(list, '=============')
                    store.dispatch('recordGameListAction', { list: list, total: total })
                }
                resolve(res)
            } else {
                // 获取游戏记录失败
                Message.error(res.msg || i18n.t("errorMessage.text36"))
                resolve(false)
            }
        })
    })
}

// 获取活动记录数据
export const _getPartakeAction = ({ name, startTime, endTime, currPage, pageSize }) => {
    const user = store.getters.userData
    startTime = startTime ? _getTime(startTime) + ' 00:00:00' : ''
    endTime = endTime ? _getTime(endTime) + ' 59:59:59' : ''
    const params = {
        actName: name,
        dateBegin: startTime,
        dateEnd: endTime,
        pageSize: pageSize,
        currentPage: currPage,
        memberId: user.user_id,
    }
    return new Promise(resolve => {
        _getPartake(params).then(res => {
            if (res && res.code == 0) {
                if (res.data.totalRecords > 0) {
                    const total = res.data.totalRecords
                    const list = res.data.content.map(item => {
                        item._joinTime = _formatDate(item.joinTime)
                        return item
                    })
                    store.dispatch('recordActiveListAction', { list: list, total: total })
                } else {
                    store.dispatch('recordActiveListAction', { list: [], total: 0 })
                }
                resolve(res)
            } else {
                // 获取活动记录失败
                Message.error(res.msg || i18n.t("errorMessage.text37"))
                resolve(false)
            }
        })
    })
}
<template>
  <div class="MobileCode-bg">
    <div class="MobileCode-game">
      <div class="MobileCode-content">
        <div class="MobileCode-app" v-animate-css="'fadeInRightBig'"></div>
        <div class="MobileCode-code">
          <div class="MobileCode-title">{{$t("homePageIndex.text48")}}</div>
          <div class="mobile-text">
            {{$t("homePageIndex.text49")}}
          </div>
          <div class="MobileCode-qrcode">
            <div class="mobile-qrcode">
              <div class="qrcode" v-animate-css.hover="'bounce'">
                <div id="qrcodeApp" class="blury-qrcode1"></div>
              </div>
              <p>{{$t("homePageIndex.text50")}}</p>
              <p>{{$t("homePageIndex.text51")}}</p>
            </div>
            <div class="mobile-H5">
              <div class="H5" v-animate-css.hover="'headShake'">
                <img loading="lazy" v-lazy="require('@/assets/images/index/home/h5.png')" alt="" />
              </div>
              <p>{{$t("homePageIndex.text52")}}</p>
              <p class="yellow">{{ mobileUrl }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _jump, _createQRcode, _downloadApp } from '@/core/utils/utils'
import { _referralLinkAction } from '@/core/comp/pages/promo'
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      appUrl: _downloadApp(),
      mobileUrl:'',
    };
  },
  computed: {
    ...mapGetters([
      'inviteCode',
      'inviteAddress'
    ]),
  },
  mounted () {
  },
  created() {
    setTimeout(() => {
      _createQRcode({
        id: 'qrcodeApp',
        content: this.appUrl,
        width: 130,
        height: 130,
      })
    }, 500);
    this.getMobileUrl();
  },
  methods: {
    getMobileUrl(){
      var u = window.location.hostname;
      var a = u.split('.');
      if (a.length > 2) a = a.slice(a.length - 2);
      var s = 'http://m.' + a.join('.');
      // if (this.inviteCode) s += '?code=' + this.inviteCode;
      this.mobileUrl = s;
    },
  },
};
</script>

<style lang="scss" scoped>
$imgUrl: "~@/assets/images/index/";
.MobileCode-bg {
  background-color: #141414;
  .MobileCode-game {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0;
    .MobileCode-content {
      font-size: 14px;
      color: #fff;
      width: 100%;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      .MobileCode-app {
        flex: 1;
        height: 500px;
        background: url(#{$imgUrl}home/mobile-download.png) no-repeat center/contain;
      }
      .MobileCode-code {
        flex: 1;
        color: #fff;
        .MobileCode-title {
          font-size: 40px;
          background: linear-gradient(to bottom, #ece088, #d7a925);
          -webkit-background-clip: text;
          color: transparent;
        }
        .mobile-text {
          font-size: 16px;
          margin: 20px 0;
        }
        .MobileCode-qrcode {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 40px;
          p {
            font-size: 16px;
            line-height: 28px;
            text-align: center;
          }
          p.yellow {
            color: #f4cd6f;
          }
          .mobile-qrcode {
            flex: 1;
            .qrcode {
              width: 160px;
              height: 160px;
              margin: 20px auto;
              overflow: hidden;
              background: url(#{$imgUrl}home/qrcode.png) no-repeat center/contain;
              position: relative;
              .blury-qrcode1{
                  position: absolute;
                  top: 18px;
                  left: 14px;
              }
              img {
                width: 142px;
                height: 135px;
                margin: 15px auto;
                border: none;
                display: block;
              }
            }
          }
          .mobile-H5 {
            flex: 1;
            .H5 {
              margin: 20px auto;
              text-align: center;
              img {
                margin: auto;
                height: 160px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

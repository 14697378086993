import Vue from 'vue'
import VueRouter from 'vue-router'
import StartUp from '../views/StartUp.vue'
import Wrap from '../views/Wrap.vue'

Vue.use(VueRouter)

const routes = [
  { // 启动页
    path: '/',
    name: 'startUp',
    component: StartUp
  },
  { // 维护页
      path: '/maintain',
      name: 'maintain',
      component: () =>
          import ('../core/components/Maintain.vue')
  },
  { // ip限制页
      path: '/iplimit',
      name: 'iplimit',
      component: () =>
          import ('../core/components/Iplimit.vue')
  },
  { // 财务中心-银行卡存款
    path: '/bankRecharge',
    name: 'bankRecharge',
    component: () => import('../views/my/Finance/BankRecharge.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: Wrap,
    children: [
      { // 主页
        path: '/home',
        name: 'home',
        component: () => import('../views/wrap/Home.vue')
      },
      { // 优惠活动
        path: '/promo',
        name: 'promo',
        component: () => import('../views/wrap/Promo.vue')
      },
      { // 活动详情
        path: '/promoInfo',
        name: 'promoInfo',
        component: () => import('../views/wrap/PromoInfo.vue')
      },
      { // 电子游戏
        path: '/elec',
        name: 'elec',
        component: () => import('../views/wrap/Elec.vue')
      },
      { // 真人视讯
        path: '/live',
        name: 'live',
        component: () => import('../views/wrap/Live.vue')
      },
      { // 电子竞技
        path: '/gaming',
        name: 'gaming',
        component: () => import('../views/wrap/Gaming.vue')
      },
      { // 体育赛事
        path: '/sports',
        name: 'sports',
        component: () => import('../views/wrap/Sports.vue')
      },
      { // 彩票
        path: '/lottery',
        name: 'lottery',
        component: () => import('../views/wrap/Lottery.vue')
      },
      { // 彩票
        path: '/fish',
        name: 'fish',
        component: () => import('../views/wrap/Fish.vue')
      },
      { // 棋牌
        path: '/chess',
        name: 'chess',
        component: () => import('../views/wrap/Chess.vue')
      },
      
      { // 帮助中心
          path: '/help',
          name: 'help',
          component: () =>
              import ('../views/wrap/Help.vue')
      },
      { // 个人中心
        path: '/my',
        name: 'my',
        component: () => import('../views/my/My.vue'),
        children: [
          { // 个人资料
            path: '/userInfo',
            name: 'userInfo',
            component: () => import('../views/my/UserInfo/UserInfo.vue'),
          },
          // { // 安全管理
          //   path: '/safety',
          //   name: 'safety',
          //   component: () => import('../views/my/Safety/Safety.vue'),
          // },
          { // 资金密码
            path: '/fundPass',
            name: 'fundPass',
            component: () => import('../views/my/Safety/FundPass.vue'),
          },
          { // 设备管理
            path: '/devices',
            name: 'devices',
            component: () => import('../views/my/Safety/Devices.vue'),
          },
          { // 收款方式管理
            path: '/bankCard',
            name: 'bankCard',
            component: () => import('../views/my/BankCard/BankCard.vue'),
          },
          { // 添加收款方式
            path: '/bankAdd',
            name: 'bankAdd',
            component: () => import('../views/my/BankCard/BankAdd.vue'),
          },
          { // 账号密码
            path: '/password',
            name: 'password',
            component: () => import('../views/my/Safety/Password.vue'),
          },
          { // 彩金管理
            path: '/jackpot',
            name: 'jackpot',
            component: () => import('../views/my/Jackpot/Jackpot.vue'),
          },
          { // 财务中心-会员存款
            path: '/deposit',
            name: 'deposit',
            component: () => import('../views/my/Finance/Deposit.vue'),
          },
          { // 财务中心-会员提款
            path: '/withdraw',
            name: 'withdraw',
            component: () => import('../views/my/Finance/Withdraw.vue'),
          },
          { // 交易记录
            path: '/transRecord',
            name: 'transRecord',
            component: () => import('../views/my/TransRecord/TransRecord.vue'),
          },
          { // 返水详情
              path: '/rebateInfo',
              name: 'rebateInfo',
              component: () =>
                  import ('../views/my/TransRecord/RebateInfo.vue'),
          },
          { // 游戏记录
            path: '/gameRecord',
            name: 'gameRecord',
            component: () => import('../views/my/GameRecord/GameRecord.vue'),
          },
          { // 活动记录
            path: '/activeRecord',
            name: 'activeRecord',
            component: () => import('../views/my/ActiveRecord/ActiveRecord.vue'),
          },
          { // 公告和站内信
            path: '/notice',
            name: 'notice',
            component: () => import('../views/my/Mail/Mail.vue'),
          },
          { // 全民返利
            path: '/rebate',
            name: 'rebate',
            component: () => import('../views/my/Rebate/Rebate.vue'),
          },
          { // 全民返利-邀请列表
              path: '/rebateList',
              name: 'rebateList',
              component: () =>
                  import ('../views/my/Rebate/RebateList.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: 'error404',
    component: () => import('../views/Error404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

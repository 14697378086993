<!-- 个人中心-页面 -->
<template>
    <div class="page-mcenter">
        <div class="page-my">
            <div class="my-left">
                <div class="my-userTop">
                    <img src="@/assets/images/my/user-icon.png" class="user-img" alt="" width="150" height="150">
                    <div class="my-userText">
                        <span>{{ userData.name }}</span>
                        <span class="user_btn" @click="vipListReback">
                            {{ userData._vipLevel > 0 ? 'vip' + userData._vipLevel :  $t("userPage.text295")}}
                        </span>
                    </div>
                    <div class="my-money">
                        <span>{{$t("userPage.text296")+"："}}</span>
                        <span class="name">{{ balance }}</span>
                        <i class="refresh-btn refresh-btn-lg" :class="{'rotate': rotate}" @click="getPlatformBalance(true)"></i>
                    </div>
                    <div class="my-money">
                        <span>{{$t("userPage.text297")+"："}}</span>
                        <span>{{ reback.rebateAmount || '0.00' }}</span>
                        <i class="refresh-btn refresh-btn-lg" @click="getReback"></i>
                        <span class="receive-icon" :class="disabledGetReturnWater ? '' : 'disabledGet'" @click="openReback">{{$t("userPage.text169")}}</span>
                    </div>
                    <div class="my-Withdrawcash">
                        <div class="Withdrawcash-list" :class="isactive == i+1 ? 'active' : ''" v-for="(item,i) in withdrawcashList" :key="i" @click="_jump(item.pathName)">
                            <span class="icon-bordr">
                                <i :class="'Withdrawcash-icon'+ (i+1)"></i>
                            </span>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <div @click="_jump(item.pathName)" class="my-left-item" :class="isactive == i+4 ? 'active' : ''" v-for="(item, i) in navList" :key="i">
                    <i class="my_icon" :class="'my_icon'+(i+1)"></i>
                    <span>{{ item.name }}</span>
                </div>
            </div>
            <div class="my-right">
                <router-view />
            </div>
            <!-- 领取返水 弹窗 -->
            <Dialog :visible.sync="rebackVisible" 
                    :title='$t("userPage.text299")'
                    center
                    width="400px">
                <div class="Dialog-content">
                    <div class="amount">￥{{ reback.rebateAmount || '0.00' }}</div>
                    <div>{{$t("userPage.text299")+"："}}{{ reback.verityCount }}{{$t("userPage.text153")}}</div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <!-- 查看详情 -->
                    <Button @click="toRebateInfo">{{$t("userPage.text11")}}</Button>
                    <!-- 立即领取 -->
                    <Button type="primary" @click="receiveReback">{{$t("userPage.text155")}}</Button>
                </div>
            </Dialog>
            <!-- vip详情 弹窗 -->
            <Dialog :visible.sync="vipListVisible" :title='$t("userPage.text299")' class="vipList-Dialog">
                <div>
                    <!-- <div>{{ vipLevelList }}</div> -->
                    <Table :data="vipLevelList"  
                            border
                            style="width: 100%"
                            max-height="500"                            
                            header-cell-class-name="vipList-Dialog-header"
                            cell-class-name="vipList-Dialog-item">
                        <TableColumn
                            prop="gradeName"
                            :label='$t("userPage.text300")' align="center">
                        </TableColumn>
                        <!-- 达成条件 -->
                        <TableColumn 
                            :label='$t("userPage.text301")' align="center">
                            <template slot-scope="scope">
                                <span>{{$t("userPage.text66")+ scope.row.charge + ',' + $t("userPage.text302") + scope.row.bet}}</span>
                            </template>
                        </TableColumn>
                        <!-- 有效投注 -->
                        <TableColumn 
                            :label='$t("userPage.text138")' align="center">
                            <template slot-scope="scope">
                                <span>{{'24h/' +  scope.row.withdrawLimit + $t("userPage.text302")}}</span>
                            </template>
                        </TableColumn>
                    </Table>
                </div>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _getNotReceive } from '@/core/api/modules/promo'
import { _noticeList } from '@/core/api/modules/system'
import { _jump } from '@/core/utils/utils'
import { _getBankList,_getPlatformBalance,_getReback, _receiveReback ,_getVipList } from '@/core/api/modules/user'
import {  Message,Dialog,Table,TableColumn,Button } from 'element-ui'
// 路由对应的下表
const routeIndex = {
    'deposit': 1,
    'withdraw': 2,
    'jackpot': 3,
    'userInfo': 4,
    'password':4,
    'fundPass':4,
    'devices':4,
    'gameRecord': 5,
    'transRecord': 6,
    'rebateInfo':6,
    'notice': 7,
    'bankCard': 8,
    'bankAdd':8,
    'activeRecord': 9,
    'rebate':10,
    'rebateList':10,
}
export default {
    components: {
        Button,
        Dialog,
        Table,
        TableColumn
    },
    data () {
        return {
            navList: [
                { name: this.$t("userPage.text303"), pathName: 'userInfo', },
                { name: this.$t("userPage.text304"), pathName: 'gameRecord', },
                { name: this.$t("userPage.text305"), pathName: 'transRecord', },
                { name: this.$t("userPage.text306"), pathName: 'notice', },
                { name: this.$t("userPage.text307"),pathName: 'bankCard',icon:''},
                { name: this.$t("userPage.text308"), pathName: 'activeRecord', },
                { name: this.$t("userPage.text309"), pathName: 'rebate', },
            ],
            withdrawcashList:[
                { name: this.$t("userPage.text310"), pathName: 'deposit'},
                { name: this.$t("userPage.text311"), pathName: 'withdraw'},
                { name: this.$t("userPage.text312"), pathName: 'jackpot'},
            ],
            isactive:0,
            reback:{},
            rotate:false,//余额刷新
            rebackVisible:false,
            vipListVisible:false,
            disabledGetReturnWater: false, //默认不可以领取返水
        }
    },
    watch: {
        // 监听选中菜单
        $route: {
            handler: function (val) {
                if(val){
                    console.log('---个人中心路由变化', val, routeIndex[val.name])
                    this.isactive = routeIndex[val.name]
                }
            },
            immediate: true
        }
    },
    computed:{
        ...mapGetters([
            'userData',
            'vipLevelList',
        ]),
        balance () { // 主账户数据
            return this.userData._totalBalance || '0.00'
        },
    },
    mounted () {
        setTimeout(() => {
            _getVipList() // 获取vip等级列表
            _getBankList() // 绑定的银行卡列表
            this.getReback()//获取返水信息
        }, 1000)
        setTimeout(() => {
            _getNotReceive() // 是否有彩金需要领取
            _noticeList({}) // 获取未读公告
        }, 2000)
    },
    methods: {
        _jump,
        // 查看详情
        toRebateInfo() {
            this.rebackVisible = false
            _jump('rebateInfo')
        },
        getPlatformBalance (isRefresh) {
            this.rotate = true
            _getPlatformBalance().then(res => {
                if (res && isRefresh)  {
                    this.rotate = false
                    Message.success(this.$t("userPage.text121"))
                }
            })
            this.rotate = false
        },
        // 获取返水信息
        getReback (isRefresh) {
            this.rotate1 = true
            _getReback().then(res => {
                if (res && res.code == 0) {
                    this.reback = res.data
                    this.reback.rebateAmount = this.reback.rebateAmount ? this.reback.rebateAmount.toFixed(2) : '0.00'
                    //待领取返水>最低返水金额
                    if (this.reback.rebateAmount * 1 > res.data.rebateDown * 1 || this.reback.rebateAmount * 1 > 0){
                        this.disabledGetReturnWater = true;
                    }
                    if (isRefresh) return Message.success(this.$t("userPage.text121"))
                }else{
                    this.disabledGetReturnWater = false;
                    Message.error(res.msg)
                }
            }).catch(() => {
                this.rotate1 = false  
            })
        },
        openReback(){
            if(!this.disabledGetReturnWater) return
            if (this.reback.rebateAmount < this.reback.rebateDown) return Message.warning(this.$t("userPage.text313"))
            this.rebackVisible = true
        },
        // 领取返水
        receiveReback () {
            _receiveReback().then(res => {
                if (res) {
                    this.rebackVisible = false
                    this.getReback()
                }
            })
        },
        // 领取返水
        vipListReback () {
            this.vipListVisible = true
            console.log('---vip',this.vipLevelList)
        },
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/my/';
.page-mcenter{
    width:100%;
    height:auto;
    color: #fff;
    padding: 30px 0;
    background:url(#{$imgUrl}my_bg.jpg)repeat top center;
    .page-my {
        width: 1200px;
        margin: 0 auto;
        min-height: 500px;
        display: flex;
        .my-left {
            width: 300px;
            padding-right:40px;
            border-right: 1px solid #000000;
            .my-userTop{
                text-align: center;
                .user-img{
                    margin: 0 auto;
                    border-radius: 50%;
                    border: none;
                }
                .my-userText{
                    margin: 15px auto;
                    text-align: center;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    span{
                        font-size: 16px;
                        margin-right: 10px;
                    }
                    .user_btn{
                        width: 89px;
                        height:28px;
                        font-weight: 700;
                        font-size: 12px;
                        display: flex;
                        cursor: pointer;
                        justify-content:center;
                        align-items: center;
                        background: url(#{$imgUrl}btn_user.png) repeat top center/contain;
                    }
                }
                .my-money{
                    text-align:left;
                    display: flex;
                    margin: 10px auto;
                    align-items:center;
                    span:nth-child(2){
                        font-size: 16px;
                        font-weight: 700;
                        color: #fdf86a;
                    }
                    // i.refresh-btn {
                    //     width: 23px;
                    //     height:23px;
                    //     display:block;
                    //     margin: 0 15px;
                    //     background:url(#{$imgUrl}vip_refresh.png)no-repeat center/contain;
                    // }
                    .receive-icon{
                        width: 53px;
                        height: 28px;
                        line-height: 28px;
                        cursor: pointer;
                        text-align: center;
                        background: url(#{$imgUrl}btn_balance.png) no-repeat center/contain;
                        &.disabledGet{
                            -webkit-filter: grayscale(100%);
                            -moz-filter: grayscale(100%);
                            -ms-filter: grayscale(100%);
                            -o-filter: grayscale(100%);
                            filter:grayscale(100%);
                            cursor:inherit;
                        }
                    }
                }
                .my-Withdrawcash{
                    display: flex;
                    margin: 0px auto 20px;
                    justify-content:space-between;
                    align-items: center;
                    padding: 27px 0 20px 0;
                    border-bottom: 1px solid #000000;
                    .Withdrawcash-list{
                        // flex: 1;
                        cursor: pointer;
                        text-align: center;
                        &.active{
                            color: #d1a302;
                            .icon-bordr{
                                border: 1px solid #d1a302;
                            }
                        }
                        .icon-bordr{
                            display:block;
                            padding: 10px;
                            margin:0px auto 10px;
                            border: 1px solid transparent;
                            border-radius: 10px;
                        }
                        @for $i from 1 to 4{    
                            .Withdrawcash-icon#{$i}{
                                width: 30px;
                                height: 30px;
                                display:block;
                                margin: 0 auto;
                                background:url(#{$imgUrl}my_vip#{$i}.png)no-repeat center/contain;
                            }
                        }
                    }
                }
            }
        }
        .my-right {
            flex: 1;
            padding-left: 20px;
        }
        .my-left-item {
            width: 100%;
            height: 50px;
            padding: 0 10px;
            cursor: pointer;
            display: flex;
            color: #6f6f6f;
            font-size: 16px;
            font-weight: 700;
            justify-content: flex-start;
            align-items: center;
            &.active,&:hover{
                color: #fff;
                border-radius: 10px;
                border: 1px solid #3e3e3e;
                background-color: #000000;
            }
            .my_icon{
                width: 32px;
                height: 32px;
                display:block;
                margin-right: 30px;
            }
            span{
                flex:1;
            }
            @for $i from 1 to 8{
                .my_icon#{$i}{
                    background:url(#{$imgUrl}my_icon#{$i}.png)no-repeat center/contain;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.vipList-Dialog{
    .el-dialog{
        .el-dialog__header{
            .el-dialog__title{
                text-align: left;
                font-size: 24px;
                display: block;
                font-weight: 700;
            }
        }
        .el-dialog__body{
            .el-table{
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                &::before {
                    background-color: #f8ec9a;
                }
                .vipList-Dialog-header{
                    border:none;
                    color: #553b0f;
                    text-align:center;
                    background: linear-gradient(180deg, #fffdc4, #e3bd29,#dcad02);
                    border-bottom:1px solid #f8ec9a;
                }
                .vipList-Dialog-item{
                    text-align:center;
                    border-bottom:1px solid #f8ec9a;
                    border-right:1px solid #f8ec9a;
                }
            }
            .el-table--border{
                border:1px solid #f8ec9a;
                border-right: none;
                border-bottom: none;
                &::after{
                    background-color: #f8ec9a;
                }
            }
        }
    }
}
</style>
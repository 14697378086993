<!-- 滚动公告 -->
<template>
    <div class="home-notice-component">
        <div class="title">
            <img src="../../assets/images/index/black_a001_news.png" alt="">
            <!-- {{$t("componentsViews.text34")}} -->
            <span></span>
        </div>
        <div class="home-notice" @click.stop="handleSectionClick($event)">
            <vueSeamless
                :data="list"
                :class-option="option"
                class="home-notice-box"
            >
                <ul class="home-notice-ul">
                    <li class="home-notice-li"  v-for="(item, index) in list" :key="index" v-text="item.content"></li>
                </ul>
            </vueSeamless>
        </div>
        
        <Dialog
        :visible.sync="noticeDialog" width="800px" destroy-on-close center :modal-append-to-body='false'
        :show-close="false" :close-on-click-modal="false" 
        >   <div class="notice-dialog">
                <div class="dialog-title">
                    {{$t("componentsViews.text35")}}
                    <img @click="noticeDialog = false" src="../../assets/images/index/black_a001_login_close.png" alt="">
                </div>
                <div class="dialog-content">
                    <div v-for="(item, index) in list" :key="'list' + index">
                        {{ item.content }}
                    </div>
                </div>
            </div>
            
        </Dialog>
    </div>
</template>

<script>
import vueSeamless from 'vue-seamless-scroll'
import { _getNotice } from '../api/modules/system'
import { Dialog } from 'element-ui'
export default {
    props: {
        classOption: { // 滚动配置 https://chenxuan0000.github.io/vue-seamless-scroll/guide/01-basic.html
            type: Object,
            default: () => {
                return { step: 2, direction: 2 }
            }
        }
    },
    components: {
        vueSeamless,
        Dialog
    },
    data () {
        return {
            noticeDialog:false,
            list: []
        }
    },
    created () {
        this.getNotice()
    },
    computed: {
        option () {
            return {
                limitMoveNum: this.list.length,
                ...this.classOption
            }
        }
    },
    methods: {
        handleSectionClick(e){
            let target = e.target;
            if(target.tagName == "LI") {
                this.noticeDialog = true;
            }
        },
        getNotice () {
            _getNotice({
                createdAt: '',
                currentPage: '',
                pageSize: '',
                publishedAt: '',
                subject: '',
                type: ''
            }).then(res => {
                if (!res) return
                this.list = res.data.content
            })
        }
    }
}
</script>

<style lang="less" scoped>
.home-notice-component {
    display: flex;
    align-items: center;
    width: 1200px;
    height: 100%;
    .title {
        display: flex;
        align-items: center;
        width: 40px;
        img {
            vertical-align: bottom;
            margin-right: 12px;
        }
        span {
            display: inline-block;
            width:2px;
            height:14px;
            background-color:#333333;
            border-radius:90px;
            margin-left: 10px;
        }
    }
}
.home-notice {
    width: 1160px;
}
.home-notice-box {
    overflow: hidden;
    width: 100%;
    height: 100%;
    line-height: 16px;
    
}
.home-notice-ul {
    width: max-content;
    list-style: none;
    display: flex;
}
.home-notice-li {
    margin-right: 20px;
    width: max-content;
    color: #ffbe00;
    cursor: pointer;
}
.home-notice-component ::v-deep .el-dialog__header{
    padding: 0;
}
.home-notice-component ::v-deep .el-dialog--center .el-dialog__body {
    padding: 0;
}
.notice-dialog {
    width:800px;
    height:485px;
    .dialog-title {
        width:800px;
        height:56px;
        line-height: 56px;
        text-align: center;
        font-weight:700;
        color:#ffffff;
        font-size:22px;
        background-color:#dcad02;
        border-radius:10px 10px 0px 0px;
        img {
            position: absolute;
            top: 15px;
            right: 18px;
            cursor: pointer;
        }
    }
    .dialog-content {
        width: 100%;
        height: 429px;
        padding: 15px;
        padding-bottom: 30px;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        div {
            min-height: 80px;
            padding: 15px 0;
            border-bottom: 1px dashed #ccc;
        }
    }
}
</style>
<!-- 主页面-顶部 -->
<template>
    <div class="GameConsole-game">
        <div class="GameConsole-bg">
            <div class="game-left">
                <div class="gameTab">
                    <div class="gameItem" @click="_jump('help',7)"></div>
                    <div class="gameItem" @click="_jump('chess')"></div>
                    <div class="gameItem" @click="toMobile"></div>
                </div>
                <div class="gameText">
                    <swiper :options="swiperOption" class="swiper-container" v-if="gameBanner.length>0">
                        <swiper-slide class="swiper-item" v-for="(item,index) in gameBanner" :key="index">
                            <img :src="item._url" class="swiper-img" >
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="game-icon"></div>
            </div> 
            <div class="game-right" @click="_jump('fish')"></div>
        </div>
    </div>
</template>

<script>
import { _jump,_getImgUrl } from '@/core/utils/utils'
export default {
        data () {
        return {
            scroll:3000,
            swiperOption: {//swiper3
                speed: 1000,
                slidesPerView: 1,
                loop:true,
                autoplay: {
                    delay: 3000, //等4秒下一个
                    disableOnInteraction: false // 用户操作后继续自动轮播
                },
                //左右点击
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
            gameBanner:[
                {
                    _url: _getImgUrl('GameConsole-banner1','.jpg')
                },
                {
                    _url: _getImgUrl('GameConsole-banner2','.jpg')
                }
            ]
        }
    },
    computed: {
    },
    methods: {
        _jump,
        _getImgUrl,
        toMobile(){
            this.$router.push({ 
                path: '/home',
                query:{
                    scroll:this.scroll++
                }
            });
        },
        // toGaming(pathName,id){
        //     this.$router.push({
        //         path:pathName,
        //         query:{
        //             id:id,
        //         },
        //     })
        // }
    }
}
</script>

<style lang="scss" scoped >
    $imgUrl: '~@/assets/images/index/';
    .GameConsole-game{
        width: 100%;
        height: 376px;
        background:url(#{$imgUrl}home/GameConsole-bg.png) no-repeat center/101%;
        .GameConsole-bg{
            width: 1200px;
            min-width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content:space-around;
            background: #1c1c1c;
            .game-left{
                width: 70%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                position: relative;
                .gameTab{
                    width: 100%;
                    height: 50%;
                    display: flex;
                    justify-content:space-around;
                    .gameItem{
                        flex: 1;
                        height: 100%;
                        cursor: pointer;
                    }
                }
                .game-icon{
                    position: absolute;
                    top: 9px;
                    left: -32px;
                    width:38px;
                    height:108px;
                    background:url(#{$imgUrl}home/GameConsole-icon.png) no-repeat center/contain;
                }
                .gameText{
                    width: 100%;
                    height:50%;
                    background:#fff;
                    border-radius: 5px;
                    overflow: hidden;
                }
            }
            .game-right{
                width: 30%;
                height: 100%;
                cursor: pointer;
            }
        }
    }
</style>
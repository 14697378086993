<!-- 体育赛事 -->
<template>
    <div class="WrapPage-live">
        <div class="WrapPage-live-tab">
           <div class="swipers">
                <swiper :options="swiperOption">
                    <swiper-slide  v-for="(item,index) in newList" :key="index">
                        <div class="live-tab">
                            <div class="item" :class="{'item-act':curId === citem.id}"
                            v-for="(citem,cindex) in item" :key="cindex" @click="switchTab(citem)">
                                {{citem.name}}
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <!--以下看需要添加-->
                <div class="swiper-button-next"></div>
           </div>
        </div>
        <div class="WrapPage-live-content">
            <div class="live-left">
                <img loading="lazy" v-lazy="imgUrlOne" class="animate__animated animate__bounceIn">
            </div>
            <div class="live-right">
                <div class="text1">{{ curItem.name }}</div>
                <div class="text2">{{ curItem.nameEn }}</div>
                <div class="text3">{{$t("gamePage.text39")}}</div>
                <div class="text4">{{$t("gamePage.text40")}}</div>
                <div>{{$t("gamePage.text41")}}</div>
                <div class="text3">{{$t("gamePage.text42")}}</div>
                <div class="text4">{{$t("gamePage.text43")}}</div>
                <div class="live-icon">
                    <div class="icon-box" v-for="(item,index) in iconList" :key="'icon' + index">
                        <img :src="item.img" alt="">
                        <div>{{ item.name }}</div>
                    </div>
                </div>
                <div class="buttom" @click="_getToken(curItem,2)">
                    {{$t("gamePage.text1")}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { _getToken } from '@/core/comp/common'
import { mapGetters } from 'vuex'


export default {
    data () {
        return {
            iconList:[
                {
                    name: this.$t("gamePage.text44"),
                    img:require('@/assets/images/index/game/black_sports_a001_game1.png')
                },
                {
                    name: this.$t("gamePage.text45"),
                    img:require('@/assets/images/index/game/black_sports_a001_game2.png')
                },
                {
                    name: this.$t("gamePage.text46"),
                    img:require('@/assets/images/index/game/black_sports_a001_game3.png')
                },
                {
                    name: this.$t("gamePage.text47"),
                    img:require('@/assets/images/index/game/black_sports_a001_game4.png')
                },
                {
                    name: this.$t("gamePage.text48"),
                    img:require('@/assets/images/index/game/black_sports_a001_game5.png')
                },
                {
                    name: this.$t("gamePage.text49"),
                    img:require('@/assets/images/index/game/black_sports_a001_game6.png')
                },
                {
                    name: this.$t("gamePage.text50"),
                    img:require('@/assets/images/index/game/black_sports_a001_game7.png')
                }
                
            ],
            curId: '',
            imgUrlOne:'',
            curItem:{},
            newList:[],// 分割之后的新数组
            swiperOption: {//swiper3
                // autoplay: 3000,
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 30,
                //左右点击
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
        }
    },
    computed: {
        lists () { // 二级导航
            return this.gameMenu.arr_ty || []
        },
        ...mapGetters([
            'gameMenu', // 游戏分类
            "systemImgHost"
        ])
    },
    mounted() {
        this.splitList()
    },
    created(){
        this.switchTab(this.lists[0])
    },
    methods: {
        _getToken,
        switchTab(item) {
            this.curItem = item
            this.curId = item.id
            this.imgUrlOne = this.systemImgHost + item.imgUrlOne
        },
        // 数组切割 列表 每组几个
        splitList() {
            for (let i = 0; i < this.lists.length; i += 5) {
                this.newList.push(this.lists.slice(i, i + 5));
            }
        },
    }
}
</script>

<style lang="scss" scoped>
$imgUrlOne: '~@/assets/images/index/';
.WrapPage-live {
    background: url(#{$imgUrlOne}game/black_sports_a001_bg.jpg) center;
    background-size: 100% 100%;
}
@import "~@/assets/css/WrapPage-live.scss";
</style>
<!-- 真人视讯 -->
<template>
    <div class="page-fish">
        <div data-v-738a24f7="" class="bg-animation">
            <img loading="lazy"
                src="../../assets/images/fish/fish5.png"
                width="130"
                class="cloud cloud-5"
            />
            <img loading="lazy"
                src="../../assets/images/fish/fish2.png"
                width="90"
                class="cloud cloud-2"
            />
            <!-- <img loading="lazy"
                src="../../assets/images/fish/fish3.png"
                width="110"
                class="cloud cloud-3"
            /> -->
            <img loading="lazy"
                src="../../assets/images/fish/fish4.png"
                width="150"
                class="cloud cloud-4"
            /><img loading="lazy"
                src="../../assets/images/fish/fish1.png"
                width="140"
                class="cloud cloud-1"
            />
            <img loading="lazy"
                src="../../assets/images/fish/fish6.png"
                width="110"
                class="cloud cloud-6"
            />
        </div>
        <div class="text-img-box">
            <img loading="lazy" :src=" _getImgUrl('black_fishing_a001_title')" />
        </div>
        <div class="main">
            <ul>
                <li class="item" v-for="(item, index) in lists" :key="index" @click="_getToken(item,1)">
                <div class="nomal"></div>
                    <div class="active"></div>
                    <div class="title">{{item.name}}</div>
                    <div class="img">
                        <img loading="lazy" v-lazy="item._imgUrl?item._imgUrl:assetsList[index].img" alt="">
                    </div>
                    <a class=" active"> 
                        {{$t("gamePage.text5")}}
                    </a>
                </li>
            </ul>
        </div>
  </div>
</template>
<script>
import { _getToken } from '../../core/comp/common'
import { _getImgUrl } from '@/core/utils/utils'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            assetsList: [
                // 鱼的图片列表
                { img: require("@/assets/images/fish/fish1.png") },
                { img: require("@/assets/images/fish/fish2.png") },
                { img: require("@/assets/images/fish/fish3.png") },
                { img: require("@/assets/images/fish/fish4.png") },
                { img: require("@/assets/images/fish/fish5.png") },
                { img: require("@/assets/images/fish/fish6.png") },
            ]
        }
    },
    computed: {
        lists () { // 二级导航
            return this.gameMenu.arr_by || []
        },
        ...mapGetters([
            'gameMenu', // 游戏分类
        ])
    },
    mounted() {
        console.log(this.lists)
    },
    methods: {
        _getImgUrl,
        _getToken,
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/page-fish.scss"
</style>
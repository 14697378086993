
<template>
    <div class="page-help">
        <div class="page-help-box">
            <div class="help-left">
                <div v-for="(item,index) in list"  :key="index" :class="{'item-act':curIndex === index}"
                @click="switchTab(index)">
                    <i :class="[curIndex === index ? 'active-icon' : '' , 'help-icon' + index]"></i>
                    {{ item.title }}
                </div>
            </div>
            <div class="help-right">
                <About v-show="curIndex === 0" />
                <DepositDemo v-show="curIndex === 1" />
                <WithdrawDemo v-show="curIndex === 2" />
                <Declarations v-show="curIndex === 3" />
                <CommonProblem v-show="curIndex === 4" />
                <AgentDetail  v-show="curIndex === 5" />
                <AgentApply  v-show="curIndex === 7" />
            </div>
        </div>
    </div>
</template>
<script>
import About from '@/components/help/About.vue'
import DepositDemo from '@/components/help/DepositDemo.vue'
import WithdrawDemo from '@/components/help/WithdrawDemo.vue'
import Declarations from '@/components/help/Declarations.vue'
import CommonProblem from '@/components/help/CommonProblem.vue'
import AgentApply from '@/components/AgentApply/AgentApply.vue'
import AgentDetail from '@/components/AgentDetail/AgentDetail.vue'
export default {
    components: {
        About,
        DepositDemo,
        WithdrawDemo,
        Declarations,
        CommonProblem,
        AgentApply,
        AgentDetail
    },
    watch: {
        $route: {
            handler: function (val) {
                if(val.query.id) {
                    this.curIndex = Number(val.query.id)
                } else {
                   this.curIndex = 0 
                }
            },
            immediate: true
        }
    },
    data () {
        return {
            list:[
                { title: this.$t("homePageIndex.text69"), },//关于我们
                { title: this.$t("homePageIndex.text70"), },//存款帮助
                { title: this.$t("homePageIndex.text71"), },//取款帮助
                { title: this.$t("homePageIndex.text72"), },//规则与声明
                { title: this.$t("homePageIndex.text73"), },//常见问题
                { title: this.$t("homePageIndex.text74"), },//代理加盟
                { title: this.$t("homePageIndex.text75"), },//代理登录
                { title: this.$t("homePageIndex.text76"), },//代理注册
            ],
            curIndex:0
        }
    },

    mounted() {
        
        
    },
    methods: {
        switchTab(index) {
            if(index === 6){
                return
                // return window.open('https://agent01.bets8888.net','_blank')
            } 
            this.curIndex = index
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/page-help.scss";
</style>
<template>
  <div class="page-maintain">
    <div class="maintain-box">
      <!-- 系统维护升级 -->
      <div class="maintain-title">{{$t("componentsViews.text29")}}</div>
      <!-- 预期开启时间： -->
      <div class="maintain-text1">{{$t("componentsViews.text30")}}：{{ endTime}}</div>
      <!-- 尊敬的用户 -->
      <div class="maintain-text2">{{$t("componentsViews.text31")}}：</div>
      <!-- 为了给您带来更好的体验 -->
      <div class="maintain-text3">
        {{$t("componentsViews.text32")}},{{ projectName }}
        {{$t("componentsViews.text33")}}
      </div>
      <div class="maintain-customer" @click="_toCustomerService">{{$t("componentsViews.text12")}}</div>
    </div>
  </div>
</template>

<script>
import { _toCustomerService } from '@/core/comp/common'
import config from '../common/config'
export default {
    data() {
        return {
            projectName: window.projectName,
            endTime:config.maintainEndTime
        };
    },
    mounted() {
    },
    methods: {
      _toCustomerService,

    }
};
</script>

<style  lang="scss" scoped>
$imgUrl: '~@/assets/images/start/';
.page-maintain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: url(#{$imgUrl}/whsj_bg.png) no-repeat center;
  background-size: 100% 100%;
  .maintain-box {
    margin-top: 50px;
    padding-left: 668px;
    .maintain-title {
      font-weight:700;
      font-style:italic;
      color:#fdf86a;
      font-size:60px;
    }
    .maintain-text1 {
      margin-top: 10px;
      font-family:Arial;
      font-weight:700;
      color:#cccccc;
      font-size:18px;
    }
    .maintain-text2 {
      margin-top: 10px;
      color:#999999;
      font-size:16px; 
    }
    .maintain-text3 {
      width: 586px;
      color:#999999;
      font-size:16px; 
      text-indent: 32px;
    }
    .maintain-customer {
      width:130px;
      height:44px;
      line-height: 42px;
      text-indent: 30px;
      margin-top: 20px;
      text-align: center;
      font-weight:700;
      color:#ffffff;
      font-size:16px;
      background: url(#{$imgUrl}/whsj_botton.png) no-repeat center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  
}
</style>

// 请求封装
import axios from 'axios'
import globalConfig from '../common/config'
import store from '../store/index'
import { Loading, Message } from 'element-ui'
import Router from '../../router/index'
import Config from '../common/config'
import { _loginOut } from '../comp/common'
import i18n from '@/il8n/index'

// 登录过期
const loginInvalid = () => { // 登录过期
    _loginOut()
    setTimeout(() => {
        Router.push({
            name: 'home'
        })
    }, 500)
}
//登录异常提示
let loginRuest = 0
// 全局loading
let requestNumber = 0 // 正在发送中且需要loading的请求数量
let loading = false // 是否有请求还在loading状态
let loadingInstance = null
const addLoadingRequest = () => { // 增加一个需要loading的请求
    requestNumber++
    if (!loading) {
        loading = true
        loadingInstance = Loading.service({
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.6)',
            text: i18n.t("textMessage.text56") +'...'//请稍等
        })
    }
}
const subLoadingRequest = () => { // 减少一个需要loading的请求
    requestNumber--
    if (requestNumber <= 0) {
        setTimeout(() => {
            if (loadingInstance) loadingInstance.close()
            loading = false
        }, 300)
    }
}

// 请求拦截器
axios.interceptors.request.use(config => {
    config.headers = {
        'X-Frame-Options': 'SAMEORIGIN',
        contentType: 'application/json',
        clientCode: globalConfig.clientCode,
        pc: 1,
        clientItem: globalConfig.childCode,
        skinCode: globalConfig.theme,
        lang: globalConfig.locale,
    }
    if (store.getters.isLogin) {
        config.headers.Authorization = store.getters.userAccessToken
    }
    if(['a025','a026'].includes(globalConfig.theme)){
        config.headers['Accept-Language'] = "gu-IN,gu;q=0.9"
        config.headers['ascriptionType'] = globalConfig.ascriptionType 
    }
    return config
})

// 返回拦截器
axios.interceptors.response.use(
    response => {
        switch (response.data.code) {
            case 16003: // 其他设备登录
                if(loginRuest == 0){
                    loginRuest = 1
                    Message({
                        // 账号已在其它设备登录，请重新登录
                        message: i18n.t("errorMessage.text25"),
                        type: 'error'
                    })
                    loginInvalid()
                }
                break
            case 110009: // IP限制
                Router.push({
                    name: 'iplimit',
                    query: {
                        ip: response.data.msg,
                        customerUrl: response.data.data && response.data.data.customer
                    }
                })
                break
            default:
                if(response.status == 401) return
                // if(response.data && response.data.code!==0) {
                //     // 账号已在其它设备登录，请重新登录
                //     let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                //     Message({
                //         message: msg ,
                //         type: 'error'
                //     })
                //     return response 
                // } else {
                //     return response
                // }
                return response
                
        }
    },
    err => {
        switch (err.response.status) {
            case 503:
            case 500:
                Message({
                    // 服务异常
                    message: i18n.t("errorMessage.text56"),
                    type: 'error'
                })
                break
            case 401:
                if(loginRuest == 0){//登录异常只提示一次
                    loginRuest = 1
                    Message({
                        // 您的登录账户已经过期，请您重新登录
                        message: i18n.t("errorMessage.text26"),
                        type: 'error'
                    })
                    loginInvalid()
                }
                break
            default:
                console.log(err.response.data,"err.response.data",err.response.msg,"err.response.data")
                if (err.response.data && err.response.data.msg) {
                    let msg =  i18n.t('errorCode.'+err.response.data.code) + `(${err.response.data.code})`
                    Message({
                        message: msg,
                        type: 'error'
                    })
                }
                break
        }
        console.log("err.response.data=================",err.response,"err.response.data",err.response.msg,"err.response.data")
        if(loginRuest == 1 && err.response.status == 401) return
        return Promise.resolve(err.response)
    }
)


// 普通post请求
export const _post = (url, data, isLoading = false,isInterceptCode = true) => {
    const requestUrl = Config.host + url
    return new Promise(resolve => {
        const req = {
            appVer: Config.appVer,
            ver: Config.protocolVer,
            reqId: Config.reqId,
            param: data || {},
        }
        if (isLoading) addLoadingRequest()
        axios.post(requestUrl, req).then(response => {
            if (isLoading) subLoadingRequest()
            if(response?.data?.code === 0){
                 resolve(response.data)
            } else {
                if(isInterceptCode){
                    let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                    Message({
                        message: msg ,
                        type: 'error'
                    })
                    return 
                } else {
                    resolve(response.data)
                }
            }
            resolve(false)
        }).catch(() => {
            if (isLoading) subLoadingRequest()
            resolve(false)
        })
    })
}

// 自定义路径的post请求
export const _mainPost = (url, data, isLoading = false, isInterceptCode = true) => {
    return new Promise(resolve => {
        const req = {
            appVer: Config.appVer,
            ver: Config.protocolVer,
            reqId: Config.reqId,
            param: data || {},
        }
        if (isLoading) addLoadingRequest()
        axios.post(url, req).then(response => {
            if (isLoading) subLoadingRequest()
            if(response?.data?.code === 0){
                resolve(response.data)
            } else {
                if(isInterceptCode){
                    let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                    Message({
                        message: msg ,
                        type: 'error'
                    })
                    return 
                } else {
                    resolve(response.data)
                }
            }
            resolve(false)
        }).catch(() => {
            if (isLoading) subLoadingRequest()
            resolve(false)
        })
    })
}

// get请求
export const _get = (url, params = '', isLoading = false, isInterceptCode = true) => {
    const requestUrl = Config.host + url + params
    return new Promise(resolve => {
        if (isLoading) addLoadingRequest()
        axios.get(requestUrl).then(response => {
            if (isLoading) subLoadingRequest()
            if(response?.data?.code === 0){
                resolve(response.data)
            } else {
                if(isInterceptCode){
                    let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                    Message({
                        message: msg ,
                        type: 'error'
                    })
                    return 
                } else {
                    resolve(response.data)
                }
            }
            resolve(false)
        }).catch(() => {
            if (isLoading) subLoadingRequest()
            resolve(false)
        })
    })
}

// put请求
export const _put = (url, data, isLoading = false, isInterceptCode = true) => {
    const requestUrl = Config.host + url
    return new Promise(resolve => {
        const req = {
            appVer: Config.appVer,
            ver: Config.protocolVer,
            reqId: Config.reqId,
            param: data || {},
        }
        if (isLoading) addLoadingRequest()
        axios.put(requestUrl, req).then(response => {
            if (isLoading) subLoadingRequest()
            if(response?.data?.code === 0){
                resolve(response.data)
            } else {
                if(isInterceptCode){
                    let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                    Message({
                        message: msg ,
                        type: 'error'
                    })
                    return 
                } else {
                    resolve(response.data)
                }
            }
            resolve(false)
        }).catch(() => {
            if (isLoading) subLoadingRequest()
            resolve(false)
        })
    })
}

// 没有公共参数的put请求
export const _putUrl = (url, data, isLoading = false, isInterceptCode = true) => {
    const requestUrl = Config.host + url
    return new Promise(resolve => {
        const req = data || {}
        if (isLoading) addLoadingRequest()
        axios.put(requestUrl, null, {
            params: req
        }).then(response => {
            if (isLoading) subLoadingRequest()
            if(response?.data?.code === 0){
                resolve(response.data)
            } else {
                if(isInterceptCode){
                    let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                    Message({
                        message: msg ,
                        type: 'error'
                    })
                    return 
                } else {
                    resolve(response.data)
                }
            }
            resolve(false)
        }).catch(() => {
            if (isLoading) subLoadingRequest()
            resolve(false)
        })
    })
}

// delete请求
export const _delete = (url, params = '', isLoading = false, isInterceptCode = true) => {
    const requestUrl = Config.host + url + params
    return new Promise(resolve => {
        if (isLoading) addLoadingRequest()
        axios.delete(requestUrl).then(response => {
            if (isLoading) subLoadingRequest()
            if(response?.data?.code === 0){
                resolve(response.data)
            } else {
                if(isInterceptCode){
                    let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                    Message({
                        message: msg ,
                        type: 'error'
                    })
                    return 
                } else {
                    resolve(response.data)
                }
            }
            resolve(false)
        }).catch(() => {
            if (isLoading) subLoadingRequest()
            resolve(false)
        })
    })
}


// 带参数的delete请求
export const _deleteArray = (url, params, isLoading = false, isInterceptCode = true) => {
    const requestUrl = Config.host + url
    return new Promise(resolve => {
        const req = {
            appVer: Config.appVer,
            ver: Config.protocolVer,
            reqId: Config.reqId,
            clientCode: Config.clientCode,
            operator: 'xxx',
            param: params || {},
        }
        if (isLoading) addLoadingRequest()
        axios.delete(requestUrl, {
            data: req
        }).then(response => {
            if (isLoading) subLoadingRequest()
            if(response?.data?.code === 0){
                resolve(response.data)
            } else {
                if(isInterceptCode){
                    let msg =  i18n.t('errorCode.'+response.data.code) + `(${response.data.code})`
                    Message({
                        message: msg ,
                        type: 'error'
                    })
                    return 
                } else {
                    resolve(response.data)
                }
            }
            resolve(false)
        }).catch(() => {
            if (isLoading) subLoadingRequest()
            resolve(false)
        })
    })
}
<!-- 主页 -->
<template>
    <div>
        <!-- Banner -->
        <div class="home-banner">
            <Banner />
        </div>
        <!-- 公告 -->
        <WrapNotice />
        <!-- 游戏机 -->
        <GameConsole />
        <!-- 游戏类型 -->
        <GameList />
        <!-- 游戏奖池 -->
        <GamePrizePool />
        <!-- 热门游戏推荐 -->
        <GamePopular />
        <!-- 游戏轮播 -->
        <GameInner />
        <!-- 手机二维码下载 -->
        <MobileCode />
        <!-- 服务优势 -->
        <ServiceAdvantage />
        
        <!-- 电子游戏 -->
        <!-- <div class="home-games">
            <ElecGames />
        </div> -->
        <JackpotNew />
    </div>
</template>

<script>
import Banner from '@/core/components/Banner.vue'
import WrapNotice from '@/components/wrap-notice/WrapNotice.vue'

import GameConsole from '@/components/game-console/GameConsole.vue'
import GameList from '@/components/game-list/GameList.vue'
import GamePrizePool from '@/components/game-prizePool/GamePrizePool.vue'
import GamePopular from '@/components/game-popular/GamePopular.vue'
import GameInner from '@/components/game-inner/GameInner.vue'
import MobileCode from '@/components/mobile-downloadCode/MobileCode.vue'
import ServiceAdvantage from '@/components/service-advantage/ServiceAdvantage.vue'
import JackpotNew from '@/components/JackpotNew/JackpotNew.vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        Banner,
        WrapNotice,
        GameConsole,
        GameList,
        GamePrizePool,
        GamePopular,
        GameInner,
        MobileCode,
        ServiceAdvantage,
        JackpotNew
    },
    computed: {
        ...mapGetters([
            'isLogin',
            'systemQuickEntry'
        ])
    }
}
</script>

<style scoped lang="scss">
.home-banner {
    width: 100%;
    height: 600px;
    min-height: 325px;
    position: relative;
}
</style>
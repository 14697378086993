<!-- 验证码 -->
<template>
    <div class="verify-code" style="width: 100%">
        <!-- 普通验证码 -->
        <div class="verify-code-item verify-code-nomal" v-if="codeType === 0">
            <img  class="icon"  src="@/assets/images/index/loginVerify.png" alt="">
            <input class="verify-code-input" v-model="captchaCode" type="text" :placeholder="$t('componentsViews.text68')">
            <img class="verify-code-img" @click="captchaData" v-if="verifyCodeImg" :src="verifyCodeImg" alt="#">
        </div>

        <!-- 极验 -->
        <div class="verify-code-item1" v-if="codeType === 1" :id="domId">{{captchaText}}</div>

        <!-- 数美 -->
        <div class="verify-code-item2" v-if="codeType === 2" id="captcha" @click="captchaClickSM">{{captchaText}}</div>

    </div>
</template>

<script>
import {
    _getDefaultStatus,
    _captcha,
    _gt,
    } from '../api/modules/user'
import { Message } from 'element-ui'
import '../common/gt.js'
import '../common/smcp.min.js'
export default {
    props:{
        showVerifyImg:{
            type: Boolean,
            default: false,

        },
        domId:{
            type: String,
            default: 'captcha1'
        }
    },
    data () {
        return {
            loading: false,

            codeType: -1, // 0：默认验证码  1：极验  2：数美

            // 普通验证码
            captchaCode: '', // 验证码
            captchaKey: '',
            verifyCodeImg: '',

            captcha: {}, // 验证码对象
            captchaText: '', // 表面文本
            // 数美
            smData: {
                lang: '',
                mode: '',
                organization: '',
                product: '',
                appId: '',
                channel: ''
            },
            fingerprintState:false,

        }
    },
    mounted () {
        this.getDefaultStatus()
    },
    methods: {
        // ----- 重置验证码 ------
        reset () {
            const captcha1 = document.querySelector(`#${this.domId}`)
            if (captcha1) {
                captcha1.innerHTML = ""
            }
            this.getDefaultStatus()
        },
        // ----- 获取验证状态 ------
        getResult () {
            switch (this.codeType) {
                case 1:
                case 2:
                    return this.captcha.getValidate()
                case 0:
                    return {
                        captchaCode: this.captchaCode,
                        captchaKey: this.captchaKey
                    }
            }
        },
        // 获取验证码类型
        getDefaultStatus () {
            let _this = this
            _getDefaultStatus().then(res => {
                if (res && res.code === 0) {
                    _this.codeType = res.data.type
                    switch (_this.codeType) {
                        case 0:
                            _this.captchaData()
                            break
                        case 1:
                            _this.getGeetest()
                            break
                        case 2:
                            Object.assign(_this.smData, res.data)
                            _this.getSM()
                            break
                    }
                    // this.fingerprintState = res.data.fingerprintState
                    // sessionStorage.setItem('fingerprintState', this.fingerprintState);
                    // if(this.fingerprintState){
                    //     _getDeviceId(res.data.fingerprintId).then( res => {
                    //         this.$config.fingerprint = res
                    //         sessionStorage.setItem('fingerprint', res);
                    //     })
                    // }
                } else {
                    // 获取验证码配置失败
                    Message.error(_this.$t('componentsViews.text69'))
                }
            })
        },
        // 获取普通验证码
        captchaData () {
            if (this.loading) return
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 2000)
            _captcha().then(res => {
                if (res && res.code == 0) {
                    this.verifyCodeImg = res.data.captcha_image_content
                    this.captchaKey = res.data.captchaKey
                } else {
                    // 获取验证码失败
                    Message.error(this.$t('componentsViews.text70'))
                }
            })
        },
        // 获取极验验证码
        getGeetest () {
            // eslint-disable-next-line
            _gt().then(res => {
                if (res && res.code === 0) {
                    window.initGeetest(
                        {
                            gt: res.data.gt,
                            challenge: res.data.challenge,
                            new_captcha: res.data.newCaptcha, // 用于宕机时表示是新验证码的宕机
                            offline: res.data.success !== 1, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
                            product: "float", // 产品形式，包括：float，popup
                            width: "100%",
                            lang: window.locale, // 根据环境来 en zh-cn zh-hk
                        },
                        this.onGeetCaptcha
                    )
                } else {
                    // 获取极验失败
                    Message.error(this.$t('componentsViews.text71'))
                }
            })
        },
        // 验证极验
        onGeetCaptcha (res) {
            this.captcha = res
            res.appendTo(`#${this.domId}`)
            res.onReady(() => {
                if (this.captcha) {
                    this.captchaText = ''
                } else {
                    // 加载中
                    this.captchaText = this.$t('componentsViews.text72')
                }
            })
        },
        // 获取数美验证码
        getSM () {
            const data = {
                ...this.smData,
                width: 300,
                https: location.protocol == "https:",
                appendTo: '#captcha'
            }
            // eslint-disable-next-line
            initSMCaptcha(
                data,
                this.onSMCaptcha
            )
        },
        // 验证数美
        onSMCaptcha (res) {
            this.captcha = res
            this.captchaText = this.$t('componentsViews.text73')//点击验证
            this.captcha.onSuccess(data => {
                if (data.pass) {
                    this.captcha.rid = data.rid
                    this.captchaText = this.$t('componentsViews.text74')//验证成功
                } else {
                    this.captcha.rid = null
                    this.captchaText = this.$t('componentsViews.text75')//验证失败
                }
            })
            this.captcha.onError(() => {
                this.captchaText = this.$t('componentsViews.text75')//验证失败
                this.captcha.rid = null
            })
        },
        // 点击数美
        captchaClickSM () {
            const result = this.captcha.getResult()
            if (!result.pass) {
                this.captcha.verify()
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../styles/verifyCode/verifyCode.scss";
</style>
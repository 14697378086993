// 代理相关数据


// 代理相关数据
const agentInfo = {
    state: {
        agentInfo: {}, // 代理相关数据
    },
    mutations: {
        AGENT_INFO (state, data) {
            state.agentInfo = data || {}
        }
    },
    actions: {
        agentInfoAction ({ commit }, data) {
            commit('AGENT_INFO', data)
        }
    },
    getters: {
        agentInfoRule: state => state.agentInfo.rule || '', // 代理规则
    }
}


export default {
    state: {
        ...agentInfo.state,
    },
    mutations: {
        ...agentInfo.mutations,
    },
    actions: {
        ...agentInfo.actions,
    },
    getters: {
        ...agentInfo.getters,
    }
}
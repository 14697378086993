<!-- 信息编辑 -->
<template>
    <div class="views-EditInfo">
        <Descriptions title="" :column="1">
            <!-- 真实姓名 -->
            <DescriptionsItem :label='$t("userPage.text282")'>
                <span v-if="userData.realName">{{ userData.realName }}</span>
                <div v-if="!userData.realName">
                    <Input v-model.trim="form.realName" :placeholder='$t("userPage.text283")' clearable />
                </div>
            </DescriptionsItem>
            <!-- 会员生日 -->
            <DescriptionsItem :label='$t("userPage.text284")'>
                <span v-if="userData.birthday">{{ userData._birthday }}</span>
                <div v-if="!userData.birthday">
                    <DatePicker v-model="form.birthday" type="date" value-format="timestamp">
                        <template slot="prefix">
                            <i></i>
                        </template>
                    </DatePicker>
                </div>
            </DescriptionsItem>
            <!-- 用户昵称 -->
            <DescriptionsItem :label='$t("userPage.text285")'>
                <span v-if="userData.nickname">{{ userData.nickname }}</span>
                <div v-if="!userData.nickname">
                    <Input v-model.trim="form.nickname" :placeholder='$t("userPage.text294")' clearable />
                </div>
            </DescriptionsItem>
            <!-- 联系QQ -->
            <!-- <DescriptionsItem :label='$t("userPage.text286")'>
                <span v-if="userData.qq">{{ userData._hideMiddleQQ }}</span>
                <div v-if="!userData.qq">
                    <Input v-model.trim="form.qq" :placeholder='$t("userPage.text287")' clearable />
                </div>
            </DescriptionsItem> -->
            <!-- 手机号码 -->
            <DescriptionsItem :label='$t("userPage.text288")'>
                <span v-if="userData.phone">{{ userData._hideMiddlePhone }}</span>
                <div v-if="!userData.phone">
                    <Input v-model.trim="form.phone" :placeholder='$t("userPage.text289")' maxlength="11" clearable />
                </div>
            </DescriptionsItem>
            <!-- 联系微信 -->
            <!-- <DescriptionsItem :label='$t("userPage.text290")'>
                <span v-if="userData.wechat">{{ userData.wechat }}</span>
                <div v-if="!userData.wechat">
                    <Input v-model.trim="form.wechat" :placeholder='$t("userPage.text291")' clearable />
                </div>
            </DescriptionsItem> -->
            <!-- 会员邮箱 -->
            <DescriptionsItem :label='$t("userPage.text292")'>
                <span v-if="userData.email">{{ userData._hideMiddleEmail }}</span>
                <div v-if="!userData.email">
                    <Input v-model.trim="form.email" :placeholder='$t("userPage.text293")' clearable />
                </div>
            </DescriptionsItem>
        </Descriptions>

        <div  v-show="!isAllSubmit" class="EditInfo-btn" @click="modifyUser">{{$t("userPage.text244")}}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _modifyUserAction } from '@/core/comp/pages/userInfo'
import { Descriptions, DescriptionsItem, Input, DatePicker } from 'element-ui'
export default {
    components: {
        Descriptions,
        DescriptionsItem,
        Input,
        DatePicker
    },
    computed: {
        isAllSubmit () {
            if (
                this.userData.realName &&
                this.userData.birthday &&
                this.userData.nickname &&
                this.userData.qq &&
                this.userData.phone &&
                this.userData.wechat &&
                this.userData.email
            ) return true
            return false
        },
        ...mapGetters([
            'userData',
        ])
    },
    data () {
        return {
            form: {
                realName: '',
                nickname: '',
                phone: '',
                qq: '',
                wechat: '',
                email: '',
                birthday: '',
            }
        }
    },
    methods: {
        // 修改用户信息
        modifyUser () {
            _modifyUserAction(this.form)
        }
    }
}
</script>
<style lang="scss">
$imgUrl: '~@/assets/images/my/';
.views-EditInfo{
    margin: 30px 0;
    .EditInfo-btn{
        margin: 40px 90px;
        color: #fff;
        border:none;
        width: 130px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        background:url(#{$imgUrl}tab_bg_active.png)no-repeat center/contain;
    }
    .el-descriptions{
        color: #cccccc;
        .el-descriptions__body{
            color: #cccccc;
            background:transparent;
            .el-descriptions__table{
                display:grid;
                grid-template-columns: repeat(2,1fr);
                grid-gap: 20px 10px;
                .el-descriptions-item__cell{
                    display:flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 50px;
                    padding: 0;
                }
                .el-descriptions-item__container {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
                .el-descriptions-item__label{
                    width: 120px;
                    display: flex;
                    justify-content:flex-end;
                    line-height: 1.2;
                    font-weight: 700;
                    text-align:right;
                }
                .el-descriptions-item__content{
                    height: 100%;
                    display:inline-block;
                    width: 260px;
                    display:flex;
                    align-items: center;
                    border-radius: 5px;
                    background:#3c3c3c;
                    border: 1px solid #3e3e3e;
                    span{
                        display:block;
                        padding: 0 15px;
                    }
                    .el-input__inner{
                        height: 50px;
                        color: #fff;
                        line-height: 50px;
                        width: 260px;
                        border-radius: 5px;
                        border: 1px solid #3e3e3e;
                        background:#000000;
                        padding: 0 15px;
                        &:focus{
                            border: 1px solid #fdf86a;
                        }
                    }
                    .el-input__suffix-inner{
                        height: 50px;
                    }
                    // 生日
                    .el-date-editor.el-input{
                        width: 260px;
                        .el-input__prefix{
                            padding: 0 5px;
                        }
                        input{
                            padding-left: 35px;
                        }
                    }
                }
            }
        }
    }
}
</style>
<!-- 账号密码 -->
<template>
    <div class="views-Password">
        <div class="views-title">{{$t("userPage.text197")}}</div>
        <div class="views-title-small">
            <div class="title-btn" @click="_jump('userInfo')">{{$t("userPage.text197")}}</div>
            <i class="views-title-icon"></i>
            <div>{{$t("userPage.text220")}}</div>
        </div>
        <Form
            :model="form"
            :rules="rules"
            class="Password-form"
            ref="PasswordForm"
            label-width="150px">
            <!-- 旧密码 -->
            <Form-item :label='$t("userPage.text221")' prop="oldPass">
                <Input class="fund-item-input" show-password :placeholder='$t("userPage.text222")' v-model="form.oldPass" />
                <span class="text-right">{{$t("userPage.text223")}}</span>
            </Form-item>
            <!-- 新密码 -->
            <Form-item :label='$t("userPage.text224")' prop="newPass">
                <Input class="fund-item-input" show-password :placeholder='$t("userPage.text225")' v-model="form.newPass" />
                <span class="text-right">{{$t("userPage.text223")}}</span>
            </Form-item>
            <!-- 确认密码 -->
            <Form-item :label='$t("userPage.text227")' prop="confirmPass">
                <Input class="fund-item-input" show-password :placeholder='$t("userPage.text228")' v-model="form.confirmPass" />
                <span class="text-right">{{$t("userPage.text226")}}</span>
            </Form-item>
            <Form-item label='' class="FundPassBtn">
                <Button class="Password-btn" @click="modify('PasswordForm')">{{$t("userPage.text213")}}</Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import { Input, Button, Form, FormItem } from 'element-ui'
import { _jump } from '@/core/utils/utils'
import { _modifyPassword } from '@/core/comp/pages/userInfo'
export default {
    components: {
        Input,
        Button,
        Form,
        FormItem
    },
    data () {
        const confirmPass = (rule, value, callback) =>{
            if(!value) {
                return callback(new Error(this.$t("userPage.text230")))
            }else{
                if(value != this.form.newPass){
                    return callback(new Error(this.$t("userPage.text231")))
                }
            }
            callback();
        }
        return {
            form: {
                oldPass: '',
                newPass: '',
                confirmPass: '',
            },
            rules:{
                oldPass:[{required:true,message:this.$t("userPage.text232"),trigger: "blur"}],
                newPass:[{required:true,message:this.$t("userPage.text233"),trigger: "blur"}],
                confirmPass:[{required:true,validator:confirmPass,trigger: "blur"}],
            }
        }
    },
    methods: {
        _jump,
        // 修改资金密码
        modify (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _modifyPassword(
                        this.form,
                        () => { // 修改成功
                        this.form = {
                            oldPass: '',
                            newPass: '',
                            confirmPass: '',
                        }
                        this.$router.push({name:'userInfo'})
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss">
$imgUrl: '~@/assets/images/my/';
.views-Password{
    .Password-form{
        margin-top: 40px;
        .el-form-item {
            .el-form-item__label {
                color: #cccccc;
            }
            .el-input {
                width: 260px;
                margin-right: 20px;
                input{
                    width: 260px;
                    height: 50px;
                    line-height:50px;
                    padding: 0 15px;
                    border-radius: 6px;
                    color: #fff;
                    background:#000;
                    border: 1px solid #3e3e3e;
                    &::-webkit-input-placeholder{
                        color:#888888;
                    }
                }
            }
        }
        .el-form-item__content {
            display: flex;
            align-items: center;
            .text-right{
                line-height: 1;
                display: inline-block;
            }
        }
        .FundPassBtn{
            margin-top: 80px;
            .Password-btn{
                color: #fff;
                border:none;
                width: 130px;
                margin: 0;
                height: 40px;
                text-align: center;
                font-size: 18px;
                background:url(#{$imgUrl}tab_bg_active.png)no-repeat center/contain;
            }
        }
    }
}
</style>
<template>
  <div class="GamePrizePool-list">
        <ul>
          <li class="listItem" ref="li" v-for="i in 8" :key="i">
            <!-- 每列中的10行数字 -->
            <span v-for="num in 10" :key="num" :class="'listItem-'+ (num-1)">{{ num - 1 }}</span>
          </li>
        </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      numberArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], //固定每列中的0-9行数字
      numStr: 16191000, // 默认金额
      numArr: [1, 6, 1, 9, 9, 0, 0, 0], //默认展示8个数字
    };
  },
  mounted() {
    this.setNumberTransform()
    setInterval(() => {
          this.numStr += Math.ceil(Math.random() * 999999);
          if(this.numStr>99999999) {this.numStr = 10000000}
          this.setNumberTransform()
    }, 2000);
  },
  methods: {
    setNumberTransform(){
      this.numArr = this.numStr.toString().split("");
      let numArrlen = this.numArr.length;
      // 展示数字的处理，不够6位前面补0
      for (let i = 0; i < 8 - numArrlen; i++) {
        this.numArr.unshift(0);
      }
      this.$refs.li.forEach((item, index) => {
        let ty = this.numArr[index];
        // 滚动数字(li标签)
        item.style.transform = `translateY(-${ty * 82}px)`;
      });
    }
  },
};
</script>
<style scoped lang='scss'>
$imgUrl: '~@/assets/images/index/';  
  .GamePrizePool-list{
      height: 103px;
      margin: 0 30px;
      width: 545px;
      display:flex;
      align-items: center;
      background: url(#{$imgUrl}GamePrizePool-bg.png)no-repeat bottom center/contain;
      ul{
        height: 82px;
        width: 100%;
        display: flex;
        overflow: hidden;
        .listItem{
          flex: 1;
          min-width:58px;
          height: 100%;
          max-width: 68px;
          flex-direction: column;
          transition: transform 1s ease-in-out;
          @for $i from 0 to 10{
            .listItem-#{$i} {
              height: 100%;
              width: 100%;
              display:block;
              font-size: 0;
              justify-content: center;
              align-items: center;
              background: url(#{$imgUrl}pool/#{$i}.png)no-repeat center;
            }
          }
        }
        .listItem:nth-child(7),.listItem:nth-child(8){
          @for $i from 0 to 10{
            .listItem-#{$i} {
              height: 100%;
              width: 100%;
              display:block;
              font-size: 0;
              justify-content: center;
              align-items: center;
              background: url(#{$imgUrl}pool/#{$i}-black.png)no-repeat center;
            }
          }
        }
      }
  }
</style>
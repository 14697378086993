<!-- 优惠活动 -->
<template>
    <div class="activity_page-promo">
        <div class="activity-top" :style="'background:url('+  _getImgUrl('activity_bg','.jpg') + ') no-repeat center/contain'"></div>
        <div class="activity-content">
            <div class="promo-left">
                <!-- <div class="promo-left-item" :class="activeType == item.id ? 'promo-left-item_active' : ''" v-for="(item, i) in promoMenu" :key="i" @click="changeTab(item.id)">{{ item.remark }}</div> -->
                <swiper :options="swiperOption" class="GameList_scroll">
                    <swiper-slide class="GameListTab-name">
                        <div class="promo-left-item" :class="activeType ? '' : 'promo-left-item_active'" @click="changeTab('')">
                            <i class="item-icon item-icon0"></i>
                            <span>{{$t("promoInfo.text1")}}</span>
                            </div>
                    </swiper-slide>
                    <swiper-slide class="GameListTab-name" 
                        v-for="(item, i) in promoMenu" :key="i">
                        <div class="promo-left-item" 
                            :class="activeType == item.id ? 'promo-left-item_active' : ''"
                            @click="changeTab(item.id)">
                            <i class="item-icon" :class="'item-icon'+(i+1)"></i>
                            <span>{{ item.remark }}</span>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="promo-right">
                <div class="promo-right-item" v-for="(item, i) in list" :key="i" @click="promoInfo(item)">
                    <div class="promo-right-title">{{ item.forever == 1 ? $t("promoInfo.text2") : $t("promoInfo.text3") }}</div>
                    <img loading="lazy" v-lazy="item._picture" class="promo-right-item_img" alt="">
                    <div class="promo-right-item_text">
                        <div>{{ item.name }}</div>
                        <span>{{ $t("userPage.text4") + ':' + item._titleText }}</span>
                    </div>
                </div>
            </div>
            <Empty v-if="!list.length" :description='$t("userPage.text54")' :image="require('@/assets/images/my/null.png')"></Empty>
            <div v-else class="pagination-box">
                <Pagination
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :current-page.sync="currPage"
                :page-size="pageSize"
                :total="total"
                @current-change="loadData"
                ></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _activityType, _activity, } from '@/core/api/modules/promo'
import { _getTime,_getImgUrl } from '@/core/utils/utils'
import { _activeInfoAction } from '@/core/comp/common'
import { Empty,Pagination } from 'element-ui'
export default {
    name: 'promo',
    components:{
        Empty,
        Pagination,
    },
    data () {
        return {
            curItem:{},	 
            list: [], // 活动列表
            activeType: '', // 当前选择的分类
            currPage: 1, // 当前第几页
            pageSize: 9, // 每页多少条
            total:0,
            loading: false,
            swiperOption: {//swiper3
                speed: 1000,
                slidesPerView: 7,
            },
        }
    },
    computed: {
        ...mapGetters([
            'promoMenu', // 分类列表
            'systemImgHost', // 图片服务器
        ])
    },
    mounted () {
        this.getPromoMenu()
        this.loadData()
    },
    methods: {
        _getImgUrl,
        // 获取活动分类
        getPromoMenu () {
            if (this.promoMenu.length) return
            _activityType()
        },
        // 切换分类
        changeTab (id) {
            if (this.activeType == id) return
            this.activeType = id
            this.currPage = 1
            this.list = []
            this.lastPage = false
            this.$nextTick(() => {
                this.loadData()
            })
        },
        // 加载数据
        loadData () {
            const params = {
                currentPage: this.currPage,
                pageSize: this.pageSize,
                ascriptionType: this.activeType
            }
            this.loading = true
            _activity(params).then(res => {
                this.loading = false
                if (!res || res.code) return
                res.data.content.map(a => {
                    // 设置图片链接
                    a._picture = this.systemImgHost + a.picture
                    // 设置时间文本
                    a._titleText = ''
                    if (a.forever == 1) a._titleText = _getTime(a.validStartTime)
                    if (a.forever == 0 && a.type == 0) a._titleText = `${_getTime(a.validStartTime)}${this.$t("promoInfo.text5")}${_getTime(a.validEndTime)}`
                    if (a.forever == 0 && a.type != 0) a._titleText = `${_getTime(a.startTime)}${this.$t("promoInfo.text5")}${_getTime(a.endTime)}`
                    return a
                })
                this.list = res.data.content
                this.total = res.data.totalRecords
            })
        },
        // 查看活动详情
        promoInfo (item) {
            _activeInfoAction(item)
        }
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/index/';
.activity_page-promo{
    width: 100%;
    background-color: #000;
    position: relative;
    .activity-top{
        height: 520px;
        // background:url(#{$imgUrl}activity_bg.jpg) no-repeat center/contain;
    }
}
.activity-content {
    display: flex;
    align-items: flex-start;
    justify-content:flex-start;
    width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    .promo-left {
        // width: 170px;
        width: 100%;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        overflow: auto;
        padding: 10px 0;
        position:sticky;
        top: 205px;
        left: 0;
        z-index: 9;
        background-color: #000;
        .GameList_scroll{
            width: 100%;
        }
        .promo-left-item {
            min-width:160px;
            max-width:160px;
            min-height: 54px;
            color: #fff;
            cursor: pointer;
            font-size: 18px;
            padding: 0 15px 5px;
            margin-right: 10px;
            display: flex;
            align-items:center;
            justify-content: center;
            background:url(#{$imgUrl}activity_btn1.png)no-repeat center/contain;
            i.item-icon{
                width: 31px;
                height: 31px;
                vertical-align: middle;
                display:inline-block;
            }
            span{
                width: 92px;
                font-weight: 700;
                padding-left: 10px;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        @for $i from 1 to 7{
            .GameListTab-name:nth-child(6n+#{$i}) {
                .promo-left-item i.item-icon{
                    background:url(#{$imgUrl}other/activity-icon#{$i}.png) no-repeat center/contain;
                }
            }
        }
        @for $i from 1 to 7{
            .GameListTab-name:nth-child(6n+#{$i}){
                .promo-left-item_active i.item-icon{
                    background:url(#{$imgUrl}other/activity-icon_#{$i}.png) no-repeat center/contain;
                }
            }
        }
        .GameListTab-name:nth-child(1){
            .promo-left-item i.item-icon{
                background:url(#{$imgUrl}other/activity-icon0.png) no-repeat center/contain;
            }
        }
        .GameListTab-name:nth-child(1){
            .promo-left-item_active i.item-icon{
                background:url(#{$imgUrl}other/activity-icon_0.png) no-repeat center/contain;
            }
        }
        .promo-left-item.promo-left-item_active{
            color: #000000;
            background:url(#{$imgUrl}activity_btn.png)no-repeat center/contain;
        }
    }
    .promo-right {
        width: 100%;
        margin: 50px auto 0;
        display:grid;
        grid-gap: 10px 20px;
        grid-template-columns: repeat(3,1fr);
    }
    .promo-right-item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
        position: relative;
        .promo-right-title{
            height: 40px;
            width: 100px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            color: #fff;
            top: 0;
            left: 0;
            position:absolute;
            border-bottom-right-radius: 40px;
        }
        .promo-right-item_img {
            max-height: 200px;
            width: 100%;
            border:none;
        }
        .promo-right-item_text{
            color: #FFF;
            font-size: 18px;
            margin-top:5px;
            span{
                margin-top: 5px;
                display: block;
                color: #666666;
                font-size: 14px;
            }
        }
    }
    @each $i, $color1, $color2 in (1,#e4bf2e, #825a01),(2,#e42e66,#820138),(3,#2783d5,#1857b4),
    (4,#28d86c,#19b758),(5,#d88b2f,#b75632),(6,#bec73a,#748156),(7,#c639c7,#7d5492),
    (8,#68bfdd,#72729a),(9,#fdc89a,#f2655d){
        .promo-right-item:nth-child(9n+#{$i}){
            .promo-right-title{
                background:linear-gradient(to left,#{$color1},#{$color2});
            }
        }
    }
}
</style>
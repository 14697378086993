<template>
  <div class="ServiceAdvantage-bg">
    <div class="ServiceAdvantage-game">
      <div class="ServiceAdvantage-content">
        <div class="ServiceAd-item">
          <div class="ServiceAd-list">
            <!-- 服务优势 -->
            <div class="flex-center ServiceAd-title">
              <i class="ServiceAd-icon1"></i>
              <div class="ServiceAd-right">
                <span>{{$t("homePageIndex.text32")}}</span>
                <p>Service Advantages</p>
              </div>
            </div>
            <!-- 存款火速到账 -->
            <div class="flex-center ServiceAd-title2">
              <i class="ServiceAd-icon2"></i>
              <div class="ServiceAd-right">
                <span>{{$t("homePageIndex.text33")}}</span>
                <p>{{$t("homePageIndex.text34")}}</p>
              </div>
              <div class="num"><span>38</span>{{$t("homePageIndex.text35")}}</div>
              <Progress
                :color="customColors"
                :text-inside="true"
                :stroke-width="8"
                :percentage="38"
              ></Progress>
            </div>
            <!-- 取款火速到账 -->
            <div class="flex-center ServiceAd-title2">
              <i class="ServiceAd-icon2"></i>
              <div class="ServiceAd-right">
                <span>{{$t("homePageIndex.text36")}}</span>
                <p>{{$t("homePageIndex.text37")}}</p>
              </div>
              <div class="num"><span>38</span>{{$t("homePageIndex.text35")}}</div>
              <Progress
                :color="customColors"
                :text-inside="true"
                :stroke-width="8"
                :percentage="38"
              ></Progress>
            </div>
          </div>
          <div class="ServiceAd-list">
            <div class="flex-center ServiceAd-title">
              <i class="ServiceAd-icon3"></i>
              <div class="ServiceAd-right">
                <span>{{$t("homePageIndex.text38")}}</span>
                <p>Product Advantages</p>
              </div>
            </div>
            <div class="ServiceAd-text">
              <span>{{$t("homePageIndex.text39")}}</span>
              <p>{{$t("homePageIndex.text40")}}</p>
            </div>
            <div class="ServiceAd-text">
              <span>{{$t("homePageIndex.text41")}}</span>
              <p>{{$t("homePageIndex.text42")}}</p>
            </div>
          </div>
          <div class="ServiceAd-list">
            <div class="flex-center ServiceAd-title">
              <i class="ServiceAd-icon4"></i>
              <div class="ServiceAd-right">
                <span>{{$t("homePageIndex.text43")}}</span>
                <p>More services</p>
              </div>
            </div>
            <div class="ServiceAd-text">
              <span>{{$t("homePageIndex.text44")}}</span>
              <p>{{$t("homePageIndex.text45")}}</p>
            </div>
            <div class="ServiceAd-text">
              <span>{{$t("homePageIndex.text46")}}</span>
              <p>{{$t("homePageIndex.text47")}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Progress } from "element-ui";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      customColors: "#BEBEBE",
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
$imgUrl: "~@/assets/images/index/";
.ServiceAdvantage-bg {
  background: #1a1a1a;
  .ServiceAdvantage-game {
    width: 1200px;
    margin: 0 auto;
    .ServiceAdvantage-content {
      font-size: 14px;
      color: #fff;
      width: 100%;
      padding: 20px 0;
      .ServiceAd-item {
        display: grid;
        grid-gap: 10px 150px;
        grid-template-columns: repeat(3, 1fr);
        .ServiceAd-list {
          flex: 1;
          color: #828282;
          .ServiceAd-text {
            margin-bottom: 15px;
            span {
              font-size: 18px;
            }
            p {
              margin-top: 8px;
            }
          }
          .ServiceAd-title {
            justify-content: flex-start;
            padding: 10px 0;
            margin-bottom: 20px;
            border-bottom: 1px solid #ffd992;
            span {
              font-size: 18px;
              color: #efc77b;
            }
          }
          .ServiceAd-title2 {
            justify-content: flex-start;
            margin-bottom: 20px;
            flex-wrap: wrap;
            span {
              margin: 5px 0;
              display: inline-block;
              font-size: 18px;
            }
            .num {
              margin-left: auto;
              background: linear-gradient(to bottom, #f6d078, #fefabe);
              -webkit-background-clip: text;
              color: transparent;
              span {
                font-size: 38px;
                font-weight: 700;
              }
            }
            .el-progress {
              width: 100%;
            }
          }
          @for $i from 1 to 5 {
            i.ServiceAd-icon#{$i} {
              width: 38px;
              height: 38px;
              margin-right: 10px;
              display: inline-block;
              background: url(#{$imgUrl}ServiceAd-icon#{$i}.png)
                no-repeat
                center/contain;
            }
          }
        }
      }
    }
  }
}
</style>
<!-- 存款演示 -->
<template>
    <div class="DepositDemo">
        <div class="titleBOx">
                <!-- 会员存款 -->
               <h2 class="title">
                {{$t('depositDemo.text1')}}
            </h2>
        </div>
        <div class="content"> 
            <div class="box">
                <h5 class="tip4">{{$t('depositDemo.text2')}}</h5>
                <img loading="lazy" :src="_getImgUrl('text/deposit1','.jpg')" alt="">
                <h5 class="tip4">{{$t('depositDemo.text3')}}</h5>
                <p class="tip2">{{$t('depositDemo.text4')}}</p>
                <p class="tip2">{{$t('depositDemo.text5')}}</p>
                <p class="tip2">{{$t('depositDemo.text6')}}</p>
                <p class="tip2 tip5">{{$t('depositDemo.text7')}}</p>
                <img loading="lazy" :src="_getImgUrl('text/deposit2','.jpg')" alt="">
                <p class="tip2">{{$t('depositDemo.text8')}}</p>
                <p class="tip2 tip5">{{$t('depositDemo.text9')}}</p>
                <img loading="lazy" :src="_getImgUrl('text/deposit3','.jpg')" alt="">
                <h5 class="tip4">{{$t('depositDemo.text10')}}</h5>
                <p class="tip2">{{$t('depositDemo.text11')}}</p>
                <p class="tip2">{{$t('depositDemo.text12')}}</p>
                <p class="tip2 tip5">{{$t('depositDemo.text13')}}</p>
                <img loading="lazy" :src="_getImgUrl('text/deposit4','.jpg')" alt="">
                <p class="tip2 tip5">{{$t('depositDemo.text14')}}</p>
                <img loading="lazy" :src="_getImgUrl('text/deposit5','.jpg')" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import {_getImgUrl} from '@/core/utils/utils'
export default {
    data(){
        return {
            index:0
        }
    },
    methods:{
        _getImgUrl,
    },
    mounted(){

    }
}
</script>
<style scoped lang='scss'>
    .DepositDemo{
        .titleBOx{
            .title{
                font-weight:700;
                color:#fdf86a;
                font-size:16px;
            }
        }
        .content{
            .box{
                width: 100%;
                .list{
                    display: inline-block;
                    width: 116px;
                    height: 42px;
                    background: #d9d9d9;
                    color: #000;
                    line-height: 45px;
                    font-size: 14px;
                    overflow: hidden;
                    margin-bottom: 30px;
                    margin-top: 10px;
                    margin-right: 10px;
                    font-weight: 600;
                    cursor: pointer;
                    text-indent: 0;
                    text-align: center;
                }
                .activity{
                    background: #000;
                    color: #fff;
                }
                img{
                    width: 100%;
                    margin-bottom: 10px;
                }
                .tip1 {
                    padding: 15px 0 20px;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 30px;
                }
                .tip4 {
                    color: #fff;
                    font-size: 16px;
                    padding: 0 0 10px;
                }
                .tip2 {
                    line-height: 28px;
                    font-size: 14px;
                    color: #ccc;
                }
                .tip5 {
                    margin-bottom: 20px;
                }
            }
        }
    }
</style>
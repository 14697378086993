<template>
    <div class="LoginAction">
        <!-- ip验证弹窗 -->
        <Dialog :visible.sync="ipDialog" :title='$t("componentsViews.text21")' width="550px" destroy-on-close center :modal-append-to-body='false'
        :show-close="false" :close-on-click-modal="false" custom-class="ip-dialog">
            <img @click="closeValide" class="close-icon" src="~@/assets/images/index/login_close.png" alt="">
            <div>{{$t("componentsViews.text22")}}</div>
            <div class="Action-input">
            <!-- 真实姓名 -->
                <input :placeholder='$t("componentsViews.text23")' v-model.trim="ipForm.name" />
            </div>
            <!-- 提交验证 -->
            <div class="valide-buttom" @click="submitIP">{{$t("componentsViews.text24")}}</div>
        </Dialog>

        <!-- 设备验证弹窗 -->
        <Dialog :visible.sync="deviceDialog" :title='$t("componentsViews.text25")' width="550px" destroy-on-close center :modal-append-to-body='false'
        :show-close="false" :close-on-click-modal="false" custom-class="ip-dialog">
            <img @click="closeValide" class="close-icon" src="~@/assets/images/index/login_close.png" alt="">
            <div>{{$t("componentsViews.text26")}}</div>
            <div>
                <div class="Action-input">
                    <input v-model.number="deviceForm.phone" :placeholder='$t("componentsViews.text27")' />
                </div>
                <div class="Action-input">
                    <input v-model.number="deviceForm.code" :placeholder='$t("componentsViews.text5")' />
                    <div class="sms-box" @click="getCode">{{ deviceForm.time ? `${deviceForm.time}s` : $t("componentsViews.text6") }}</div>
                </div>
            </div>           
            <div class="valide-buttom" @click="submitDevice">{{$t("componentsViews.text24")}}</div>
            
        </Dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { _loginMethod, _loginAction, _validateRealNameAction } from '@/core/comp/pages/login.js'
import { _sendValidateSmsCode, _checkValidateSmsCode } from '@/core/api/modules/user'
import { Dialog, Message, Input, Button } from 'element-ui'
export default {
    props: {
        deviceDialog:{
            type: Boolean,
        },
        ipDialog:{
            type: Boolean,
        },
        form: {
            type: Object ,
        },
        codeDom: {
            type: Object,
        }
    },
    computed: {
        ...mapGetters([
            'isLogin',
            'userData'
        ])
    },
    components: {
        Dialog,
        Input,
        Button,
    },
    data () {
        return{
            ipForm: { // ip验证表单
                name: ''
            },
            deviceForm: { //设备验证表单
                phone: '',
                code: '',
                time: 0,
            }
        }
    },
    methods:{
        closeValide() {
            this.$emit('closeipDialog')
            this.$emit('closeDevice')
            this.deviceForm = {
                phone: '',
                code: '',
                time: 0,
            }
            this.ipForm = { // ip验证表单
                name: ''
            }
        },
        // ip验证
        submitIP () {
            if (!this.ipForm.name) return Message.warning(this.$t("componentsViews.text23"))
            _validateRealNameAction({
                name: this.form.account,
                realName: this.ipForm.name
            }).then(res => {
                if (res) {
                    this.ipForm.name = ''
                    this.$emit('closeipDialog')
                    setTimeout(() => {
                        _loginAction(this.form, this.codeDom)
                    }, 0)
                }else{
                    Message.error(res.msg)
                }
            })
        },
        // 获取验证码
        getCode () {
            if (!this.deviceForm.phone || !/^1[3456789]\d{9}$/.test(this.deviceForm.phone)) {
                // 无效的手机号
                Message.warning(this.$t("componentsViews.text28"))
                return
            }
            if (!this.deviceForm.phone) return
            if (this.deviceForm.time) return
            _sendValidateSmsCode({
                mobile: this.deviceForm.phone,
                name: this.form.account,
            }).then(res => {
                if (res) {
                    this.deviceForm.time = 60
                    const interval = setInterval(() => {
                        this.deviceForm.time--
                        if (this.deviceForm.time <= 0) {
                            clearInterval(interval)
                        }
                    }, 1000)
                }
            })
        },
        // 验证设备
        submitDevice () {
            if (!this.deviceForm.phone || !/^1[3456789]\d{9}$/.test(this.deviceForm.phone)) {
                // 无效的手机号
                Message.warning(this.$t("componentsViews.text28"))
                return 
            }
            // 请输入短信验证码
            if (!this.deviceForm.code) return Message.warning(this.$t("componentsViews.text5"))
            if (!this.deviceForm.phone || !this.deviceForm.code) return
            _checkValidateSmsCode({ 
                mobile: this.deviceForm.phone,
                name: this.form.account,
                smsCode: this.deviceForm.code
             }).then(res => {
                if (res) {
                    this.deviceForm.phone = ''
                    this.deviceForm.code = ''
                    this.$emit('closeDevice')
                    setTimeout(() => {
                        _loginAction(this.form, this.codeDom)
                    }, 0)
                }
             })
        }
    }
}
</script>
<style lang="scss">
$imgUrl: '~@/assets/images/index/';
.LoginAction{
    .ip-dialog {
        position: relative;
        padding: 0 15px;
        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
        .Action-input {
            display: flex;
            position: relative;
            margin: 30px 0;
            input {
                flex: 1;
                height: 40px;
                padding-left: 10px;
                background-color: #f8f8f8;
                border: 1px solid;
                border-color: #dcdfe6;
                border-radius: 3px;
                box-sizing: border-box;
                &:focus {
                    outline: none;
                    border-color: #dcad02;
                }
            }
            .sms-box {
                width: 100px;
                height: 40px;
                line-height: 40px;
                margin-left: 20px;
                text-align: center;
                color: #fff;
                background-color: #b28601;
                border-radius: 20px;
                cursor: pointer;
            }
        }
        .valide-buttom {
            flex: 1;
            height: 44px;
            line-height: 44px;
            text-align: center;
            font-weight:700;
            color:#ffffff;
            font-size:16px;
            margin-top: 40px;
            background: url(#{$imgUrl}login_botton_bg.png) no-repeat center;
            background-size: 100% 100%;
            cursor: pointer;
            &:hover {
                opacity: .8;
            }
        }
    }
}
</style>
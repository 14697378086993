// 记录相关接口
// import store from '../../store'
import { _get, _post } from '../request'
import { Message } from 'element-ui'
import store from '../../store'
import { _formatDate } from '../../utils/utils'
import i18n from '@/il8n/index'


// 获取充值记录
export const _pcOnlinePayRecordsList = data => _post('/pay/api/v1/onlinePayRecords/pcOnlinePayRecordsList', data, true)

// 银行转账记录
export const _bankTransfer = data => _post('/pay/api/v1/offlineRecords/pageLlist', data, true)

// 提款记录
export const _withdrawalsRecords = data => _post('/pay/api/v1/withdrawRecords/appWithdrawRecordsList', data, true)

// 返水记录
export const _rebates = data => _post('/wallet/api/v1/rebates/appList', data, true)

// 其他记录
export const _elseList = data => _post('/pay/api/v1/accountMoneyChange/getList', data, true)


// 查询游戏记录
export const _gameRecords = data => _post('/game/api/v1/game/queryBetRecords', data, true)

// 获取所有游戏平台
export const _allPlatform = () => {
    return new Promise(resolve => {
        _get('/game/api/v1/vendorCallback/getAllVendorAndKindInfoByapp', '', false).then(res => {
            if (res && res.code == 0) {
                const arr = res.data
                arr.unshift({
                    vendorCode: i18n.t("textMessage.text5"),
                    vendorId: 9999,
                    vendorName: i18n.t("textMessage.text5"),
                })
                store.dispatch('recordAllPlatformAction', arr)
                resolve(res)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text7"))
                resolve(false)
            }
        })
    })
}

// 查询活动记录
export const _getPartake = data => _post('/longm/api/v1/activities/getPartake', data, true)

// 查询邀请记录
export const _memberAllowanceRecord = ({ start, end, currPage, pageSize }) => {
        return new Promise(resolve => {
            const user = store.getters.userData
            _post('/wallet/api/v1/allowance/memberAllowanceRecord', {
                currentPage: currPage,
                endTime: end,
                memberId: user.user_id || '',
                pageSize: pageSize,
                startTime: start
            }, true).then(res => {
                if (res && res.code == 0) {
                    console.log('---邀请列表', res)
                    store.dispatch('rebateListAction', {
                        list: res.data.content,
                        total: res.data.totalRecords,
                        totalBetValid: Number(res.data.totalBetValid).toFixed(2),
                        totalAllowance: Number(res.data.totalAllowance).toFixed(2)
                    })
                    resolve(res)
                } else {
                    Message.error(res.msg ||  i18n.t("errorMessage.text7"))
                    resolve(false)
                }
            })
        })
    }
    // 返水详情实时返水
export const _getRebateAmountDetail = params => {
        params.currentPage = 1
        params.pageSize = 1000
        return new Promise(resolve => {
            _post('/wallet/api/v1/rebates/getRebateAmountDetail', params, true).then(res => {
                if (res && res.code == 0) {
                    const list = res.data.list.map(item => {
                        item._createdAt = _formatDate(item.createdAt)
                        item._rebateAmount = Number(item.rebateAmount).toFixed(2)
                        item._effectiveBet = Number(item.effectiveBet).toFixed(2)
                        return item
                    })
                    resolve(list || [])
                } else {
                    resolve(false)
                }
            })
        })
    }
    // 返水详情批次返水
export const _getDetailByBetNo = params => {
    params.currentPage = 1
    params.pageSize = 1000
    return new Promise(resolve => {
        _post('/wallet/api/v1/rebates/getDetailByBetNo', params, true).then(res => {
            if (res && res.code == 0) {
                const list = res.data.map(item => {
                    item._createdAt = _formatDate(item.createdAt)
                    item._rebateAmount = Number(item.rebateAmount).toFixed(2)
                    item._effectiveBet = Number(item.effectiveBet).toFixed(2)
                    return item
                })
                resolve(list || [])
            } else {
                resolve(false)
            }
        })
    })
}
// 优惠活动

import { _get, _post, _putUrl } from '../request'
import { _getTime } from '../../utils/utils'
import store from '../../store/index'
import { Message } from 'element-ui'
import i18n from '@/il8n/index'

// 限时活动详情
export const _getThematicActivitiesByApp = id => _get(`/longm/api/v1/thematicActivities/getThematicActivitiesByApp/${id}`, '', true)


// 优惠活动详情
export const _activityInfo = id => {
    return new Promise(resolve => {
        _get('/longm/api/v1/activities/appGet/', id, true).then(res => {
            console.log('----结果', res)
            if (res && res.code == 0) {
                // 跳转链接
                if (res.data.type == 3 && res.data.activityExtensionsVo.url) {
                    window.open( res.data.activityExtensionsVo.url)
                    resolve(false)
                }
                // 跳转游戏
                else if (res.data.activityExtensionsVo &&  res.data.activityExtensionsVo.type == 4) {
                    if (store.getters.isLogin) { // 进入游戏
                        console.log('---进入游戏', res.data.activityExtensionsVo.activityGame)
                        resolve(false)
                    } else {
                        Message.error(i18n.t('errorMessage.text1'))
                        resolve(false)
                    }
                } else {
                    localStorage.setItem('promoInfoData', JSON.stringify(res.data))
                    // 活动时间文案
                    const a = res.data
                    a._titleText = ''
                    if (a.forever == 1) a._titleText = _getTime(a.validStartTime)
                    if (a.forever == 0 && a.type == 0) a._titleText = `${_getTime(a.validStartTime)} ${i18n.t('textMessage.text1')} ${_getTime(a.validEndTime)}`
                    if (a.forever == 0 && a.type != 0) a._titleText = `${_getTime(a.startTime)} ${i18n.t('textMessage.text1')} ${_getTime(a.endTime)}`
                    // 活动展示的详情
                    let result = ''
                    if (res.data.activityExtensionsVo && !res.data.activityExtensionsVo.url) {
                        result = res.data.activityExtensionsVo.responseFields
                        resolve({
                            ...res.data,
                            _result: result
                        })
                    } else {
                        result = res.data.rules
                        resolve({
                            ...res.data,
                            _result: result
                        })
                    }
                    localStorage.setItem('promoInfo', result)
                }
                return
            }
            Message.error(res.msg)
            resolve(false)
        })
    })
}

// 获取优惠活动分类
export const _activityType = () => {
    return new Promise(resolve => {
        _get('/longm/api/v1/settings/getSettingsByType/7').then(res => {
            if (res && res.code == 0) {
                store.dispatch('promoMenuAction', res.data)
            }
            resolve(false)
        })
    })
}

// 获取优惠列表数据
export const _activity = params => _post('/longm/api/v1/activities/list', params, true)

// 参与优惠活动
export const _joinAct = params => _putUrl('/longm/api/v1/activities/joinAct', params, true)


// 彩金列表
export const _jackpotList = params => {
    return new Promise(resolve => {
        _post('/wallet/api/v1/bonus/getAppList', params, true).then(res => {
            if (res && res.code == 0) {
                let arr = res.data.content || []
                // 数据转换
                arr = arr.map(item => {
                    item._createdAt = _getTime(item.createdAt)
                    item._overdueTime = _getTime(item.overdueTime)
                    item._amount = Number(item.amount).toFixed(2)
                    item._multiple = (item.amountAudit / item.amount).toFixed(2)
                    const stateNameMap = {
                        0: i18n.t("textMessage.text2"),//'立即领取',
                        1: i18n.t("textMessage.text3"),//'已领取',
                        2: i18n.t("textMessage.text4"),//'已过期'
                    }
                    item._stateName = stateNameMap[item.state]
                    return item
                })
                if (params.currentPage != 1) { // 第一页就直接刷新整个列表, 不是第一页就追加数据
                    arr = store.getters.jackpotList.concat(arr)
                }
                store.dispatch('jackpotListAction', arr)
                store.dispatch('jackpotTotalsAction', res.data.totalRecords || 0)
                resolve(res.data)
            } else {
                resolve(false)
            }
        })
    })
}

// 领取彩金
export const _receiveJackpot = id => {
    return new Promise(resolve => {
        _get('/wallet/api/v1/bonus/receive/' + id, '', true).then(res => {
            if (res && res.code == 0) {
                Message.success(i18n.t("errorMessage.text4"))
                // 更新列表数据
                const arr = store.getters.jackpotList
                const target = arr.find(item => item.id == id)
                if (target) target.state = 1 // 已领取
                store.dispatch('jackpotListAction', arr)
                // 全局彩金状态
                if (!res.data.notReceiveState) { // 全部领取了
                    store.dispatch('jackpotStatusAction', false)
                }
                resolve(res)
            } else {
                Message.error(res.msg || i18n.t("errorMessage.text5"))
                resolve(false)
            }
        })
    })
}

// 获取彩金领取状态
export const _getNotReceive = () => {
    return new Promise(resolve => {
        _get('/wallet/api/v1/bonus/getNotReceive').then(res => {
            if (res && res.code == 0) {
                if (res.data.notReceiveState) { // 有彩金需要领取
                    store.dispatch('jackpotStatusAction', true)
                }
                resolve(res)
            } else {
                resolve(false)
            }
        })
    })
}

// 会员可领取返利金额
export const _availableAmount = () => _post('/wallet/api/v1/allowance/availableAmount', '', true)
// 获取有效会员数据
export const _validMemberCount = () => _get('/wallet/api/v1/allowance/validMemberCount', '', true)
// 获取返利规则
export const _allowanceExplain = () => {
    return new Promise(resolve => {
        const rebateExplains = store.getters.rebateExplains
        if (rebateExplains) return resolve({
            data: { explains: rebateExplains }
        })
        _get('/wallet/api/v1/allowance/allowanceExplain').then(res => {
            if (res && res.code == 0) {
                store.dispatch('rebateExplainsAction', res.data.explains)
                resolve(res)
            } else {
                resolve(false)
                Message.error(res.msg || i18n.t("errorMessage.text6"))
            }
        })
    })
}

// 领取返利
export const _getReceive = () => _post('/wallet/api/v1/allowance/receive', null, true)

// 获取推广码
export const _referralLink = () => _get('/member/api/v1/members/referralLink', '', true)

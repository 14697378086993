<!-- 顶部导航 -->
<template>
    <div class="my-tab">
        <div class="tab-item"  @click="tab(0)" :class="index==0?'active-tab':''">{{$t("userPage.text53")}}（{{ msgUnReadTotalVuex || 0 }}）</div>
        <div class="tab-item"  @click="tab(1)" :class="index==1?'active-tab':''">{{$t("userPage.text54")}}（{{ noticeUnReadTotalVuex || 0 }}）</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'noticeUnReadTotalVuex', // 通知未读条数
            'msgUnReadTotalVuex', // 公告未读条数
        ])
    },
    props: {
        tabIndex: { // 外部注入的tab下标
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            index: 0
        }
    },
    created () {
        this.index = this.tabIndex
    },
    watch: {
        tabIndex (val) {
            this.index = val
        },
        '$route.query.t': {
            handler () {
                setTimeout(() => {
                    const i = this.$route.query.tab
                    if (!isNaN(i))  this.tab(this.$route.query.tab)
                })
            },
            immediate: true
        }
    },
    methods: {
        tab (index) {
            this.index = index
            this.$emit('tab', index)
        }
    }
}
</script>

<style scoped>
.my-tab {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:  20px;
    margin-bottom: 66px;
}
.tab-item {
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    font-weight:700;
    color:#6f6f6f;
    font-size:24px;
    text-align: center;
    padding-left: 6px;
    box-sizing: border-box;
}
.active-tab {
    color:#fdf86a;
    border-bottom: 3px solid #fdf86a;;
}
</style>
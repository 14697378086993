<template>
   <div class="LeftSidebar">
      <div class="LeftSidebar-list" @click="_toCustomerService">
         <i class="sidebar-icon sidebar-icon1"></i>
         <div class="LeftSidebar-text">{{$t("homePageIndex.text64")}}</div>
      </div>
      <div class="LeftSidebar-list" @click="_jump('elec')">
         <i class="sidebar-icon sidebar-icon2"></i>
         <div class="LeftSidebar-text">{{$t("homePageIndex.text65")}}</div>
      </div>
      <Popover popper-class=""
                  placement="left-start"
                  width="128"
                  trigger="hover">
         <div class="qrCodeApp">
            <div id="qrcodeApp1"></div>
            <div class="qrText">{{$t("homePageIndex.text66")}}</div>
         </div>
         <div slot="reference" class="LeftSidebar-list">
            <i class="sidebar-icon sidebar-icon3"></i>
            <div class="LeftSidebar-text">{{$t("homePageIndex.text66")}}</div>
         </div>
      </Popover>
      <div class="LeftSidebar-list" @click="toAgent">
         <i class="sidebar-icon sidebar-icon4"></i>
         <div class="LeftSidebar-text">{{$t("homePageIndex.text67")}}</div>
      </div>
   </div>
</template>

<script>
import { _toCustomerService } from '@/core/comp/common'
import { _jump, _createQRcode, _downloadApp } from '@/core/utils/utils'
import { Popover } from 'element-ui'
export default {
   components:{
      Popover,
   },
   data () {
      return {
         appUrl: _downloadApp(),
      }
   },

  created() {
    setTimeout(() => {
      _createQRcode({
        id: 'qrcodeApp1',
        content: this.appUrl,
        width: 130,
        height: 130,
      })
    }, 500);
  },
   methods: {
      _jump,
      _toCustomerService,
      toAgent(){
         this.$router.push({
            name: 'help',
            query: {id:7}
         })
      }
   }
}
</script>

<style lang="scss" scoped>
$imgUrl: '~@/assets/images/index/';
.LeftSidebar{
   position:fixed;
   right: 0;
   top: 50%;
   transform:translateY(-50%);
   z-index:11;
   .LeftSidebar-list{
      width: 92px;
      height: 66px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 5px;
      padding:10px 0px 5px 20px;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background: url(#{$imgUrl}home/LeftSidebar-bg.png)no-repeat center/contain;
      &:hover{
         color: #fff;
         background: url(#{$imgUrl}home/LeftSidebar-active.png) no-repeat center/contain;
         .sidebar-icon{
            background:url(#{$imgUrl}sidebar-icon-active.png) no-repeat center;
         }
      }
      .sidebar-icon{
         width: 24px;
         height:24px;
         display:block;
         background:url(#{$imgUrl}sidebar-icon.png) no-repeat center;
      }
      .LeftSidebar-text{
         width: 100%;
         text-align:center;
         font-size: 12px;
      }
      @for $i from 0 to 4{
         .sidebar-icon#{$i+1}{
            background-position: 0px $i*-26px !important;
         }
      }
   }
}
</style>
<style lang="scss">
.qrCodeApp{
   .qrText{
      text-align:center;
      margin-top:10px;
      font-size: 16px;
      color: #cdb659;
   }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './element.js'
import store from './core/store'
import i18n from '@/il8n/index'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/main.scss'
import locale from 'element-ui/lib/locale';  //elementUI的国际化
import {
  Swiper as SwiperClass,
  Pagination, //使用那个组件就在这里面添加
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import 'swiper/swiper-bundle.css'
// 导入图片预加载方法以及图片列表
import VueLazyload from 'vue-lazyload'

// 取出缓存中的数据放入vuex
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);
store.dispatch('take')
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);
Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(VueLazyload, {
  loading: require('./assets/images/loading.gif'),
  error: require('./assets/images/loading_error.svg'),
  attempt: 1
})  

Vue.prototype.$config = require('./utils/config.js').default;
locale.i18n((key, value) => i18n.t(key, value)) //elementui多语言切换
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="agent-detail">
      <div class="wrap">
          <div v-html="agentInfoRule"></div>
      </div>
  </div>
</template>

<script>
import { _agentCommissionPlan } from '@/core/api/modules/agent'
import { mapGetters } from 'vuex'
export default {
  name: "agentDetail",
  computed:{
    ...mapGetters([
      'agentInfoRule', // 代理规则
    ])
  },
  mounted(){
    _agentCommissionPlan()
  },
};
</script>

<style lang="scss" scoped>
.agent-detail {
  color: #fff;
    .wrap {
        box-sizing: border-box;
        padding: 50px;
        padding-top: 50px;
        min-height: 600px;
        margin: 0 auto;
    }
}
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

import en from './lang/en';
import zh_CN from './lang/zh_CN';
import zh_HK from './lang/zh_HK';

//引入Element的语言包
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

/*---------使用语言包-----------*/
let lang = window.locale || 'en'
const i18n = new VueI18n({
    locale: lang, // 语言标识
    messages: {
        zh_CN: Object.assign(zh_CN,zhLocale), //中文简体
        zh_HK, //中文繁体
        en: Object.assign(en,enLocale)
    },
    silentTranslationWarn: true,
})
export default i18n;
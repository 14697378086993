// 获取设备唯一标识
import store from '../store/index'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import UA from 'ua-parser-js'
// 获取设备唯一id   h5获取的时候需要把后端返回的token传进来
export const _getDeviceId = token => {
    token = token || store.getters.fingerprintId
    return new Promise(resolve => {

        let id = localStorage.getItem('dfeivnigceer') // 缓存
        if (id) {
            resolve(id)
            return
        }
        
        // 获取新的
        const fpPromise = FingerprintJS.load({
            token: token
        })
        fpPromise
        .then(fp => fp.get()).catch(() => {
            warningTip()
            resolve(token.split('').reverse().join('') + new Date().getTime().toString().slice(-12))
        })
        .then(result => {
            // device finger
            localStorage.setItem('dfeivnigceer', result.visitorId)
            resolve(result.visitorId)
        }).catch(() => {
            warningTip()
            resolve(token.split('').reverse().join('') + new Date().getTime().toString().slice(-12))
        })
    })
}


function warningTip () {
    // alert(i18n.t("textMessage.text53")) //浏览器安全提示
}

// 获取设备详情  从header里面传
// 手机型号 phoneModel
// 操作系统类型 systemType
// 浏览器类型 browserType
export const _getDeviceInfo = () => {
    return new Promise(resolve => {
        resolve({
            phoneModel: 'pc',
            systemType: myPlatform(),
            browserType: myBrowser()
        })
    })
}


// 判断平台
function myPlatform() {
    if (/macintosh|mac os x/i.test(navigator.userAgent)) {
        return 'mac os'
    }
    if (/windows|win32/i.test(navigator.userAgent)) {
        return 'windows'
    }
    return 'unknown'
}

// 判断浏览器
function myBrowser() {
    return UA().browser.name
}

<!-- 公告列表 -->
<template>
    <div class="page-notice page-elec">
        <!-- 公告列表 -->
        <div v-if="!showDetail">
            <div  class="notice-item" v-for="(item, i) in systemNoticeList" :key="i" @click="readItem(item, i)">
                <div class="img-box">
                    <img loading="lazy" v-lazy="item.readFlag == 0 ? require('../../../assets/images/my/news-i.png') : require('../../../assets/images/my/news.png')"  alt="">
                </div>
                <div class="content-box">
                    <div class="title-box">
                        <div class="title" :class="{'title-i':item.readFlag == 0}">{{ item.subject }}</div>
                        <div class="time">{{ item._publishedAt }}</div>
                    </div>
                    <div class="content">
                        {{ item.content }}
                    </div>
                </div>
            </div>
            <Empty v-if="!systemNoticeList.length" :description="$t('userPage.text162')" :image="require('@/assets/images/my/null.png')"></Empty>
            <!-- 分页 -->
            <div class="pagination-box">
                <Pagination
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :current-page.sync="form.currPage"
                :page-size="form.pageSize"
                :total="systemNoticeTotal"
                @size-change="getData"
                @current-change="getData"
                ></Pagination>
            </div>
        </div>
        <div v-else class="notice-detail">
            <div class="title-box">
                <div class="title">{{ info.subject }}</div>
                <div class="time">{{ info._publishedAt }}</div>
            </div>
            <div class="content-box">
                {{ info.content }}
            </div>
            <div class="back" @click="back">{{$t("userPage.text45")}}</div>
        </div>
    </div>
</template>

<script>
import { _noticeList, _readNotice } from '@/core/api/modules/system'
import { mapGetters } from 'vuex'
import { Pagination, Empty } from 'element-ui'

export default {
    components: {
        Pagination,
        Empty,
    },
    computed: {
        ...mapGetters([
            'systemNoticeList', // 公告列表
            'systemNoticeTotal', // 总条数
            'systemNoticeUnread', // 是否有未读公告
        ])
    },
    data () {
        return {
            showDetail:false,
            info:null,
            form: {
                currPage: 1,
                pageSize: 10,
            }
        }
    },
    created () {
        _noticeList(this.form)
    },
    
    watch:{
        // 打开详情
        $route: {
            handler: function (val) {
                if(val.query.id) { 
                    const id = this.$route.query.id
                    setTimeout(() => {
                        this.getNotice(id)
                    },1000)
                }
            },
            immediate: true
        }
    },
    methods: {
        // 打开详情
        getNotice(id){
            var item = ''
            var index = ''
            var data = {
                createdAt: "",
                currentPage: "",
                pageSize: 999,
                publishedAt: "",
                subject: ""
            };
            var noticeList = ''
            _noticeList(data).then(res => {
                if(res && res.code == 0) {
                    noticeList = res.data.content
                    noticeList.filter( (e, i) => {
                        if( e.id == id){ 
                            item = e 
                            index = i
                        }
                    });
                    this.form.currPage = parseInt((index + 1) / 10) + 1
                    if(item) this.readItem(item,index)
                }
            })
        },
        // 获取数据
        getData () {
            setTimeout(() => {
                _noticeList(this.form)
            }, 0)
        },
        // 打开或关闭详情
        readItem (item, i) {
            this.showDetail = true
            this.info = item
            if (item.readFlag == 0) { // 打开未读消息
                _readNotice([item.id], i)
            }
        },
        back() {
            this.showDetail = false
        }
    }
}
</script>

<style scoped lang="scss">

.page-notice{
    flex: 1;
    .notice-item {
        width: 846px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding-left: 10px;
        border-bottom: 1px solid #000;
        .content-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 774px;
            height: 80px;
            .title-box {
                display: flex;
                justify-content: space-between;
                .title {
                    font-weight:700;
                    color:#6f6f6f;
                    font-size:18px;
                }
                .time {
                    color:#6f6f6f;
                    font-size:16px;
                }
                .title-i {
                    color:#bebebe;
                }
            }
            .content {
                width:620px;
                height:20px;
                margin-top: 6px;
                color:#6f6f6f;
                font-size:14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .notice-detail {
        position: relative;
        height: 100%;
        .title-box {
            display: flex;
            justify-content: space-between;
            height: 40px;
            border-bottom: 2px solid #000;
            .title {
                font-weight:700;
                color:#bebebe;
                font-size:18px;
            }
            .time {
                color:#6f6f6f;
                font-size:16px;
            }
        }
        .content-box {
            padding-top: 12px;
            color:#bebebe;
            font-size:14px;
        }
        .back {
            position: absolute;
            bottom: 25px;
            width:90px;
            height:50px;
            line-height: 50px;
            background-image:linear-gradient(0.24deg,#4a4a4a 0%,#000000 27.59%,#414141 100%);
            border:1px solid;
            border-color:#707070;
            border-radius:6px;
            box-shadow:0px 1px 0px #ffffff;
            text-align: center;
            font-weight:700;
            color:#ffffff;
            font-size:20px;
            cursor: pointer;
        }
    }
    .pagination-box {
        width: 100%;
        text-align: right;
        margin-top: 56px;
    }
}
</style>
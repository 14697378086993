<!-- 主页面-顶部 -->
<template>
    <div class="GameList">
        <div class="GameList-bg">
            <div class="GameList-left">
                <div class="GameList-name" 
                    :class="index == isActive ? 'active' : ''"
                    v-for="(item,index) in gameMenuList"
                    :key="index"
                    v-animate-css.click="'swing'"
                    @click="gameTab(item,index)">
                    <i class="GameList-icon" :class="item.className"></i>
                    <span>{{ item.name }}</span>
                </div>
            </div>
            <div class="GameList-right">
                <div class="Game_tilte">
                    <div class="GameList-tab">
                        <swiper :options="swiperOption" class="GameList_scroll">
                            <swiper-slide class="GameListTab-name" 
                                :class="item.ids === dataInfo.id ? 'active' : ''"  
                                @click="gameListTab(item)" v-for="(item,index) in listTab"
                                :key="index">
                                <div class="List-btn" @click="gameListTab(item)">
                                    {{item.name}}
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="GameMore" @click="onMoreLink">{{$t("homePageIndex.text62")}} 》</div>
                </div>
                <div class="Game-content">
                    <ul v-infinite-scroll="loadMoreData">
                        <li class="GameList-game" v-for="(item,index) in dataList" :key="index" @click="_getToken(item,dataInfo.type)">
                            <img loading="lazy" v-lazy="systemImgHost+item.pictureUrl" />
                            <p>{{ item.name }}</p>
                        </li>
                    </ul>
                </div>
                <div class="game-text">
                    {{$t("homePageIndex.text63")}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _getToken } from '@/core/comp/common'
import { _menuList,_vendorGame,_getGameByIds } from '@/core/api/modules/game'
import { InfiniteScroll } from 'element-ui'
import vue from 'vue'
vue.use(InfiniteScroll)
export default {
    computed: {
        ...mapGetters([
            'gameMenu',
            'systemImgHost'
        ])
    },
    watch: {
        gameMenu: {
            handler: function () {
                this.updateData()
            },
        }
    },
    data () {
        return{
            gameMenuList:[
                {
                    // 电子游艺
                    name: this.$t("homePageIndex.text7"),
                    path:'Elec',
                    className: 'CASINO',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                },
                {
                    // 真人视讯
                    name: this.$t("homePageIndex.text8"),
                    path:'Live',
                    className: 'LIVE-CASINO',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                },
                {
                    // 电子竞技
                    name: this.$t("homePageIndex.text9"),
                    path: 'Gaming',
                    className: 'Electronic-Sports',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                },
                {
                    // 体育赛事
                    name: this.$t("homePageIndex.text10"),
                    path:'sports',
                    className: 'SPORTS',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                },
                {
                    // 彩票游戏
                    name: this.$t("homePageIndex.text11"),
                    path: 'Lottery',
                    className: 'LOTTERY',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                },
                {
                    // 捕鱼达人
                    name: this.$t("homePageIndex.text13"),
                    path: 'Fish',
                    className: 'fishing',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                },
                {
                    // 棋牌游戏
                    name: this.$t("homePageIndex.text12"),
                    path: 'Chess',
                    className: 'CARD-GAME',
                    data: [], // 源列表数据
                    list: [], // 分页数据
                },
                // {
                //     name: '热门游戏',
                //     className: 'hotgame',
                    // isActive:false,
                //     data: [], // 源列表数据
                //     list: [], // 分页数据
                // },
            ],
            isActive:0,
            listTab:[],
            dataList:[],
            lastPage: false,
            loading:false,
            dataInfo:{
                pid:'', // 父id
                id:'', //子id
                type:'', // 类型
                currPage:1, //当前页
                pageSize:99, // 展示数量
                // total:0,//游戏列表总数量
            },
            swiperOption: {//swiper3
                speed: 1000,
                slidesPerView: 3.5,
                //左右点击
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
        }
    },
    mounted () {
        this.updateData();
        setTimeout(() => {
            this.listTab = this.gameMenuList[0].list
            this.gameListTab(this.listTab[0])
        },1000)
    },
    methods: {
        _getToken,
        updateData() {
            const { arr_dz, arr_ty, arr_qp, arr_cp, arr_by, arr_zb, arr_dj , arr_dz_all } = this.gameMenu
            // console.log('---捕鱼', arr_by)
            this.gameMenuList[0].list = arr_dz
            this.gameMenuList[1].list = arr_zb
            this.gameMenuList[2].list = arr_dj
            this.gameMenuList[3].list = arr_ty
            this.gameMenuList[4].list = arr_cp
            this.gameMenuList[5].list = arr_dz_all//获取捕鱼数据
            this.gameMenuList[6].list = arr_qp
        },
        // 加载更多数据
        loadMoreData () {
            if (this.lastPage) return
            if (this.loading) return
            this.dataInfo.currPage++
            this.$nextTick(() => {
                this.loadData()
            })
        },
        // 加载游戏
        async loadData(){
            console.log('this.dataInfo1',this.dataInfo)
            let {pid,id,type,curPage,pageSize} = this.dataInfo;
            this.loading= true;
            let gameRelust = null;
            switch (type) {
                case 1:
                    gameRelust = await _vendorGame({
                        currentPage: curPage,
                        pageSize: pageSize,
                        gameKindId: pid,
                        vendorId: id,
                    })
                    break
                case 2:
                    gameRelust = await _getGameByIds({
                        currentPage: curPage,
                        pageSize: pageSize,
                        gameKindId: pid,
                        ids: id,
                    })
                    break
                case 3:
                    gameRelust = await _getGameByIds({
                        currentPage: curPage,
                        pageSize: pageSize,
                        gameKindId: pid,
                        ids: id,
                    })
                    break
            }
            if(gameRelust){
                this.dataList = gameRelust.data.list
            }
        },
        // 游戏类型tab切换
        gameTab(item,index){
            if(this.isActive == index) return
            this.listTab = []
            this.dataList = []
            this.isActive = index
            if(item.className == 'fishing'){
                let fishing = this.gameMenuList[5].list.filter(item =>{
                    return item.nameEn == 'fishing';
                })
                this.listTab= fishing;
            }else{
                this.listTab = this.gameMenuList[index].list
            }
            let parms = this.listTab[0]
            console.log('---点击了',item)
            this.gameListTab(parms)
        },
        // 游戏tab切换
        gameListTab(item){
            if(item.ids == this.dataInfo.id) return
            let {parentId:pid,ids:id,type} = item;
            this.dataInfo.pid = pid;
            this.dataInfo.id = id;
            this.dataInfo.type = type;
            this.currPage = 1;
            this.lastPage = false
            this.dataList= []
            this.loadData();
            console.log('---点击了',item)
        },
        onMoreLink(){
            const path = this.gameMenuList[this.isActive].path.toLowerCase()
            this.$router.push({
                path:path,
            })
        }
    },
}
</script>

<style lang="scss" scoped >
$imgUrl:'~@/assets/images/index/';
$gameNames: CASINO SPORTS CARD-GAME LOTTERY LIVE-CASINO Electronic-Sports fishing;
    .GameList{
        width: 100%;
        min-width: 1200px;
        height: 730px;
        background: #141313;
        .GameList-bg{
            width: 1200px;
            min-width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content:space-around;
            padding: 70px 15px 0px 15px;
            background:url(#{$imgUrl}home/GameList-bg.png) no-repeat top center/contain #202020;
            .GameList-left{
                width: 252px;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                position: relative;
                .GameList-name{
                    width: 100%;
                    height: 92px;
                    padding: 0 15px;
                    display: flex;
                    align-items: center;
                    justify-content:flex-start;
                    color:#F4CD6F;
                    font-size: 24px;
                    cursor: pointer;
                    i.GameList-icon{
                        width: 56px;
                        height: 52px;
                        margin-right: 10px;
                    }
                    @each $gameName in $gameNames{
                        i.#{$gameName}{
                            background:url(#{$imgUrl}game_#{$gameName}.png) no-repeat center/contain;
                        }
                    }
                    &:hover,&.active{
                        background:url(#{$imgUrl}home/GameList-active_bg.png) no-repeat center/contain;
                    }
                }
            }
            .GameList-right{
                width: 78%;
                height: 644px;
                padding:15px 30px;
                background:url(#{$imgUrl}home/GameList-right.png) no-repeat bottom center/contain;
                .Game_tilte{
                    display: flex;
                    align-content: flex-start;
                    justify-content:space-between;
                    font-size: 18px;
                    width: 100%;
                    .GameList-tab{
                        width: 90%;
                        color: #fff;
                        height: 100%;
                        overflow: hidden;
                        .GameList_scroll{
                            width: 100%;
                            display:flex;
                            height: 100%;
                            white-space: nowrap;
                            user-select: text;
                            padding: 10px;
                            /* 滚动条滑块 */
                            &::-webkit-scrollbar-thumb {
                                border-radius: 3px;
                                background-color: rgba(0,0,0,0.3);
                                -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
                            }
                            .GameListTab-name{
                                height: 50px;
                                width:201px;
                                min-width:201px;
                                display:flex;
                                justify-content:center;
                                align-items: center;
                                padding-bottom: 4px;
                                cursor: pointer;
                                background:url(#{$imgUrl}home/GameListTab-name.png)no-repeat center/contain;
                                &.active,&:hover{
                                    color:#F4CD6F;
                                    background:url(#{$imgUrl}home/GameListTab-name_active.png)no-repeat center/contain;
                                }
                                .List-btn{
                                    width: 100%;
                                    text-align: center;
                                    line-height: 46px;
                                    height: 100%;
                                    padding: 0 15px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                    .GameMore{
                        color: #fff;
                        width: auto;
                        height: 76px;
                        display:flex;
                        justify-content:center;
                        align-items: center;
                    }
                }
                .Game-content{
                    display: flex;
                    width:100%;
                    align-items: center;
                    justify-content:flex-start;
                    ul{
                        width: 100%;
                        display:grid;
                        grid-gap: 16px 13px;
                        grid-template-columns: repeat(5,1fr);
                        height: 450px;
                        overflow: auto;
                        .GameList-game{
                            width: 155px;
                            height: min-content;
                            border-radius: 5px;
                            overflow: hidden;
                            background: #343943;
                            cursor: pointer;
                            &:hover{
                                img{
                                    transform: scale(1.04);
                                }
                            }
                            img{
                                width: auto;
                                height: 104px;
                                display: block;
                                margin: 11px auto;
                                transition: cubic-bezier(0.26,0,0.1,1) 600ms,opacity cubic-bezier(0.26,0,0.1,1) 600ms,-webkit-transform cubic-bezier(0.26,0,0.1,1) 600ms;
                            }
                            p{
                                display: block;
                                text-align: center;
                                font-size: 18px;
                                color: #fff;
                                margin-bottom: 11px;
                            }
                        }
                    }
                }
                .game-text{
                    font-size: 18px;
                    width: 80%;
                    text-align: center;
                    margin: 15px auto;
                    color: #AAAAAA;
                }
            }
        }
    }
</style>
import Vue from 'vue'
import Vuex from 'vuex'

// 模块
import system from './modules/system'
import user from './modules/user'
import game from './modules/game'
import promo from './modules/promo'
import finance from './modules/finance'
import records from './modules/records'
import agent from './modules/agent'

Vue.use(Vuex)

let saveTimeout = 0 // 缓存节流延时器
const storeList = [ // 需要缓存的字段
  'systemReady',
  'userAccessToken',
  'userData',
  'systemCodeUrl', // web服务器地址
  'systemImgHost', // 图片服务器地址
  'systemDowUrl', // 下载服务器地址
  'systemJackpotUrl', // 彩金服务器地址
  'systemCustomerServiceUrl', // 客服服务器地址
  'systemIosDownloadUrl', // ios下载地址
  'systemAndroidDownloadUrl', // 安卓下载地址
  'systemPcDownloadUrl', // pc下载地址
  'systemPcMainUrl', // 主站域名
  'systemHost',
  'fingerprintId',
  'recordAllPlatform',
  'gameMenu',
]

export default new Vuex.Store({
  state: {
    ...system.state,
    ...user.state,
    ...game.state,
    ...promo.state,
    ...finance.state,
    ...records.state,
    ...agent.state,
  },
  mutations: {
    ...system.mutations,
    ...user.mutations,
    ...game.mutations,
    ...promo.mutations,
    ...finance.mutations,
    ...records.mutations,
    ...agent.mutations,
    SAVE(state) { // 将vuex数据存入store中
      if (saveTimeout) clearTimeout(saveTimeout)
      saveTimeout = setTimeout(() => { // 节流存储
        const states = {}
        storeList.forEach(key => {
          states[key] = state[key] || ''
        })
        console.log('----存入store----', states)
        localStorage.setItem('app_datas', JSON.stringify(states))
      }, 1000)
    },
    TAKE(state, data) { // 将store的数据取出放进vuex, 直接在需要缓存数据的地方 commit('TAKE') 即可
      // console.log('----同步vuex----', data)
      state = Object.assign(state, data)
    }
  },
  actions: {
    ...system.actions,
    ...user.actions,
    ...game.actions,
    ...promo.actions,
    ...finance.actions,
    ...records.actions,
    ...agent.actions,
    take({ commit }) { // 将store的数据取出放进vuex
      try {
        commit('TAKE', JSON.parse(localStorage.getItem('app_datas')))
      } catch {
        console.error('设置缓存数据失败')
      }
    }
  },
  getters: {
    ...system.getters,
    ...user.getters,
    ...game.getters,
    ...promo.getters,
    ...finance.getters,
    ...records.getters,
    ...agent.getters,
    all: state => state, // 获取所有state的值
  }
})

// 一些通用的逻辑
import store from '../store/index'
import config from '../common/config'
import { Message } from 'element-ui'
import { _getTokenApi } from '../api/modules/game'
import { _activityInfo } from '../api/modules/promo'
import Router from '../../router/index'
import i18n from '@/il8n/index'

// 退出登录
export const _loginOut = () => {
    store.dispatch('clearUserData')
}

// 进入游戏
// type 为 2 的适合，gameId用game.ids  否则用 game.id
export const _getToken = (game, type = 1) => {
    if (!store.getters.isLogin) return Message.error(i18n.t("errorMessage.text1"))//请先登录
    const params = {
        memberId: store.getters.userData.user_id,
        tenantId: store.getters.userData.tenant_id,
        username: store.getters.userData.username,
        gameId: type == 2 ? game.ids : game.id,
        clientIp: config.clientIp,
        terminalType: 1,
    }
    _getTokenApi(params).then(res => {
        if (!res || res.code) return Message.error(res.msg || i18n.t("errorMessage.text59"))//游戏正在维护中
        window.open(res.data)
    })
}

// 新旧客服系统
export const _toCustomerService = () => {
    // 新客服-自助客服系统
    if (window.customerServiceStatus == 1) {
        const obj = {
            host: config.host,
            clientCode: config.clientCode,
            clientItem: config.childCode,
            language: config.locale,
            username: store.getters.userData.username,
            theme: config.theme,
            projectImgUrl: config.projectImgUrl,
            orgin: window.location.origin + '/activity'
        }
        const str = window.btoa(JSON.stringify(obj))
        const url = window.location.origin + '/func/customerService/pc?s=' + str
        window.open(url)
    }
    // 旧客服-在线客服
    else {
        window.open(store.getters.systemCustomerServiceUrl)
    }
}
// 查看活动详情
export const _activeInfoAction = (item) => {
    console.log('----活动', item)
    // 如果是优惠活动就拿urlId 和 urlName
    const id = item.urlId || item.id
    const name = item.urlName || item.name
    _activityInfo(id).then(res => {
        console.log('----返回', res)
        if (!res) return
        Router.push({
            name: 'promoInfo',
            query: {
                title: name,
                pid: id,
                time: item._titleText || res._titleText,
                forever: item.forever || res.forever
            }
        })
    })
}
